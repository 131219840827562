import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './../../../core/service/api.service';
@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.css']
})
export class MasterdataComponent implements OnInit {


 
  constructor(public translate: TranslateService,
    private api: ApiService,
    
    ) { }
    raw_data = [];
    citydata= [];
    provinces= [];


  ngOnInit(): void {

    this.api.GET('getcities').then((res) => {
        
      if (res['message'] === 'Cities retrieved successfully') {
        this.raw_data = res['citydata'];
        this.raw_data.forEach((element) => {
        this.citydata.push(element);
        });
        console.log('%c City data',this.citydata);
      }
    });

    this.api.GET('provinces').then((res) => {
        
      if (res['message'] === 'Provinces retrieved successfully') {
        this.raw_data = res['data'];
        this.raw_data.forEach((element) => {
        this.provinces.push(element);
        });
        console.log('%c Province data',this.provinces);
      }
    });
  }

}
