<app-header></app-header>
<div class="update-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col-md-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- <div>
    <button class="button">Contact Us-Request</button>
    </div>
</div> -->
<div class="fixed-right-btm">
  <div class="fixed-right-button">{{'side_nav.contact_us_request'|translate}}</div>  
</div>
<app-footer></app-footer>
