
<div class="next-course">
  <div class="container">
      <h2>{{'welcome_page.categories'|translate}}</h2>
      <div class="">
          <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                  <img src="../../../../assets/images/certificate.png" class="categories">
                  <p>{{'welcome_page.qualification'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/update.png" class="categories">
                  <p>{{'welcome_page.update_property'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/marketing.png" class="categories">
                  <p>Marketing</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/marketing.png" class="categories">
                  <p>Marketing</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

            </owl-carousel-o>
      </div>
  </div>

</div>


