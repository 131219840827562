import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service'
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
export interface PeriodicElement {
  job: string;
  name: string;
  frequency: string;
  certificate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name:'Mario Rossi' , job: 'Administration', frequency: 'Yes', certificate: ''},
  {name: 'Luke Yellows', job: 'Technical', frequency: 'Yes', certificate: ''},
  {name: 'Antonio Verdi', job: 'Employee', frequency: 'No', certificate: ''},
  {name: 'Fabio Blue', job: 'Framework', frequency: 'Yes', certificate: ''},
  
];

@Component({
  selector: 'app-purchaseview',
  templateUrl: './purchaseview.component.html',
  styleUrls: ['./purchaseview.component.css']
  
})
export class PurchaseviewComponent implements OnInit {
  id: any;
  learners = [];
  myData =[];
  courseData = [];
  constructor(
    private router:Router,
    private api: ApiService,  
    private _Activatedroute: ActivatedRoute,
    public common_: CommonService,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));

    this.api.GET("leanersforcourse/"+this.id).then((res)=>{
  let arr =[];
      if (res['message'] === 'Enrolies retrieved successfully') {
            // this.raw_data=res['course'];
          
            this.learners = res['learners'];
            this.courseData = res['course'];
            this.learners.forEach((item)=>{
              let n = {
                name : item.first_name + ' ' + item.last_name,
                job : "Administration",
                frequency : "Yes",
                certificate : ""
              }
              arr.push(n);

              this.myData = arr

            })
      }
    });
  }
  displayedColumns: string[] = ['name', 'job', 'frequency', 'certificate'];
  // myData = ELEMENT_DATA;
  
}
