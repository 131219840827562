<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <h3>{{'propose_new_course.proposal_for_a_new_course'|translate}}</h3>
      <p>{{'propose_new_course.feature_text'|translate}}</p>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2" style="margin-left: 15px">
              <label class="mt-1" for="email" >{{'purchases_page.title'|translate}}</label>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{'purchases_page.title'|translate}}"
                  [(ngModel)]="course_propose.title"
                />
              </div>
            </div>
          </div>
     
           <div class="row">
              <div class="col-md-2">
                <label for="email" style="margin-left: 15px">{{'update_form.job'|translate}}</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="slct"  [(ngModel)]="course_propose.job" id="slct" *ngIf="jobs">
                      <option selected disabled>Select Job</option>
                      <option *ngFor="let job of jobs" value="{{job.id}}">{{job.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 active" >
                <div (click)="lauchJbForm()" style="background-color:#F1B900;width: 30px;height: 30px;text-align: center;border-radius: 10px;">
                <img src="../../../assets/images/plus-icon.png" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <label for="email" style="margin-left: 15px">{{'course_management.role'|translate}}</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="slct"  [(ngModel)]="course_propose.role" id="slct" *ngIf="roleData">
                      <option selected disabled>Select Role</option>
                      <option *ngFor="let role of roleData" value="{{role.id}}">{{role.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 active" >
                <div style="background-color:#F1B900;width: 30px;height: 30px;text-align: center;border-radius: 10px;">
                <img src="../../../assets/images/plus-icon.png" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <label for="email" style="margin-left: 15px">{{'course_management.classroom'|translate}}</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="slct"  [(ngModel)]="course_propose.classroom" id="slct" *ngIf="classRoom">
                      <option selected disabled>Select Class Room</option>
                      <option *ngFor="let classroom of classRoom" value="{{classroom.id}}">{{classroom.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            
            </div>

            <div class="row">
              <div class="col-md-2">
                <label for="risklevels" style="margin-left: 15px">Risk Level</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="risklevels"  [(ngModel)]="course_propose.risklevel" id="slct" *ngIf="risklevels">
                      <option selected disabled>Select risk level</option>
                      <option *ngFor="let risklevel of risklevels" value="{{risklevel.code}}">{{risklevel.title}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-2">
                <label for="risklevels" style="margin-left: 15px">Category</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="category"  [(ngModel)]="course_propose.category" id="category" *ngIf="categorylist">
                      <option selected disabled>Select Category</option>
                      <option *ngFor="let category of categorylist" value="{{category.id}}">{{category.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <label for="risklevels" style="margin-left: 15px">Course type</label>
              </div>
              <div class="col-md-6" style="width: 702px; margin-left: 13px;">
                <div class="form-group">
                  <div class="select">
                    <select name="course_type"  [(ngModel)]="course_propose.course_type" id="course_type">
                      <option selected disabled>Select Type</option>
                      <option value="R">R</option>
                      <option value="S">S</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label for="email" style="margin-left: 11px;">{{'propose_new_course.area_argument'|translate}}</label>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="select">
                    <input type="text" class="form-control" />
                    <select name="slct" id="slct">
                      <option selected disabled></option>
                      <option value="1">2</option>
                      <option value="2">2</option>
                      <option value="3">2</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <div class="select">
                    <input type="text" class="form-control" />
                    <select name="slct" id="slct">
                      <option selected disabled></option>
                      <option value="1">2</option>
                      <option value="2">2</option>
                      <option value="3">2</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <p>{{'propose_new_course.description_of_the_course'|translate}}</p>
            </div>
            <div class="col-md-12">
            <ckeditor
              [editor]="Editor"
              data="<p></p>"
              [(ngModel)]="course_propose.description"
            >
              
            </ckeditor>
            </div>
            <div class="col-md-12">
              <div class="row">
              <div class="col-md-2"></div>
              <div class="btn-g col-md-2">
             <button class="btn btn-primary" style="width:200px" [routerLink]="'/course'">
              {{'company_learner.cancel'|translate}}
            </button>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <button class="btn btn-primary active" style="width:200px" (click)="ProposeCourse()">
              {{'propose_new_course.send_proposal'|translate}}
            </button>
          </div>
          </div>
            </div>

          
        </div>
      </div>
    </div>
  </div>
</div>
