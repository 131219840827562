<div class="registerd-form insert-course">
  <div class="container">
    <div class="side-nav">
      <h3>{{'insert_new_courses.new_course'|translate}}</h3>
      <mat-tab-group #tabGroup [(selectedIndex)]="selectedTab">
        <mat-tab  label="Course"
          ><p>
            1. {{'insert_new_courses.first_text_1'|translate}} <b>{{'insert_new_courses.first_text_2'|translate}}</b> {{'insert_new_courses.first_text_3'|translate}}
          </p>
          <form>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="email">{{'purchases_page.title'|translate}}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="{{'purchases_page.title'|translate}}"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'update_form.job'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>{{'purchases_page.title'|translate}}</option>
                      <option value="1">Pure CSS</option>
                      <option value="2">No JS</option>
                      <option value="3">Nice!</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'course_management.role'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>{{'purchases_page.title'|translate}}</option>
                      <option value="1">Pure CSS</option>
                      <option value="2">No JS</option>
                      <option value="3">Nice!</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'course_management.room'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>{{'purchases_page.title'|translate}}</option>
                      <option value="1">Pure CSS</option>
                      <option value="2">No JS</option>
                      <option value="3">Nice!</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'purchases_page.category'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>AAA</option>
                      <option value="1">Pure CSS</option>
                      <option value="2">No JS</option>
                      <option value="3">Nice!</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'register_form.city'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>Noida</option>
                      <option value="1">Delhi</option>
                      <option value="2">U.P</option>
                      <option value="3">Goa</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'purchases_page.date'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>24-2-20</option>
                      <option value="1">25-2-20</option>
                      <option value="2">24-3-20</option>
                      <option value="3">20-2-20</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="company">{{'course_management.to'|translate}}</label>
                  <div class="select">
                    <select name="slct" id="slct">
                      <option selected disabled>24-2-20</option>
                      <option value="1">20-2-20</option>
                      <option value="2">20-2-20</option>
                      <option value="3">20-2-20</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-g">
              <button class="btn btn-primary">{{'purchases_page.clear_fields'|translate}}</button>
              <button class="btn btn-primary active">
                {{'welcome_page.search'|translate}} <img src="../../../assets/images/search.png" />
              </button>
              <button
                class="btn btn-primary"
                routerLink="/course/propose-new-course"
              >
                {{'insert_new_courses.propose_new_course'|translate}}
              </button>
            </div>
            <!---btn-g-->
          </form>

          <div class="table-div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>{{'purchases_page.title'|translate}}</th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
              </ng-container>

              <ng-container matColumnDef="required">
                <th mat-header-cell *matHeaderCellDef>{{'insert_new_courses.required'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.required }}
                </td>
              </ng-container>
              
              <ng-container matColumnDef="credit">
                <th mat-header-cell *matHeaderCellDef>{{'insert_new_courses.credit'|translate}}</th>
                <td mat-cell *matCellDef="let element">{{ element.credit }}</td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>{{'insert_new_courses.choose'|translate}}</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  (click)="nextTab([element.id, element.title], 1)"
                  class="ptr"
                >
                  <img src="../../../assets/images/action-icon.png" />
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Enter data" class="rd">
          <p>
            2. You have chosen the <b>{{ courseData.course_name }}</b
            >. Fill out the form below to continue. If you wanted to choose
            another course, click here: Choose another course
          </p>

          <div class="row">
            <div class="col-md-12">
              <div class="row rd-in">
                <div class="col-md-2">
                  <label for="email">Type</label>
                </div>

                <div class="col-md-10">
                  <div class="rr">
                    <div>
                      <input
                        type="radio"
                        name="radio"
                        id="radio1"
                        value="classroom"
                        [(ngModel)]="courseData.type"
                      />
                      <label class="radio" for="radio1"
                        ><span>Classroom</span>
                      </label>
                      <input
                        type="radio"
                        name="radio"
                        id="radio2"
                        value="FAD"
                        [(ngModel)]="courseData.type"
                      />
                      <label for="radio2"><span>FAD</span></label>
                      <input
                        type="radio"
                        name="radio"
                        id="radio3"
                        value="virtual"
                        [(ngModel)]="courseData.type"
                      />
                      <label for="radio3">
                        <span>{{'insert_new_courses.classroom_virtual'|translate}}</span></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="email">{{'purchases_page.place'|translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      [(ngModel)]="courseData.place"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="cost">Cost</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="cost"
                      [(ngModel)]="courseData.cost"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="cost">Start Date</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input
                    class="form-control"
                    type="date"
                    [(ngModel)]="courseData.start_date"
                  />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="cost">End Date</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input
                    class="form-control"
                    type="date"
                    [(ngModel)]="courseData.end_date"
                  />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <label for="company">{{'insert_new_courses.status'|translate}}</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <div class="select">
                      <select
                        name="slct"
                        id="slct"
                        [(ngModel)]="courseData.status"
                      >
                        <option value="ACTIVE">{{'insert_new_courses.active'|translate}}</option>
                        <option value="INACTIVE">{{'insert_new_courses.in_active'|translate}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="date-dd">
              <div class="container mb-2">
                <div class="row">
                  <div class="col-md-3">
                    <label for="email">{{'purchases_page.date'|translate}}</label>
                  </div>
                  <div class="col-md-3 pl-0">
                    <label for="email">{{'insert_new_courses.time'|translate}}</label>
                  </div>
                  <div class="col-md-2">
                    <label for="email">{{'purchases_page.place'|translate}}</label>
                  </div>
                  <div class="col-md-3">
                    <label for="email">{{'purchases_page.teacher'|translate}}</label>
                  </div>
                  
                     <div class="col-md-1 p-0 d-flex justify-content-center">
                      <img
                        class="p-1 yellow-bg ptr"
                        width="25px"
                        height="25px"
                        (click)="AddNewClass()"
                        src="../../../assets/images/plus-icon.png"
                      />
                    </div>
                </div>
              </div>
              <div class="main-dd p-0 m-0">
                <div class="container">
                  <div class="row" *ngFor="let class of classes; let i = index">
                    <div class="col-md-3">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          [(ngModel)]="class.date"
                        />
                      </div>
                    </div>

                    <div class="col-md-3 pl-0">
                      <div class="w-100 m-0 p-0 d-flex">
                        <div>
                          <input
                            style="font-size:13px;height: 37px;"
                            type="time"
                            placeholder="00:00"
                            [(ngModel)]="class.start_time"
                          />
                        </div>
                        <p class="my-1">:</p>
                        <div>
                          <input
                            style="font-size:13px;height: 37px;"
                            type="time"
                            placeholder="00:00"
                            [(ngModel)]="class.end_time"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <div class="select">
                          <select id="slct" [(ngModel)]="class.room">
                            <option [value]="1">room1</option>
                            <option [value]="2">room2</option>
                            <option [value]="3">room3</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="select delete-cc w-100">
                        <select id="slct" [(ngModel)]="class.teacher_id">
                          <option
                            *ngFor="let teacher of teachers"
                            [value]="teacher.teacher?.id"
                          >
                            {{ teacher.teacher?.first_name }}
                            {{ teacher.teacher?.last_name }}
                          </option>
                          <option [value]="3">teacher1</option>
                          <option [value]="4">teacher2</option>
                          <option [value]="5">teacher3</option>
                          <option [value]="6">teacher4</option>
                          <option [value]="7">teacher5</option>
                        </select>
                      </div>
                      
                    </div>

                    <div class="col-md-1 p-0 d-flex justify-content-center">
                      <img
                        class="ptr"
                        src="../../../assets/images/delete-icon.png"
                        width="25px"
                        height="30px"
                        (click)="DeleteClass(i)"
                      />
                    </div>
                  </div>
                  <!----row-->

                  <hr />
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          name="class_date"
                          [(ngModel)]="classData.date"
                        />
                      </div>
                    </div>

                    <div class="col-md-3 pl-0">
                      <div class="w-15 d-flex">
                        <div>
                          <input 
                            style="font-size:13px;height: 37px;"
                            type="time"
                            placeholder="00:00"
                            name="start_time"
                            [(ngModel)]="classData.start_time"
                          />
                        </div>
                         <p class="my-1">:</p>
                        <div class="w-20">
                          <input
                            style="font-size:13px;height: 37px;"
                            type="time"
                            placeholder="00:00"
                            name="end_time"
                            [(ngModel)]="classData.end_time"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <div class="select">
                          <select
                            name="slct"
                            id="slct"
                            [(ngModel)]="classData.room"
                          >
                            <option [value]="1">room1</option>
                            <option [value]="2">room2</option>
                            <option [value]="3">room3</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="select delete-cc w-100">
                        <select
                          name="slct"
                          id="slct"
                          [(ngModel)]="classData.teacher_id"
                        >
                          <option
                            *ngFor="let teacher of teachers"
                            [value]="teacher.teacher?.id"
                          >
                            {{ teacher.teacher?.first_name }}
                            {{ teacher.teacher?.last_name }}

                          </option>
                          <option [value]="3">teacher1</option>
                          <option [value]="4">teacher2</option>
                          <option [value]="5">teacher3</option>
                          <option [value]="6">teacher4</option>
                          <option [value]="7">teacher5</option>
                        </select>
                      </div>
                    </div>

                     <div class="col-md-1 p-0 d-flex justify-content-center">
                      <img
                        class="ptr"
                        src="../../../assets/images/delete-icon.png"
                        width="25px"
                        height="30px"
                      />
                    </div>
                  </div>
                  <p class="text-danger m-0" *ngIf="error_msg.length > 0">
                    {{ "*" + error_msg }}
                  </p>
                </div>
              </div>
            </div>
            
          </div>
          <div style="color: blue;">
            <span>{{'insert_new_courses.total_expected_hours'|translate}}:16</span><br>
            <span>{{'insert_new_courses.total_hours_entered'|translate}}:12</span>
          </div>
          <div class="editor">
            <h5>{{'insert_new_courses.internal_notes'|translate}}</h5>
            <ckeditor
              [editor]="Editor"
              data="<p></p>"
              [(ngModel)]="courseData.internal_notes"
            ></ckeditor>
            <br />
            <h5>{{'insert_new_courses.public_notes'|translate}}</h5>
            <ckeditor
              [editor]="Editor"
              data="<p></p>"
              [(ngModel)]="courseData.public_notes"
            ></ckeditor>
          </div>

          <div class="btn-g">
            <button type="submit" class="btn btn-primary">{{'company_learner.cancel'|translate}}</button>
            <button
              type="submit"
              class="btn btn-primary active"
              (click)="nextTab([], 2)"
            >
             <!-- {{'update_form.save_changes'|translate}} -->
             PUPLISH
            </button>
          </div>
        </mat-tab>
        <mat-tab label="Summary">
          <div class="editor">
            <ckeditor
              [editor]="Editor"
              data="<p></p>"
              [(ngModel)]="courseData.summary"
            ></ckeditor>
          </div>
          <div class="btn-g">
            <button
              type="submit"
              class="btn btn-primary"
              (click)="selectedTab = 1"
            >
              {{'insert_new_courses.back'|translate}}
            </button>
            <button
              type="submit"
              class="btn btn-primary active"
              (click)="insertCourse()"
            >
             {{'update_form.save_changes'|translate}}
            </button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <!---col-md-3---->
</div>
