<div class="container-fluid">
  <h3 class="title">{{'company_learner.edit_page_title'|translate}}</h3>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{'company_learner.course_title'|translate}}</label>
        <input
          type="text"
          style="border-radius: 7px; margin-left: 111px; width: 259.99306px"
          placeholder="Mario"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{'company_learner.certificate_release_date'|translate}}:</label>
        <input
          type="date"
          [(ngModel)]="class.date"
          style="border-radius: 7px; margin-left: 19px"
          placeholder="0"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{'company_learner.certificate_expiration_date'|translate}}:</label>
        <input
          type="date"
          [(ngModel)]="class.date"
          style="border-radius: 7px"
          placeholder="0"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{'company_learner.price_course'|translate}}</label>
        <input
          type="text"
          style="border-radius: 7px; margin-left: 94px"
          placeholder="280,00"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>{{'company_learner.training_credits'|translate}}</label>
        <input
          type="text"
          style="border-radius: 7px; margin-left: 70px"
          placeholder="0"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{'company_learner.charge_attested'|translate}}</label>
          <button
            style="border-radius: 7px; margin-left: 82px;color: white;"
            class="btn btn-warning"
          >
          {{'company_learner.browse'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="btn-gr">
      <button type="submit" style="margin-right: 10px;" class="btn btn-outline-warning">{{'company_learner.cancel'|translate}}</button>
      <button type="submit" class="btn btn-warning" style="color: white;">{{'update_form.save_changes'|translate}}</button>
    </div>
  </div>
</div>
