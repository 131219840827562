<app-choose-role *ngIf="!showRole"></app-choose-role>
<div *ngIf="showRole" class="registerd-form">
  <div class="container">
      <form>
        <h2>{{'choose_role.register'|translate}}</h2>
        <p>{{'register_form.role_text_1'|translate}} {{role}} {{'register_form.role_text_2'|translate}}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="first">{{'register_form.first_name'|translate}}</label>
              <input type="text" class="form-control" placeholder="" id="first">
            </div>
          </div>
          <!--  col-md-6   -->

          <div class="col-md-6">
            <div class="form-group">
              <label for="last">{{'register_form.last_name'|translate}}</label>
              <input type="text" class="form-control" placeholder="" id="last">
            </div>
          </div>
          <!--  col-md-6   -->
        </div>


        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="company">{{'choose_role.company'|translate}}</label>
              <input type="text" class="form-control" placeholder="" id="company">
            </div>
          </div>
          <!--  col-md-6   -->

          <div class="col-md-6">
              <div class="form-group">
                <label for="company">{{'register_form.city'|translate}}</label>
                <input type="text" class="form-control" placeholder="" id="company">
              </div>
            </div>

        </div>
        <!--  row   -->


        <div class="row">


          <div class="col-md-12">

            <div class="form-group">
              <label for="email">{{'register_form.email_address'|translate}}</label>
              <input type="email" class="form-control" id="email" placeholder="email">
            </div>
          </div>
          <!--  col-md-6   -->

          <div class="col-md-6">

              <div class="form-group">
                <label for="phone">{{'register_form.phone_number'|translate}}</label>
                <input type="tel" class="form-control" id="phone" placeholder="{{'register_form.phone'|translate}}">
              </div>
            </div>
            <!--  col-md-6   -->

          <div class="col-md-6">
            <div class="form-group">
              <label for="url">{{'register_form.alternative_number'|translate}}</label>
              <input type="url" class="form-control" id="url" placeholder="url">
            </div>

          </div>
          <!--  col-md-6   -->
        </div>
        <!--  row   -->

        <div class="checkbox">
          <label>
            <input type="checkbox" value="Sure!" id="newsletter"> {{'register_form.acceptance_regulation'|translate}}
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" value="Sure!" id="newsletter"> {{'register_form.acceptance_privacy_statement'|translate}}
          </label>
        </div>

        <button type="submit" class="btn btn-primary" (click)='OnRegister()'>{{'choose_role.register'|translate}}</button>
        <button class="btn btn-primary" (click)="gotoChooseRole()">{{'register_form.get_back_to_choose_role'|translate}}</button>
      </form>
    </div>
</div>
