import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { RegisterComponent } from './register/register.component';
import { SidecontentModule } from './sidecontent/sidecontent.module';
import { UpdateComponent } from './update/update.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {path: 'welcome', component: WelcomeComponent},
  {path: 'register', component:RegisterComponent},
  {path: 'update-form', canActivate:[AuthGuard], component:UpdateComponent},
  {path: '', canActivate:[AuthGuard], loadChildren:()=>SidecontentModule}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
