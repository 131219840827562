import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from 'src/app/core/service/api.service';
import {HttpClient,HttpParams,HttpHeaders} from '@angular/common/http'
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private auth_: AuthService,
     private http:HttpClient
    ) {}

  checkPermission(moduleArr: any): boolean {
    if (this.auth_.checkLoginStatus()) {
      let userInfo = JSON.parse(localStorage.getItem('users'));
      const userPermissionInfo = JSON.parse(userInfo['permission']);
      let status: boolean = moduleArr.every((element) => {
        return !!+userPermissionInfo[element[0] + ''][element[1] + ''];
      });
      return status;
    } else return false;
    // return true;
  }

  checkObject(object: any, keysArr: Array<string>): boolean {
    return keysArr.every(
      (key) =>
        object[key] != undefined && object[key] != null && object[key] != ''
    );
  }

}
