<div class="registerd-form insert-course">
    <div class="container">
      <div class="side-nav">
        <h3>Modify Course</h3>
        <mat-tab-group #tabGroup [(selectedIndex)]="selectedTab">
         
          <mat-tab label="Enter data" class="rd">
            <p>
              2. You have chosen the <b>{{ courseData.course_name }}</b
              >. Fill out the form below to continue. If you wanted to choose
              another course, click here: Choose another course
            </p>
  
            <div class="row">
              <div class="col-md-12">
                <div class="row rd-in">
                  <div class="col-md-2">
                    <label for="email">Type</label>
                  </div>
  
                  <div class="col-md-10">
                    <div class="rr">
                      <div>
                        <input
                          type="radio"
                          name="radio"
                          id="radio1"
                          value="classroom"
                          [(ngModel)]="courseData.type"
                        />
                        <label class="radio" for="radio1"
                          ><span>Classroom</span>
                        </label>
                        <input
                          type="radio"
                          name="radio"
                          id="radio2"
                          value="FAD"
                          [(ngModel)]="courseData.type"
                        />
                        <label for="radio2"><span>FAD</span></label>
                        <input
                          type="radio"
                          name="radio"
                          id="radio3"
                          value="virtual"
                          [(ngModel)]="courseData.type"
                        />
                        <label for="radio3">
                          <span>{{'insert_new_courses.classroom_virtual'|translate}}</span></label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label for="email">{{'purchases_page.category'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="select">
                        <select
                          name="slct"
                          id="slct"
                          [(ngModel)]="courseData.cost"
                        >
                          <option value="ACTIVE">180,000</option>
                          <option value="INACTIVE">150,000</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label for="email">{{'purchases_page.category'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        [(ngModel)]="courseData.place"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label for="email">{{'course_management.room'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        [(ngModel)]="courseData.place"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <label for="company">{{'insert_new_courses.status'|translate}}</label>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="select">
                        <select
                          name="slct"
                          id="slct"
                          [(ngModel)]="courseData.status"
                        >
                          <option value="ACTIVE">{{'insert_new_courses.active'|translate}}</option>
                          <option value="INACTIVE">{{'insert_new_courses.in_active'|translate}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="date-dd">
                <div class="container mb-2">
                  <div class="row">
                    <div class="col-md-3">
                      <label for="email">{{'purchases_page.date'|translate}}</label>
                    </div>
                    <div class="col-md-3 pl-0">
                      <label for="email">{{'insert_new_courses.time'|translate}}</label>
                    </div>
                    <div class="col-md-2">
                      <label for="email">{{'purchases_page.place'|translate}}</label>
                    </div>
                    <div class="col-md-3">
                      <label for="email">{{'purchases_page.teacher'|translate}}</label>
                    </div>
                    
                       <div class="col-md-1 p-0 d-flex justify-content-center">
                        <img
                          class="p-1 yellow-bg ptr"
                          width="25px"
                          height="25px"
                          (click)="AddNewClass()"
                          src="../../../assets/images/plus-icon.png"
                        />
                      </div>
                  </div>
                </div>
                <div class="main-dd p-0 m-0">
                  <div class="container">
                    <div class="row" *ngFor="let class of classes; let i = index">
                      <div class="col-md-3">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="date"
                            [(ngModel)]="class.date"
                          />
                        </div>
                      </div>
  
                      <div class="col-md-3 pl-0">
                        <div class="w-100 m-0 p-0 d-flex">
                          <div>
                            <input
                              style="font-size:13px;height: 37px;"
                              type="time"
                              placeholder="00:00"
                              [(ngModel)]="class.start_time"
                            />
                          </div>
                          <p class="my-1">:</p>
                          <div>
                            <input
                              style="font-size:13px;height: 37px;"
                              type="time"
                              placeholder="00:00"
                              [(ngModel)]="class.end_time"
                            />
                          </div>
                        </div>
                      </div>
  
                      <div class="col-md-2">
                        <div class="form-group">
                          <div class="select">
                            <select id="slct" [(ngModel)]="class.room">
                              <option [value]="1">room1</option>
                              <option [value]="2">room2</option>
                              <option [value]="3">room3</option>
                            </select>
                          </div>
                        </div>
                      </div>
  
                      <div class="col-md-3">
                        <div class="select delete-cc w-100">
                          <select id="slct" [(ngModel)]="class.teacher_id">
                            <option
                              *ngFor="let teacher of teachers"
                              [value]="teacher.teacher?.id"
                            >
                              {{ teacher.teacher?.first_name }}
                              {{ teacher.teacher?.last_name }}
                            </option>
                            <option [value]="3">teacher1</option>
                            <option [value]="4">teacher2</option>
                            <option [value]="5">teacher3</option>
                            <option [value]="6">teacher4</option>
                            <option [value]="7">teacher5</option>
                          </select>
                        </div>
                        
                      </div>
  
                      <div class="col-md-1 p-0 d-flex justify-content-center">
                        <img
                          class="ptr"
                          src="../../../assets/images/delete-icon.png"
                          width="25px"
                          height="30px"
                          (click)="DeleteClass(i)"
                        />
                      </div>
                    </div>
                    <!----row-->
  
                    <hr />
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="date"
                            name="class_date"
                            [(ngModel)]="classData.date"
                          />
                        </div>
                      </div>
  
                      <div class="col-md-3 pl-0">
                        <div class="w-15 d-flex">
                          <div>
                            <input 
                              style="font-size:13px;height: 37px;"
                              type="time"
                              placeholder="00:00"
                              name="start_time"
                              [(ngModel)]="classData.start_time"
                            />
                          </div>
                           <p class="my-1">:</p>
                          <div class="w-20">
                            <input
                              style="font-size:13px;height: 37px;"
                              type="time"
                              placeholder="00:00"
                              name="end_time"
                              [(ngModel)]="classData.end_time"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <div class="select">
                            <select
                              name="slct"
                              id="slct"
                              [(ngModel)]="classData.room"
                            >
                              <option [value]="1">room1</option>
                              <option [value]="2">room2</option>
                              <option [value]="3">room3</option>
                            </select>
                          </div>
                        </div>
                      </div>
  
                      <div class="col-md-3">
                        <div class="select delete-cc w-100">
                          <select
                            name="slct"
                            id="slct"
                            [(ngModel)]="classData.teacher_id"
                          >
                            <option
                              *ngFor="let teacher of teachers"
                              [value]="teacher.teacher?.id"
                            >
                              {{ teacher.teacher?.first_name }}
                              {{ teacher.teacher?.last_name }}
  
                            </option>
                            <option [value]="3">teacher1</option>
                            <option [value]="4">teacher2</option>
                            <option [value]="5">teacher3</option>
                            <option [value]="6">teacher4</option>
                            <option [value]="7">teacher5</option>
                          </select>
                        </div>
                      </div>
  
                       <div class="col-md-1 p-0 d-flex justify-content-center">
                        <img
                          class="ptr"
                          src="../../../assets/images/delete-icon.png"
                          width="25px"
                          height="30px"
                        />
                      </div>
                    </div>
                    <p class="text-danger m-0" *ngIf="error_msg.length > 0">
                      {{ "*" + error_msg }}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
            <div style="color: blue;">
              <span>{{'insert_new_courses.total_expected_hours'|translate}}:16</span><br>
              <span>{{'insert_new_courses.total_hours_entered'|translate}}:12</span>
            </div>
            <div class="editor">
              <h5>{{'insert_new_courses.internal_notes'|translate}}</h5>
              <ckeditor
                [editor]="Editor"
                data="<p></p>"
                [(ngModel)]="courseData.internal_notes"
              ></ckeditor>
              <br />
              <h5>{{'insert_new_courses.public_notes'|translate}}</h5>
              <ckeditor
                [editor]="Editor"
                data="<p></p>"
                [(ngModel)]="courseData.public_notes"
              ></ckeditor>
            </div>
  
            <div class="btn-g">
              <button type="submit" class="btn btn-primary">{{'company_learner.cancel'|translate}}</button>
              <button
                type="submit"
                class="btn btn-primary active"
                (click)="nextTab([], 2)"
              >
               <!-- {{'update_form.save_changes'|translate}} -->
               DONE
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Summary">
            <div class="editor">
              <ckeditor
                [editor]="Editor"
                data="<p></p>"
                [(ngModel)]="courseData.summary"
              ></ckeditor>
            </div>
            <div class="btn-g">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="selectedTab = 1"
              >
                {{'insert_new_courses.back'|translate}}
              </button>
              <button
                type="submit"
                class="btn btn-primary active"
                (click)="insertCourse()"
              >
               {{'update_form.save_changes'|translate}}
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!---col-md-3---->
  </div>
  