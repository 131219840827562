import { ApiService } from './../../../../core/service/api.service';
import {  Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { CommonService } from 'src/app/core/service/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from './../../../../core/excel.service';
import {HttpClient,HttpParams,HttpHeaders} from '@angular/common/http'

export interface PeriodicElement {
  title: string;
  date:string;
  class_room: string;
  was: string;
  amount: number;
  members: any;
  action: string;
  course: any;
  // id: number;
}

@Component({
  selector: 'app-cm-table',
  templateUrl: './cm-table.component.html',
  styleUrls: ['./cm-table.component.css'],
})
export class CmTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public translate: TranslateService,
    private api: ApiService,
    public common_: CommonService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private excelService:ExcelService,
    private _toastr: ToastrService,
    private http:HttpClient
  ) {}

  displayedColumns: string[] = [
    'title',
    'date',
    'class_room',
    'amount',
    'was',
    'members',
    'action',
      ];

   dumyData:PeriodicElement[]=[
     {'title':'Fire','action':'','amount':80.00,'class_room':'Room1','course':'test','date':'23-2-2020','members':1,'was':'Draft'},
     {'title':'Marketing','action':'','amount':444.00,'class_room':'Room1','course':'test','date':'23-2-2020','members':14,'was':'Online'},
     {'title':'Marketing','action':'','amount':222.00,'class_room':'Room1','course':'test','date':'22-2-2020','members':17,'was':'Conclude'},
     {'title':'Marketing','action':'','amount':241.00,'class_room':'Room1','course':'test','date':'23-2-2020','members':14,'was':'Conclude'}
    ];

  employeeCourseColumns: string[] = ['title','date', 'place','action'];
  dataSource: MatTableDataSource<PeriodicElement>=new MatTableDataSource(this.dumyData);
   
  citydata =[];
  raw_data = [];
  categories = [];
  jobs = [];
  userRole: string = '';
  EmployeeData: MatTableDataSource<any[]>;
  
  searchedDataColumns:string[]=[
  'id',
  'title',
  'job',
  'place',
  'start_date',
  'end_date',
  'amount',
  'duration',
  'action'];

  
  companyColumns:string[]=[
    'id',
    'title',
    'place',
    'start_date',
    'end_date',
    'amount',
    'duration',
    'action'];

  uniqueJobs: any = [];
  // selectedJob: string = null;

  //Define the search form property with the names of the input
  searchForm = this.formBuilder.group({
    courseTitle: '',
    courseCity: '',
    courseDate: '',
    courseCategory: '',
    courseJob: '',
  });
  
  searchCourse() {

    
    this.api.POST('course/search', this.searchForm.value).then((res) => {
      
      //  Check if the search was successfull
        if (res['body']['message'] === 'retrieved successfully') {
          let arr = [];

          // Assign raw_data to the data in the body
          this.raw_data = res['body']['data'];
          this.raw_data.forEach((item) => {
            let n = {
              id: item.course ? item.course.id : null,
              title: item.course ? item.course.title : '-',
              start_date: item.training_course
                ? item.training_course.start_date
                : '-',
              end_date: item.training_course
                ? item.training_course.end_date
                : '-',
              date: item.training_course
                ? item.training_course.created_at
                : '-',
              place: item.training_course ? item.training_course.place : '-',
              amount: item.training_course ? item.training_course.cost : '-',
              duration: '-',
              members: '10',
              was: 'test',
              action :''
            };
            arr.push(n);
          });
         
          // commented for dummy data into table 
           this.dataSource = new MatTableDataSource(arr);
          this.dataSource.paginator = this.paginator;
        }
      },
      (err) => {
        this._toastr.error(err);
      }
    );
   
  }

  // The clear fields function, to clear the search form inputs
  clearFields()
  {
    this.searchForm.reset();
  }
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataSource.filteredData, 'course-list');
  }  
  ngOnInit(): void {

    // Get cities
    
    this.http.get("https://countriesnow.space/api/v0.1/countries/population/cities").subscribe((response)=>{
          
          let d1= [];
        response['data'].forEach((element) => {
          let n = {
            name : element['city']
          }
             d1.push(n);
        });

       this.citydata = d1

      },
      (error) =>{
        console.log("Error Message");
      },
      () =>{
        console.log("Request Completed");
      }
      );

    this.userRole = this.auth.getUserRole();

    //  Get category list

  this.api.GET('category-list').then((res) => {
     let data = [];
      if (res['message'] == 'retrieved successfully') {
        let tabledata= res['data'];
      tabledata.forEach((element) => {
          let arr= {
            id : element.id,
            name : element.name,
           };
           data.push(arr);
        });
      this.categories = data;
      }
    });

    // Get the jobs list

  this.api.GET('jobs').then((res) => {

      if (res['message'] == 'Jobs retrieved successfully') {

        console.log("these are the jobs",res['data'])
         this.jobs = res['data'];
      }
      
    });

    // Get the course list for training center panel

  if (this.auth.getUserRole() === 'Training Center') {
      this.api.GET('course-list').then((res) => {
        
        if (res['message'] === 'retrieved successfully') {
          let arr = [];
          this.raw_data = res['data'];
          this.raw_data.forEach((item) => {
            let n = {
              id: item.course ? item.course.id : null,
              title: item.course ? item.course.title : '-',
              start_date: item.training_course
                ? item.training_course.start_date
                : '-',
              end_date: item.training_course
                ? item.training_course.end_date
                : '-',
              date: item.training_course
                ? item.training_course.created_at
                : '-',
              place: item.training_course ? item.training_course.place : '-',
              amount: item.training_course ? item.training_course.cost : '-',
              duration: '-',
              members: '10',
              was: 'test',
              action :''
            };
            arr.push(n);
          });
         
          // commented for dummy data into table 
           this.dataSource = new MatTableDataSource(arr);
          this.dataSource.paginator = this.paginator;
        }
      });
  }
    
   // Get the course list for company panel

  if (this.auth.getUserRole() === 'Company') {
      let arr = [];
      this.api.GET('course-list').then((res) => {
        
        if (res['message'] === 'retrieved successfully') {
          let arr = [];
          this.raw_data = res['data'];
          this.raw_data.forEach((item) => {
            let n = {
              id: item.course ? item.course.id : null,
              title: item.course ? item.course.title : '-',
              start_date: item.training_course
                ? item.training_course.start_date
                : '-',
              end_date: item.training_course
                ? item.training_course.end_date
                : '-',
              date: item.training_course
                ? item.training_course.created_at
                : '-',
              place: item.training_course ? item.training_course.place : '-',
              amount: item.training_course ? item.training_course.cost : '-',
              duration: '-',
              members: '10',
              was: 'test',
              action :''
            };
            arr.push(n);
          });
         
          // commented for dummy data into table 
           this.dataSource = new MatTableDataSource(arr);
          this.dataSource.paginator = this.paginator;
        }
      });


  }

    // Get the course list for employee panel

    if (this.auth.getUserRole() === 'Employee') {
      let array = [];
      this.api.GET('course-list').then((res) => {
        if (res['message'] === 'retrieved successfully') {
          let arr = [];
          this.raw_data = res['data'];
          this.raw_data.forEach((item) => {
            let n = {
              id: item.course ? item.course.id : null,
              title: item.course ? item.course.title : '-',
              start_date: item.training_course
                ? item.training_course.start_date
                : '-',
              end_date: item.training_course
                ? item.training_course.end_date
                : '-',
              date: item.training_course
                ? item.training_course.created_at
                : '-',
              place: item.training_course ? item.training_course.place : '-',
              amount: item.training_course ? item.training_course.cost : '-',
              duration: '-',
              members: '10',
              was: 'test',
              action :''
            };
            arr.push(n);
          });
         
          // commented for dummy data into table 
           this.dataSource = new MatTableDataSource(arr);
          this.dataSource.paginator = this.paginator;
        }
      });
    }
  }

 
  
}
