<div data-purpose="smart-bar-container"  >
  <div data-purpose="smart-bar" class="smart-bar1">
    <div style="text-align: center;color:white;">
      <span>
          {{'summary_page.sub_header_text'|translate}}</span>
      <span>
        <p          
          arial-label="Close"
          data-purpose="smart-bar-hide"
          style="float: right; margin-right: 35px;color: #000;"
        >
          X
        </p>
      </span>
    </div>
  </div>
 <div class="row"
  *ngIf="userRole !== 'Training Center' && userRole !== 'Company'">
 
    <div class="col-lg-12">
      <div class="summery-dd-m pie-chart" >
        <h3 class="" style="font-size:18px;min-max:200px;" >{{"Current  Courses "|translate}}</h3>
       
         <div *ngFor="let course of latest_dataSource">
         <div class="text-right"><h5>Date and Time</h5></div>
           <div class="row li ml-3">

            <div class="col">
            <div class="row">
                <div> <h4 >{{course?.title}}</h4></div>
           </div>
            <div class="row">
               <div style="text-color:rgb(56 66 150)">{{course?.place}}</div>
               
            </div>
          </div>
         
             <div class="text-right">
              <p class="text-right">{{course?.date}}</p>
              <p class="text-right">{{course?.start_time}} Onwards</p>
            </div>
          </div>

          
       
          </div>
      
      </div>
    </div>
    <div class="col-lg-12">
      <div class="summery-dd-m pie-chart" >
        <h3 class="" style="font-size:18px">{{"Upcomming Courses "|translate}}</h3>
       
         <div *ngFor="let course of latest_dataSource">
         <div class="text-right"><h5>Date and Time</h5></div>
           <div class="row li ml-3">

            <div class="col">
            <div class="row">
                <div> <h4>{{course?.title}}</h4></div>
           </div>
            <div class="row">
               <div style="text-color:rgb(56 66 150)">{{course?.place}}</div>
               
            </div>
          </div>
            <div class="text-right">
              <p class="text-right">{{course?.date}}</p>
              <p class="text-right">{{course?.start_time}} Onwards</p>
            </div>
          </div>
      
      </div>
    </div>
  </div>
</div>
 <div class="row"
  *ngIf="userRole === 'Training Center'">
 
    <div class="col-md-6">
      <div class="summery-dd-m pie-chart" >
        <h3 class="text-center" style="font-size:18px">{{"Upcomming Calender Courses "|translate}}</h3>
        <ul>
         
            <li *ngFor="let course of latest_dataSource">
              
          
              {{course.title}}<br/>
              {{course.place}} {{course.date}}
              
            </li>
         <li *ngIf="latest_dataSource.length<=0">
              No upcomming course
            </li>
   
        </ul>
       
       
      
      </div>
    </div>
    <div class="col-md-6">
      <div

        style="clear: both"
        class="pie-chart summery-dd-m"
      >
          <h3  class="text-center" style="font-size:18px">{{"Latest Courses Concluded "|translate}}</h3>
        <ul>
           
          <li *ngIf="concluded_dataSource.length<=0">
              No concluded course
            </li>
            <li *ngFor="let course of concluded_dataSource">
               {{course.title}}<br/>
              {{course.place}} {{course.date}}           
            </li>
         
   
        </ul>
       
      </div>
    </div>
  </div>
 <style type="text/css">
          .li:before {
            list-style: none;
              content: "\2B24";
              color: #372F95;
              font-size: 10px;
              margin: 2px;
          }
a{
  color: #00b7ff;
}
        </style>

  <div class="row">
   <div class="col-md-6"
         *ngIf="dataLoaded">
      <div class="pie-chart summery-dd-m" style="height:auto;">
        <h3 style="font-size:18px" class="text-center">{{'summary_page.purchased_course_calender'|translate}}</h3>
        
          <div *ngFor="let course of courses_purchased">
           <div class="row li ml-3">
            <div class="col-lg-9">
            <div class="row">
                <div> {{course?.title}}</div>
           </div>
            <div class="row">
               <div>{{course?.place}}</div>
                <div><ul><li>{{course?.date}}</li></ul></div>
            </div>
          </div>
            <div class="col-lg-1 "><h3>{{course?.number}}</h3></div>
          </div>
          </div>
      

      </div>
    </div>
 

 
    <div class="col-md-6"
     *ngIf="userRole === 'Company'">
      <div class="summery-dd-m pie-chart" style="height:auto;" >
        <h3 style="font-size:18px" class="text-center">{{'summary_page.last_learners_inserted'|translate}}</h3>   
        
        <div *ngFor="let learner of last_added_learners">
          <div class="row li ml-3">
            <div class="col-sm">
             <div>{{learner.first_name}}</div>
              <div class="row">
                  <div>{{learner.last_name}}</div>
                  <div class="d"><ul><li> {{learner.created_at|date:'Y-M-dd  '}} </li></ul></div>
              </div>       
            </div>
          </div>
        </div>

        <div class="text-right">
             <a href="#"><span class="badge">{{"View all DISCENTS" }}</span>{{ "" }}</a>
        </div>
 
      </div>

    </div>
</div>


  <div
   class="row mt-3" >
    
     <div class="{{userRole === 'Company'?'col-lg-7':'col-sm'}}">
      <div
        *ngIf="userRole === 'Training Center' || userRole === 'Company'"
        style="clear: both;height:auto;"
        class="pie-chart row summery-dd-m">
      <div>
        <h3 style="float: left ;font-size:18px" class="text-center" *ngIf="userRole === 'Training Center'">Courses Sold</h3>
         <h3 style="float: right;font-size:18px" *ngIf="userRole === 'Training Center'">1300</h3>

      </div>
      <style>
      .mat-button-toggle-group {
        height: 30px;
        align-items: center;
        padding: 3px !important;
        border: none;

      }

      .mat-button-toggle{
          background-color: none;
          color: #000;
          margin-left: 10px;
         line-height: 0px;
           border: none !important;
          width: fit-content;
           padding:0px !important;
          border-radius: 20px 20px 20px 20px !important;
       
      }
      .mat-button-toggle  .label {
      line-height: 0px !important;
    }
      .mat-button-toggle-checked {
          background-color: #372F95;
          color: #fff !important;
      }
      </style>
        <h3 class="text-center" style="font-size:18px" *ngIf="userRole === 'Company'">
        {{'summary_page.training_plan'|translate}}</h3>
          <div class="row mb-4"
            style="clear: both" >
            <mat-button-toggle-group (change)="toggleGraph($event.value)">
              <mat-button-toggle value="all">All Time</mat-button-toggle>
              <mat-button-toggle value="year">This Year</mat-button-toggle>
              <mat-button-toggle value="week">This Week</mat-button-toggle>
              <mat-button-toggle value="today">Today</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div style="width: 100%" class="chart">
          <canvas id="myChart" width="700" height="250" #mychart></canvas>
        </div>
      </div>
    </div>

    <div class="{{userRole === 'Company'?'col-lg-5':'col-sm'}}" *ngIf="userRole === 'Company'">
      <div  style="clear: both;width:fit-content;" class="pie-chart summery-dd-m" style='height: auto;'> 
        <h3 class="text-center" style="font-size:18px">{{'summary_page.manage_your_budget'|translate}}</h3>
          <div class="inner-content text-center">
            <div class="progress-graph graph-50 big center blue" style='vertical-align: middle;clear: both;'>
              <span>95%</span>
              <div class="slice">
                <div class="bar"></div>
                <div class="fill"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
    

</div>
