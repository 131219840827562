import { NewEditLearnerComponent } from './sidecontent/company-learners/new-edit-learner/new-edit-learner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClientModule,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeRoutingModule } from './home-routing.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { WelcomebannerComponent } from './welcome/welcomebanner/welcomebanner.component';
import { WelcomecoursesComponent } from './welcome/welcomecourses/welcomecourses.component';
import { WelcomecategoriesComponent } from './welcome/welcomecategories/welcomecategories.component';
import { WelcomefindcoursesComponent } from './welcome/welcomefindcourses/welcomefindcourses.component';
import { RegisterComponent } from './register/register.component';
import { ChooseRoleComponent } from './register/choose-role/choose-role.component';
import { RegisterBannerComponent } from './register/register-banner/register-banner.component';
import { RegisterFormComponent } from './register/register-form/register-form.component';
import { UpdateComponent } from './update/update.component';
import { UpdateFormComponent } from './update/update-form/update-form.component';
import { SharedModule } from '../shared/shared.module';
import { SigninDialogComponent } from './welcome/signin-dialog/signin-dialog.component';
import { InterceptorService } from '../core/service/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [
        WelcomeComponent,
        RegisterComponent,
        WelcomebannerComponent,
        WelcomecoursesComponent,
        WelcomecategoriesComponent,
        WelcomefindcoursesComponent,
        ChooseRoleComponent,
        RegisterBannerComponent,
        RegisterFormComponent,
        UpdateComponent,
        UpdateFormComponent,
        SigninDialogComponent,
    ],
    imports: [
        MaterialModule,
        CommonModule,
        HomeRoutingModule,
        CarouselModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        RouterModule,
        TranslateModule,
        MatTableModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
    ]
})
export class HomeModule {
  constructor(public translate: TranslateService) {
    translate.addLangs(['en','il']);
    translate.setDefaultLang('en');
    const browserLang=translate.getBrowserLang();
    translate.use(browserLang.match(/en|il/)?browserLang:'en')
  }
}
