import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
  constructor(
    private router: Router,
    private auth_: AuthService,
    public common_: CommonService,
    public translate: TranslateService,
    private auth: AuthService
  ) {}
  userRole: string = '';
  currentRole: string = '';
  userToken:string ='';
  SideNav: any = [];

  roles: any = {
    Company: [
      {
        name: 'Search for a Course',
        route: '/course',
        permissions: [['courses', 'view']],
      },
      {
        name: 'Company Learner',
        route: '/learner',
        permissions: [['learners', 'view']],
      },
      {
        name: 'Courses Purchased',
        route: '/purchases',
        permissions: [['course_purchases', 'view']],
      },
      {
        name: 'Transactions',
        route: '/transactions',
        permissions: [['courses', 'view']],
      },
    ],
    Employee: [
      {
        name: 'Search for Course',
        route: '/course',
        permissions: [['courses', 'view']],
      },
      {
        name: 'Certificate',
        route: '/certificate/my-certificates',
        permissions: [['certificates', 'view']],
      },
      {
        name: 'My Courses',
        route: '/course',
        permissions: [
          ['courses', 'view'],
          ['courses', 'self'],
        ],
      },
    ],
    'Training Center': [
      {
        name:'Master Data',
        route:'/profile/master-data',
        permissions: [['courses', 'view']],
      },
      {
        name: 'Course Management',
        route: '/course',
        permissions: [['courses', 'view']],
      },
      {
        name: 'Teacher Management',
        route: '/teachers',
        permissions: [['teachers', 'view']],
      },
      // {
      //   name: 'Transactions',
      //   route: '/transactions',
      //   permissions: [['courses', 'view']],
      // },
    ],
  };

  // permission: any = {};

  logout() {
    this.auth_.logout().subscribe(
      (res) => {
        this.router.navigate(['/welcome']);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit(): void {
    this.userRole = this.auth.getUserRole();
    this.currentRole = this.auth_.getUserRole();
    this.userToken = this.auth.getUserToken();
    this.SideNav = this.roles[this.currentRole + ''];
  }
}
