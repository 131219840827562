import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { config } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  async POST(endpoint, obj) {
    return await this.http
      .post(config.BASE_URL.concat(endpoint), obj, {
        observe: "events",
      })
      .toPromise();
  }
  async GET(endpoint) {
    return await this.http
      .get(config.BASE_URL.concat(endpoint))
      .toPromise();
  }
  async PUT(endpoint, id, obj) {
    return await this.http
      .put(config.BASE_URL.concat(endpoint, '/', id), obj, {
        observe: "events",
      })
      .toPromise();
  }
  async DELETE(endpoint, id) {
    return await this.http
      .delete(config.BASE_URL.concat(endpoint, '/', id))
      .toPromise();
  }

  
}
