<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <h3>{{'transaction_page.list_of_transactions'|translate}}</h3>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'transaction_page.transactions'|translate}}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{'transaction_page.search_for_transaction'|translate}}"
                #inputvalue
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="btn-g">
              <!-- <button type="submit" class="btn btn-primary">Clear Fields</button> -->
              <button type="submit" 
              class="btn btn-primary active"
               (click)="searchCourse(inputvalue)">
                {{'welcome_page.search'|translate}}
                <img src="../../../assets/images/search.png" />
              </button>
              <!-- <button type="submit" class="btn btn-primary">
                Export XLS <img src="../../../assets/images/attach.png" />
              </button> -->
            </div>
          </div>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="course-manage-table">
  <div class="container">
    <div class="row">

      <div class="col-md-12 my-2">
        <div class="table-div">
          <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef class="text-left">{{'purchases_page.title'|translate}}</th>
              <td mat-cell *matCellDef="let element" class="text-left">
                {{ element.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="transaction_id">
              <th mat-header-cell *matHeaderCellDef>{{'transaction_page.transaction_id'|translate}}</th>
              <td mat-cell *matCellDef="let element">
                {{element.trid}}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>{{'purchases_page.date'|translate}}</th>
              <td mat-cell *matCellDef="let element">
                {{element.date}}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>{{'purchases_page.amount'|translate}}</th>
              <td mat-cell *matCellDef="let element">
                {{element.amount ? element.amount : "-"}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <ng-container>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7" style="color: blue; font-weight: bold; font-size: 20px;">
                  No data matching the filter
                  <span style="color:red; font-weight: bold; font-size: 20px;">
                    "{{inputvalue.value}}"
                  </span>
                </td>
              </tr>
            </ng-container>

          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
         </div>
        </div>
      </div>
    </div>
  </div>
</div>
