<div class="registerd-form insert-course">
  <div class="container">
    <div class="side-nav">
      <h3>{{'course_management.registered_and_certified_management'|translate}}</h3>
      <!-- commented Title,Location & Total Enrollments: below to change Date for temprory change  -->
      <!-- <p *ngIf="courseInfo.title != ''">
        Title: <span>{{ courseInfo.title }}</span>
      </p> -->
      <!-- <p *ngIf="courseInfo.Location != ''">
        Location:
        <span>{{ courseInfo.Location || "-" }}</span>
      </p> -->
         
      <!-- <p>
        Total Enrollments:
        <span>{{ datas.length || 0 }}</span>
      </p> -->
      <p>
        {{'purchases_page.title'|translate}}:
        <span>{{'rc_management.course_title'|translate}}</span>
      </p>
      <p>
        {{'course_management.location'|translate}}:
        <span>Tanit-Via Angelo Scarsellini,147/5, 16149 Genoa GE</span>
      </p>
      <p>
        {{'purchases_page.date'|translate}}:
        <span>27/03/2020 to 28/03/2020</span>
      </p>

      <div class="table-div">
        <table mat-table [dataSource]="datas" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
          <!-- Employee ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
          <!-- Company Name  -->
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>{{'choose_role.company'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.company }}</td>
          </ng-container>
          <!-- Surname Column element?.employee?.last_name -->
          <ng-container matColumnDef="sur_name">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.surname'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.sur_name }}
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{'update_form.name'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="tax_code">
            <th mat-header-cell *matHeaderCellDef>{{'master_data.tax_code'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.tax_code }}</td>
          </ng-container>
          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef>{{'purchases_page.frequency'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.frequency }}</td>
          </ng-container>
          <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef>{{'rc_management.outcome'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.outcome }}</td>
          </ng-container>

          <!-- Weight Column
          <ng-container matColumnDef="enrollment_date">
            <th mat-header-cell *matHeaderCellDef>Enrollment Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.created_at.split("T")[0] }}
            </td>
          </ng-container>

          Weight Column
          <ng-container matColumnDef="certificate">
            <th mat-header-cell *matHeaderCellDef>Certified</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.employee?.certified ? "YES" : "NO" }}
            </td>
          </ng-container> -->

          <!-- this is remove *ngIf="element.employee.certified" for dummy Table form img tag below -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.action'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <img
                (click)="openCertificate(element.action)"
                class="ptr"
                src="../../../assets/images/action-icon.png"
              />
              <!-- <img
                (click)="fileChooser(element)"
                class="rotate ptr"
                src="../../../assets/images/action-icon.png"
              /> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <input
          type="file"
          class="d-none"
          #fileupload
          (change)="uploadCertificate($event)"
        />
      </div>

      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company"
                >{{'rc_management.insert_certificate'|translate}}</label
              >
              <div class="select">
                <select name="slct" id="slct"  [(ngModel)]="emp_id">
                  <option selected disabled>Blue Mario - ABCDEF123456</option>
                  <option *ngFor="let data of datas" value="{{data.id}}">{{data.sur_name}}</option>
                </select>
             
              </div>
            </div>
              
            <button  class="btn btn-primary" (click)="fileupload.click()" >{{'company_learner.browse'|translate}}</button>
            <button type="submit" class="btn btn-primary">{{'rc_management.save'|translate}}</button>
          </div>

          <div class="col-md-12">
            <div class="insert">
              <p>
                {{'rc_management.insert_certificate'|translate}}
                <span
                  ><img src="../../../assets/images/question-icon.png" />{{'rc_management.work_message'|translate}}</span
                >
              </p>
              <button type="submit" class="btn btn-primary">{{'company_learner.browse'|translate}}</button>
              <button type="submit" class="btn btn-primary">{{'rc_management.save'|translate}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
