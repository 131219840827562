<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="col-md-12">
          <form [formGroup]="ateco_data" (ngSubmit)="submitCode()">
          <div class="form-group showInputField">
            <label for="ateco_code">Enter Ateco Code (Risk level will be automatically determined by the sytem based on the ateco code that you enter)</label>
            <input type="text" class="form-control"   formControlName="ateco_code" id="ateco_code" placeholder="e.g 01.01.01">
          </div>
          <button type="submit"  class="btn btn-warning"> Done</button>
        </form>
      </div>
        
      </div>
    </div>
  </div>
  