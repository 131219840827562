<div class="page-header section-padding style-dark full-height dc-eight">
  <div class="full-width-d">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      </div>
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-12">
             <div class="heading-home">
                 <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
             </div>
             <div class="search-box">
              <form class="form" >
                  <div class="input-group">
                      <input class="form-control" type="text" placeholder="{{'welcome_page.search'|translate}}" aria-label="Search" id="mysearches">
                      <div class="input-group-addon">
                          <button class="btn btn-warning btn-sm" type="submit" id="search-btn"><i>{{'welcome_page.search'|translate}} </i></button>
                      </div>
                  </div>
              </form>
             </div>
          </div><!-- End Col -->
      </div><!-- End Row -->
  </div>

</div>

<div class="three-b">
  <div class="row">
      <div class="col-md-4 d-flex justify-content-center align-items-center">
          <div class="inner-box d-flex flex-row">
              <img src="../../../../assets/images/arrow.png" class="mr-3">
              <p>100,000 Corsi Online<span>Scopri tanti nuovi argomenti</span></p>
          </div>
      </div>
    <div class="col-md-4 d-flex justify-content-center align-items-center">
        <div class="inner-box d-flex flex-row">
            <img src="../../../../assets/images/right-icon.png" class="mr-3">
            <p>100,000 Corsi Online<span>Scopri tanti nuovi argomenti</span></p>
        </div>
    </div>
    <div class="col-md-4 d-flex justify-content-center align-items-center">
        <div class="inner-box d-flex flex-row">
            <img src="../../../../assets/images/clock-icon.png" class="mr-3">
            <p>100,000 Corsi Online<span>Scopri tanti nuovi argomenti</span></p>
        </div>
    </div>
  </div>
</div>
