import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-puchases',
  templateUrl: './puchases.component.html',
  styleUrls: ['./puchases.component.css'],
})
export class PuchasesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private api: ApiService,  
    private _Activatedroute: ActivatedRoute,
    public common_: CommonService,
    public translate: TranslateService
    ) {}

  displayedColumns: string[] = [
    'title',
    'date',
    'place',
    'learner',
    'amount',
    'action',
  ];
  dummyData: any[] = [
    {'title':'Fire','date':'10/08/2019','place':'Genoa','learner':'2','amount':'120.00','action':''},
    {'title':'Fire','date':'25/06/2019','place':'Genoa','learner':'2','amount':'80.00','action':''},
    {'title':'Fire','date':'14/04/2019','place':'Genoa','learner':'4','amount':'1,600.00','action':''},
    {'title':'Fire','date':'10/08/2019','place':'Genoa','learner':'4','amount':'120.00','action':''},
    {'title':'Fire','date':'25/06/2019','place':'Genoa','learner':'1','amount':'80.00','action':''},
    {'title':'Fire','date':'14/04/2019','place':'Genoa','learner':'2','amount':'1,600.00','action':''},
    {'title':'Marketing','date':'02/03/2019','place':'Genoa','learner':'1','amount':'150.00','action':''}
  ];
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource(
    this.dummyData
  );
  raw_data = [];
  id: any;
  categories = [];
  learner_data =[]
  searchCourse(courseTitle) {
    let filterValue: string = courseTitle.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));

    this.api.GET('purchased-course-list').then((res) => {
  
      if (res['message'] === 'retrieved successfully') {
        let arr = [];
        this.raw_data = res['data'];
        this.raw_data.forEach((item) => {
          let n = {
            id: item.courselist ? item.courselist.id : null,
            title: item.courselist ? item.courselist.title : '-',
            start_date: item.training_course
              ? item.training_course.start_date
              : '-',
            end_date: item.training_course
              ? item.training_course.end_date
              : '-',
            date: item.training_course
              ? item.training_course.created_at
              : '-',
            place: item.training_course ? item.training_course.place : '-',
            amount: item.training_course ? item.training_course.cost : '-',
            duration: '-',
            learner: '10',
            was: 'test',
            action :''
          };
          arr.push(n);
        });
       
        // commented for dummy data into table 
         this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
      }
    });

    this.api.GET('category-list').then((res) => {
       let data = [];
        if (res['message'] == 'retrieved successfully') {
          let tabledata= res['data'];
        tabledata.forEach((element) => {
            let arr= {
              id : element.id,
              name : element.name,
             };
             data.push(arr);
          });
        this.categories = data;
        }
    });

    this.api.GET('company/get-employee-list').then((res) => {
    
     this.raw_data = res['data'][0]?.['employees'];
     console.log(res['data']);
      let arr = [];

      this.raw_data.forEach((element)=>{
        let n= {
          id : element.id,
          name:element.first_name +" "+element.last_name,
        }
        arr.push(n)
      })

   
      this.learner_data = arr;

    });
  }
}
