import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { config } from 'src/app/core/config/config';
import { AuthResponse } from '../models/auth-response';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  username: string

  getUserToken() {
    const userInfo = JSON.parse(localStorage.getItem('users'))
    return userInfo.token
  }
  getUserName() {
    const userInfo = JSON.parse(localStorage.getItem('users'))
    return userInfo.user.name
  }

  getUserEmail() {
    const userInfo = JSON.parse(localStorage.getItem('users'))
    return userInfo.user.email
  }
  getUserInfo() {
    if (this.checkLoginStatus()) {
      const userInfo = JSON.parse(localStorage.getItem('users'))
      return userInfo.user
    } else return false;
  }

  getUserRole() {
    if (this.checkLoginStatus()) {
      const userInfo = JSON.parse(localStorage.getItem('users'))
      return userInfo.user_role
    } else return false;
  }


  autoSignIn() {
    const userData = JSON.parse(localStorage.getItem('users'))

    if (!userData) {
      return
    }
    else {
      console.log(userData)
      this.username = userData.user.name
    }
  }

  //-------------------------------------------------

  signIn(email: string, password: string) {
    return this.http.post<AuthResponse>(config.BASE_URL + 'login', {
      email: email,
      password: password,
    }).pipe(
      map(result => {
        if (result) {
          localStorage.setItem('users', JSON.stringify(result))
          this.loginStatus.next(true)
          console.log('LOGIN RESPONSE', result)
        }
        return result
      })
    )
  }

  //------------------------------------------------

  logout() {
    return this.http.get<any>(config.BASE_URL + 'user/logout').pipe(
      map(result => {
        localStorage.removeItem('users')
        this.router.navigate(['welcome'])
        this.loginStatus.next(false)
      })
    )
  }

  tokenExpired() {
    localStorage.removeItem('users')
    this.router.navigate(['welcome'])
  }

  //----------------------------------------------------

  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus())


  checkLoginStatus(): boolean {
    if (!!localStorage.getItem('users')) {
      let userInfo = JSON.parse(localStorage.getItem('users'));
      return (!!userInfo['user_role'] && !!userInfo['user'] && !!userInfo['token'] && !!userInfo['permission']);
    } else {
      return false
    }
  }

  get isLoggedin() {
    return this.loginStatus.asObservable();
  }


}
