import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/service/api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { BuyConfDialogComponent } from 'src/app/shared/buy-conf-dialog/buy-conf-dialog.component';
import { RequestAssociationDialogComponent } from 'src/app/shared/request-association-dialog/request-association-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface PeriodicElement {
  course: any;
  id: number;
  training_center: any;
}

export interface classes {
  date: string;
  end_time: string;
  room: string;
  start_time: string;
  teacher_id: string;
}

@Component({
  selector: 'app-request-association',
  templateUrl: './request-association.component.html',
  styleUrls: ['./request-association.component.css']
})
export class RequestAssociationComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    public translate: TranslateService
  ) {

   }
   id: any;
   dataSource: PeriodicElement[] = [];
   classDetail: classes[] = [];
   magnifiedData: any;
   userRole: string;
 
   title: string;
   public_notes: string;
   description: string;
   date: string;
   place: string;
 
   buttonRole: string;
   raw_data = [];
 
   data = [];
   courseData = [];
   buyCourse() {
     console.log('--', {
       course_name: this.courseData['title'],
       course_id: this.id,
       tc_id: 4,
     });
     this.dialog.open(BuyConfDialogComponent, {
       width: '60vw',
       minHeight: '10vh',
       data: {
         course_name: this.courseData['title'],
         course_id: this.id,
         tc_id: 4,
       },
     });
   }
 
   buyCase() {
     //alert(this.magnifiedData.tc_id +" and "+this.id)
 
     this.dialog.open(RequestAssociationDialogComponent, {
       width: '60vw',
       minHeight: '10vh',
       data: {
         course_name: this.courseData['title'],
         course_id: this.id,
         tc_id: 4,
       },
     });

   }
 
   getDataById(ids: any) {
     return this.dataSource.find((x) => {
       if (x.course) {
         return x.course.id === ids;
       } else {
         return false;
       }
     });
   }
 
   getDataByIds(ids: any) {
     //console.log(this.dataSource)
     return this.dataSource.find((x) => {
       return x.id === ids;
     });
   }

  ngOnInit(): void {
    this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));
   

    this.userRole = this.auth.getUserRole();

    if (this.auth.getUserRole() === 'Training Center') {
      this.buttonRole = 'Registered and certified management';
      this.api.GET('course-list').then((res) => {
        console.log('%c COURSE LIST', 'background-color: yellow', res);
        if (res['message'] === 'retrieved successfully') {
          let data = res['data'];
          this.dataSource = data;
          var dataById = this.getDataById(this.id);
          this.magnifiedData = dataById;
          console.log(this.magnifiedData);
          console.log(this.magnifiedData.training_course.class_room_det);
          this.classDetail = JSON.parse(
            this.magnifiedData.training_course.class_room_det
          );
          console.log(this.classDetail);

          this.title = this.magnifiedData.course.title;
          this.public_notes = this.magnifiedData.training_course.public_notes;
          this.description = this.magnifiedData.training_course.description;
          this.place = this.magnifiedData.training_course.place;
        }
      });
    }


  }

}
