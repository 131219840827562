<div class="container">
  <div class="row">
    <div class="side-nav col-12">
      <h3>My course</h3>
    </div>
    <div class="col-6 col-sm-6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{'company_learner.search_learner'|translate}}er"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          #inputvalue
        />
        <div class="input-group-append" (click)="searchLearner(inputvalue)">
          <span class="input-group-text" id="basic-addon2">
            <img class ="search_icon" src="../../../assets/images/search.png" />
          </span>
        </div>
      </div>
    </div>
    <div style="width: 180px; margin-left: auto" class="col-sm-3 col-3 mb-3">
         <a routerLinkActive="active" class="btn btn-primary">Download All</a>
    </div>
  </div>
</div>

<table mat-table [dataSource]="mycourse" class="mat-elevation-z8">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef class="text-left">Title</th>
    <td mat-cell *matCellDef="let element" class="text-left">
      {{ element.title }}
    </td>
  </ng-container>
  <ng-container matColumnDef="starts">
    <th mat-header-cell *matHeaderCellDef>Starts</th>
    <td mat-cell *matCellDef="let element">
      {{ element.starts }}
    </td>
  </ng-container>

  <ng-container matColumnDef="finished">
    <th mat-header-cell *matHeaderCellDef>Finished</th>
    <td mat-cell *matCellDef="let element">
      {{ element.finished }}
    </td>
  </ng-container>
  <ng-container matColumnDef="place">
    <th mat-header-cell *matHeaderCellDef>Place</th>
    <td mat-cell *matCellDef="let element">
      {{ element.place }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      {{ element.status }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <img
        *ngIf="element.action == 1"
        class="ptr"
        src="../../../assets/images/search-icon-t.png"
      />
      <img
        *ngIf="element.action == 1"
        class="ptr"
        src="../../../assets/images/action-icon.png"
      />
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
