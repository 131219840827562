<div class="side-nav">
  <div class="container">
    <div class="row">
      <ul>
         <li>
          <a [routerLink]="'/summary'" routerLinkActive="active">{{'side_nav.summary'|translate}}</a>
        </li>
        
        <li>
          <a [routerLink]="'/profile/master-data'" *ngIf="userRole == 'Training Center'" routerLinkActive="active">{{'master_data.master_data'|translate}}</a>
        </li>
        <li>
          <a [routerLink]="'profile/company-profile'" *ngIf="userRole == 'Company'" routerLinkActive="active">Company Profile</a>
        </li>
        <li>
          <a [routerLink]="'/update-form'" *ngIf="userRole == 'Employee'" routerLinkActive="active">{{'master_data.profile_data'|translate}}</a>
        </li>
        <li>
          <a [routerLink]="'/certificate/my-certificates'" *ngIf="userRole == 'Employee'" routerLinkActive="active">Certificate</a>
        </li>
        <li>
          <a [routerLink]="'/mycourses'" *ngIf="userRole == 'Employee'" routerLinkActive="active">My Courses</a>
        </li>
        <li>
          <a [routerLink]="'/course'" *ngIf="userRole == 'Company'"  routerLinkActive="active">{{'side_nav.search_for_a_course'|translate}}</a>
        </li>
        <li>
          <a [routerLink]="'/learner'" *ngIf="userRole == 'Company'"   routerLinkActive="active">{{'side_nav.company_learner'|translate}}</a>
        </li>
        <li>
          <a [routerLink]="'/purchases'" *ngIf="userRole == 'Company'"  routerLinkActive="active">{{'side_nav.courses_purchased'|translate}}</a>
        </li>
        <li>
          <a [routerLink]="'/course'" *ngIf="userRole !== 'Company'" routerLinkActive="active">{{'side_nav.course_management'|translate}}</a>
        </li>
        <!-- <li>
          <a [routerLink]="'/transactions'" *ngIf="userRole !== 'Employee'" routerLinkActive="active">{{'transaction_page.transactions'|translate}}</a>
        </li> -->
       <!-- For side nav translation changes  
           <li *ngFor="let navItem of SideNav">
          <a
            [routerLink]="navItem.route"
            routerLinkActive="active"
            *ngIf="
              navItem.permissions
                ? common_.checkPermission(navItem.permissions)
                : true
            "
            >{{ navItem.name }}
          </a>
        </li>-->
        <li>
          <a [routerLink]="'/calender'" routerLinkActive="active">{{'side_nav.calender'|translate}}</a>
        </li>
        <!-- <li><a (click)="logout()" routerLinkActive="active">Logout</a></li> -->
      </ul>
    </div>
  </div>
</div>
