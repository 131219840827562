import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpParams,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private _auth: AuthService, private _loading: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let sendReq: any;
    if (localStorage.getItem('users') === null) {
      // return next.handle(req)
      sendReq = req;
    } else {
      let modifiedReq = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this._auth.getUserToken(),
        },
      });
      // return next.handle(modifiedReq)
      sendReq = modifiedReq;
    }

    console.log(sendReq);
    this._loading.setLoading(true, req.url);
    return next
      .handle(sendReq)
      .pipe(
        catchError((err) => {
          this._loading.setLoading(false, req.url);
          return err;
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, req.url);
            this.checkToken(evt);
          }
          return evt;
        })
      );
  }

  checkToken(response): void {
    console.log(response);
    if (response['body']?.['error'] == 'TOKEN_EXPIRED') {
      console.log('Token Expired');
      this._auth.tokenExpired();
    } else {
      console.log('Token Not Expired');
    }
  }
}
