import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { AuthService } from './core/service/auth.service';
import { LoadingService } from './core/service/loading.service';
import { SiteDataService } from './core/service/site-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private _loading: LoadingService,
    private _siteData: SiteDataService
  ) {}

  title = 'quid';
  loadingStat: boolean = false;

  ngOnInit() {
    this.auth.autoSignIn();
    this.getLoadingStat();
    this._siteData.setCourseData();
  }

  getLoadingStat(): void {
    this._loading.loadingSubject.pipe(delay(0)).subscribe((stat) => {
      this.loadingStat = stat;
    });
  }
}


