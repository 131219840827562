import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import { ApiService } from 'src/app/core/service/api.service';
export interface PeriodicElement {
  title: string;
  date: string;
  place: string;
  amount: any;
  status: string;
  action: number;
}
  
const learner_card: PeriodicElement[] = [
  // {
  //   title: 'Low risk fire',
  //   date: '19/03/2020',
  //   place: 'Genoa',
  //   amount: '80.00',
  //   status: 'Complete it',
  //   action: 1,
  // },
  // {
  //   title: 'First aid',
  //   date: '26/04/2020',
  //   place: 'Genoa',
  //   amount: '125.00',
  //   status: 'On going',
  //   action: 0,
  // },
  // {
  //   title: '',
  //   date: 'Total',
  //   place: '2 courses',
  //   amount: '222.00',
  //   status: '',
  //   action: 0,
  // }
];

@Component({
  selector: 'app-learner-card',
  templateUrl: './learner-card.component.html',
  styleUrls: ['./learner-card.component.css'],
})
export class LearnerCardComponent implements OnInit {
  displayedColumns: string[] = [
    'title',
    'date',
    'place',
    'amount',
    'status',
    'action',
  ];
  ds = learner_card;
  
  constructor(
    private router: Router,
    private auth: AuthService, 
     private api: ApiService,
   private _Activatedroute: ActivatedRoute
   ) {
  }
id: any;
learnersdata = [];
t_amount = 0;
coursedata = [];
  personal_data: any = {
    Name: this.auth.getUserName(),
    Email: this.auth.getUserEmail(),
    Total_cert: 0,
  };

  ngOnInit(): void {
      this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));
// getEMployeeEnrollments

      this.api.GET("getEmployeeData/"+this.id).then((res)=>{
        let arr = [];
        if (res['message'] === 'Details retrieved successfully') {
              // this.raw_data=res['course'];
          console.log('%c Learners data', 'background-color: yellow', res['Employee-details']);
        
          this.learnersdata = res['Employee-details']

        }
      });
     this.api.GET("getEMployeeEnrollments/"+this.id).then((res)=>{
        let arr = [];
        let raw_data =[];
        let totalamount = 0;
        if (res['message'] === 'retrieved successfully') {
              // this.raw_data=res['course'];
          console.log('%c Courses data', 'background-color: yellow', res['data']);
        
          let data = res['data']
        
       data.forEach((element) => {
        totalamount = totalamount+ parseInt(element.amount);
          let arr= {
            title : element.title,
            date : element.start_date,
            amount : element.amount,
            place : element.place,
            status : "Enrolled",
            action : 1,
           };
           raw_data.push(arr);
        });
       this.t_amount = totalamount;
     let total ={
            title: '',
            date: 'Total',
            place: raw_data.length+' courses',
            amount: totalamount,
            status: '',
            action: 0,
      };
      raw_data.push(total);
    this.ds = raw_data
        }
      });
  }
}
// place: data.length+' courses',