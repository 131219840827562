import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/service/api.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-new-job-dialog',
  templateUrl: './new-job-dialog.component.html',
  styleUrls: ['./new-job-dialog.component.css']
})
export class NewJobDialogComponent implements OnInit {

  jobData={
    title: '',
    description: ''
  };

  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<NewJobDialogComponent>,
    private api: ApiService,
  ) { 
   
  }
  ngOnInit(): void {
  }

  

  saveJOb(){
    this.api.POST('addjob',this.jobData).then(res=>{
      this.toastr.success('click here to close it', 'Job Add Successful')
      this.dialogRef.close();
    })
    .catch(err=>{
      this.toastr.error('click here to close it', 'Something is wrong');
    })
  }
}
