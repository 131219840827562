<footer>
  <div class="container">
      <div class="row">
          <div class="col-md-5">
              <div class="social-div">
                  <img src="../../../assets/images/footer-logo.png">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <ul>
                      <li><a href="https://www.instagram.com/accounts/login/"><img src="../../../assets/images/instagram.png"></a></li>
                      <li><a href="https://www.facebook.com/"><img src="../../../assets/images/facebook.png"></a></li>
                      <li><a href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"><img src="../../../assets/images/twitter.png"></a></li>
                      <li><a href="https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin"><img src="../../../assets/images/Linkedin Logo.png"></a></li>
                  </ul>
              </div>
          </div>

          <div class="col-md-3">
              <div class="footer-nav">
                  <ul>
                      <li><a href="">> {{'footer.about_us'|translate}}</a></li>
                      <!-- <li><a href="">> {{'footer.social'|translate}}</a></li> -->
                      <li><a href="">> {{'header.support'|translate}}</a></li>
                      <li><a href="">> {{'footer.regulation'|translate}}</a></li>
                      <li><a href="">> Privacy</a></li>
                      <li><a href="">> {{'footer.cookie_policy'|translate}}</a></li>
                      <li><a href="" routerLink="/register" routerLinkActive="active"> > {{'choose_role.register'|translate}} </a></li>

                  </ul>
              </div>
          </div>

          <div class="col-md-4">
              <div class="footer-subscribe">
                  <h2>{{'footer.customer_service'|translate}}</h2>
                  <p>{{'footer.text'|translate}}</p>

                      <div class="search-box">
                          <form class="form" >
                              <div class="input-group">
                                  <input class="form-control" type="text" placeholder="{{'footer.placehoder'|translate}}" aria-label="Search" id="mysearch">
                                  <div class="input-group-addon">
                                      <button class="btn btn-warning btn-sm" type="submit" id="search-btn"><i>{{'footer.subscribe'|translate}} </i></button>
                                  </div>
                              </div>
                          </form>
                         </div>

              </div>
          </div>

      </div>
  </div>
</footer>
