<div class="container-fluid" *ngIf="LoginMode">
  <div class="row">
    <div class="col-md-12 col-12">
      <h3 class="title text-center">{{'header.sign_in'|translate}}</h3>
    </div>
    <div class="col-md-12">

      <form [formGroup]="formSet" (ngSubmit)="OnSubmit()" class="mx-auto" style="width: 90%;">

          <div class="form-group">

                <label for="exampleInputEmail1">{{'sign_in_dialog.email_id'|translate}}</label>

                <input formControlName="email" type="text" class="form-control" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="{{'sign_in_dialog.email_id_placeholder'|translate}}">

                <small class="form-text text-muted">*{{'sign_in_dialog.feild_required'|translate}}</small>

          </div>

          <div class="form-group">

                <label for="exampleInputPassword1">{{'sign_in_dialog.password'|translate}}</label>

                <input formControlName="password" type="password" class="form-control"
                id="exampleInputPassword1" placeholder="{{'sign_in_dialog.password_placeholder'|translate}}" >

                <small class="form-text text-muted float-left">
                  *{{'sign_in_dialog.feild_required'|translate}}
                </small>

                <small class="form-text float-right forgot-password" (click)="OnForget()">{{'sign_in_dialog.forgot_password'|translate}}</small>

                <div style="clear: both;"></div>

        </div>


            <button type="submit" class="btn btn-primary">{{'sign_in_dialog.done'|translate}}</button>
            <p class="text-center mt-4">-{{'sign_in_dialog.or'|translate}}-</p>
            <h4 class="register text-center" (click)="OnRegister()" >{{'sign_in_dialog.register_yourself'|translate}}</h4>

        </form>
    </div>
  </div>

</div>


<div class="container-fluid" *ngIf="RecoverMode">
  <div class="row">


    <div class="col-md-12 col-12">
      <h3 class="title text-center">{{'sign_in_dialog.recover_password'|translate}}</h3>
    </div>


    <div class="col-md-12 col-12">
      <p class="message text-center mx-2"><b>
        If you're already registered on QUID but can't remember your password, enter the email address below to access QUID.
      </b></p>
    </div>


    <div class="col-md-12">

      <form [formGroup]="formSet" (ngSubmit)="OnSubmit()" class="mx-auto" style="width: 90%;">

          <div class="form-group">

                <label for="exampleInputEmail1"><b>{{'sign_in_dialog.email_id'|translate}}</b></label>

                <input formControlName="email" type="text" class="form-control" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="Enter your email id">

                <small class="form-text text-muted">*{{'sign_in_dialog.feild_required'|translate}}</small>

          </div>

            <button type="submit" class="btn btn-primary" (click)="OnRecovered()">{{'sign_in_dialog.done'|translate}}</button>
        </form>
    </div>

    <div class="col-md-12 col-12">
      <p class="register-message text-center mx-2 ">
        {{'sign_in_dialog.register_message_1'|translate}} <span (click)="OnRegister()"><b>{{'sign_in_dialog.register_message_2'|translate}}</b></span>
      </p>
    </div>
  </div>

</div>


<div class="container-fluid" *ngIf="RecoverNoticeMode">
  <div class="row">


    <div class="col-md-12 col-12">
      <h3 class="title text-center">{{'sign_in_dialog.recover_password'|translate}}</h3>
    </div>


    <div class="col-md-12 col-12">
      <p class="message-recovery mx-2"><b>
        {{'sign_in_dialog.recovery_message_1'|translate}} <br/>
        {{'sign_in_dialog.recovery_message_2'|translate}} <br/><br/>
        {{'sign_in_dialog.recovery_message_3'|translate}}<br/><br/>
        {{'sign_in_dialog.recovery_message_1'|translate}},<br/>
        {{'sign_in_dialog.recovery_message_4'|translate}}!
      </b></p>
    </div>


  </div>

</div>
