<div
  mat-dialog-title
  class="w-100 d-flex justify-content-between align-items-center blue-bg"
>
  <h4 class="px-3 mt-2 py-1 text-uppercase font-weight-bold text-white">
    {{ data.title }}
  </h4>
  <i class="material-icons text-white mr-3 ptr" (click)="dialogRef.close(false)"
    >cancel
  </i>
</div>

<div class="w-100 px-3 py-1">
  <mat-dialog-content>
    <h5 class="m-0 mt-1 text-uppercase">
      {{ data.message }}
    </h5>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-flat-button
      class="btn btn-primary btn-sm mat-raised-button mat-button-base d-flex align-items-center"
      (click)="dialogRef.close(true)"
    >
      <i class="material-icons">done</i> &nbsp;{{ data.confirm_btn }}
    </button>
    <button
      mat-flat-button
      (click)="dialogRef.close(false)"
      class="btn btn-primary btn-sm mat-raised-button mat-button-base btn-cancel d-flex align-items-center"
    >
      <i class="material-icons">cancel </i>
      &nbsp;{{ data.cancel_btn }}
    </button>
  </mat-dialog-actions>
</div>
