import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/service/api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ViewEncapsulation } from '@angular/core';
import { BuyConfDialogComponent } from 'src/app/shared/buy-conf-dialog/buy-conf-dialog.component';
import { EnrollConfDialogComponent } from 'src/app/shared/enroll-conf-dialog/enroll-conf-dialog.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-buy-entire-course',
  templateUrl: './buy-entire-course.component.html',
  styleUrls: ['./buy-entire-course.component.css']
})
export class BuyEntireCourseComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    public translate: TranslateService
  ) { }
  // courselistnames
    public Editor = ClassicEditor;
    courseData = [];
    raw_data = [];
  ngOnInit(): void {

    this.api.GET('courselistnames').then((res) => {
        
        if (res['message'] === 'retrieved successfully') {
          let arr = [];
          this.raw_data = res['result'];
          console.log('%c COURSE NAMES', 'background-color: yellow', res['result']);
          this.raw_data.forEach((item) => {
            let n = {
              id: item.id,
              title: item.title,
            };
            arr.push(n);
          });
          this.courseData = arr
        }
      });
  }

}
