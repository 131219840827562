<div class="next-course">
  <div class="container">
      <h2>{{'welcome_page.next_courses'|translate}}</h2>
      <div class="">
          <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                  <img src="../../../../assets/images/course-img.png">
                  <p>{{'welcome_page.digital_marketing'|translate}}</p>
                  <div class="rating">
                      <img src="../../../../assets/images/star-icon.png">
                      <p>4,3 <span>(2751)</span></p>
                  </div>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/course-img.png">
                  <p>{{'welcome_page.digital_marketing'|translate}}</p>
                  <div class="rating">
                      <img src="../../../../assets/images/star-icon.png">
                      <p>4,3 <span>(2751)</span></p>
                  </div>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/course-img.png">
                  <p>{{'welcome_page.digital_marketing'|translate}}</p>
                  <div class="rating">
                      <img src="../../../../assets/images/star-icon.png">
                      <p>4,3 <span>(2751)</span></p>
                  </div>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/course-img.png">
                  <p>{{'welcome_page.digital_marketing'|translate}}</p>
                  <div class="rating">
                      <img src="../../../../assets/images/star-icon.png">
                      <p>4,3 <span>(2751)</span></p>
                  </div>
              </ng-template>



            </owl-carousel-o>
      </div>
  </div>

</div>

