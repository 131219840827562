<div class="container">
  <h3>Add New Teacher</h3>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <label for="email">First Name - </label>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="First Name"
              [(ngModel)]="teacher_data.first_name"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label for="email">Last Name - </label>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Last Name"
              [(ngModel)]="teacher_data.last_name"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label for="email">Tax Code - </label>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Tax Code "
              [(ngModel)]="teacher_data.tax_code"
            />
          </div>
        </div>
      </div>
    </div>
    <!---col-md-12--->
  </div>
  <div class="btn-g">
    <button type="submit" class="btn btn-primary">Cancel</button>
    <button
      type="submit"
      class="btn btn-primary active"
      (click)="CreateTeacher()"
    >
      Save Changes
    </button>
  </div>
</div>
