<div class="container">
  <h3>Teachers List</h3>
  <div class="row">
    <div class="col-6 col-sm-6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Teacher Name"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          #inputvalue
        />
        <div class="input-group-append" (click)="searchTeacher(inputvalue)">
          <span class="input-group-text" id="basic-addon2">
            <img src="../../../assets/images/search.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <a
        routerLink="/teachers/add-teacher"
        routerLinkActive="active"
        class="btn btn-primary"
        *ngIf="common_.checkPermission([['teachers', 'edit']])"
        >Insert New Teacher</a
      >
    </div>

    <div class="table-div">
      <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" >
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name}}
          </td>
        </ng-container>

        <!-- Sirname Column -->
        <ng-container matColumnDef="sirname">
          <th mat-header-cell *matHeaderCellDef>Sir Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.sirname}}
          </td>
        </ng-container>

        <!-- Tax Code Column -->
        <ng-container matColumnDef="taxcode">
          <th mat-header-cell *matHeaderCellDef>Tax Code</th>
          <td mat-cell *matCellDef="let element">
            {{ element.taxcode}}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <img
              class="ptr px-1"
              (click)="DeleteTeacher(element.id)"
              src="../../../assets/images/delete-icon.png"
            />
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7" style="color: blue; font-weight: bold; font-size: 20px;">
              No data matching your search
              <span style="color:red; font-weight: bold; font-size: 20px;">
                "{{inputvalue.value}}"
              </span>
            </td>
          </tr>
        </ng-container>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
