import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate {
  constructor(private common_: CommonService, public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this will be passed from the route config
    // on the data property
    const permission = route.data.permission;
    if (!this.common_.checkPermission(permission)
    ) {
      console.log(`%c Not Permited to view page`, 'background-color:pink;');
      this.router.navigate(['/welcome']);
      return false;
    }
    console.log(`%c Permission to view page`, 'background-color:cyan;');
    return true;
  }

}
