<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <h3>{{'purchases_page.courses_purchased'|translate}}</h3>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'purchases_page.course'|translate}}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{'purchases_page.title'|translate}}"
                #inputvalue
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'update_form.job'|translate}}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{'company_learner.framework'|translate}}"
                #inputvalue
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>{{'purchases_page.category'|translate}}</label>
                <div class="select">
                    <select id="categories" *ngIf="categories">
                      <option *ngFor="let category of categories"  [value]="category.name">
                        {{category.name}}
                      </option>
                    </select>  
                </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{'purchases_page.learner'|translate}}</label>
              <div class="select">
                <select id="categories" *ngIf="learner_data">
                    <option *ngFor="let learner of learner_data"  [value]="learner.id">
                        {{learner.name}}
                    </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>{{'purchases_page.date'|translate}}</label>
               <input
                type="date"
                class="form-control"
                #inputdate
              />
            </div>
          </div>
        </div>

        <div class="btn-g">
          <button type="submit" class="btn btn-primary mx-1">
            {{'purchases_page.clear_fields'|translate}}
          </button>

          <!-- <button type="submit" class="btn btn-primary">Clear Fields</button> -->
          <button
            type="submit"
            class="btn btn-primary active"
            (click)="searchCourse(inputvalue)"
          >
          {{'welcome_page.search'|translate}} <img src="../../../assets/images/search.png" />
          </button>
          <button type="submit" class="btn btn-primary mx-1">
            {{'company_learner.export_xls'|translate}} <img src="../../../assets/images/attach.png" />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="course-manage-table">
  <div class="container">
    <div class="row">
      <div
        class="col-md-12 my-2"
        *ngIf="common_.checkPermission([['courses', 'edit']])"
      >
        <a
          routerLink="insert-new-course"
          routerLinkActive="active"
          class="btn btn-primary mt-3"
          >{{'purchases_page.insert_new_course'|translate}}</a
        >
      </div>
      <div class="col-md-12 my-2">
        <div class="table-div">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef class="text-left">
                  {{'purchases_page.title'|translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                  {{ element.title }}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="package">
                <th mat-header-cell *matHeaderCellDef> Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.start_date }}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{'purchases_page.date'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.date }}
                </td>
              </ng-container>
              <ng-container matColumnDef="place">
                <th mat-header-cell *matHeaderCellDef>{{'purchases_page.place'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.place }}
                </td>
              </ng-container>
              <ng-container matColumnDef="learner">
                <th mat-header-cell *matHeaderCellDef>{{'purchases_page.learners'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.learner }}
                </td>
              </ng-container>
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>{{'purchases_page.amount'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amount }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>{{'company_learner.action'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <img
                    class="ptr"
                    src="../../../assets/images/search-icon-t.png"
                    *ngIf="common_.checkPermission([['courses', 'view']])"
                    [routerLink]="['/purchases/view', element.id]"
                  />
                  <!-- <img
                    class="ptr"
                    src="../../../assets/images/search-icon-t.png"
                    *ngIf="common_.checkPermission([['courses', 'view']])"
                    [routerLink]="[
                      'course-detail/',
                      element.course ? element.course.id : null
                    ]"
                  /> -->
                  <!-- <img
                    class="ptr"
                    src="../../../assets/images/search-icon-t.png"
                    *ngIf="common_.checkPermission([['courses', 'edit']])"
                  /> -->
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

              <!-- Row shown when there is no matching data. -->
              <ng-container>
                <tr class="mat-row" *matNoDataRow>
                  <td
                    class="mat-cell"
                    colspan="7"
                    style="color: blue; font-weight: bold; font-size: 20px"
                  >
                    No data matching the filter
                    <span
                      style="color: red; font-weight: bold; font-size: 20px"
                    >
                      "{{ inputvalue.value }}"
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
            <mat-paginator
              [pageSizeOptions]="[5, 10, 20]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
