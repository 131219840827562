import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

export interface GeneralCourses {
  title: string;
  required: string;
  credit: number;
  action: string;
}

export interface classData {
  date: string;
  start_time: string;
  end_time: string;
  room: string;
  teacher_id: string;
}

@Component({
  selector: 'app-insert-new-course',
  templateUrl: './insert-new-course.component.html',
  styleUrls: ['./insert-new-course.component.css'],
})

export class InsertNewCourseComponent implements OnInit {

  
  selectedTab: number = 0;
  courseData: any = {
    course_id: null,
    course_name: null,
    type: 'classroom',
    status: 'ACTIVE',
    place: '',
    start_date: '',
    end_date: '',
    cost: '',
    duration: '',
    internal_notes: '',
    public_notes: '',
    summary: '',
    description: 'some description',
    class_room_det: '',
  };

  error_msg: string = '';

  classData = {
    date: new Date().toISOString().split('T')[0],
    start_time: '12:00',
    end_time: '12:30',
    room: 0,
    teacher_id: 0,
  };

  classes = [];
  teachers: any = [];

  displayedColumns: string[] = ['title', 'required', 'credit', 'action'];
  dataSource: GeneralCourses[] = [
    { title: 'Low risk fire', required: 'Yes', credit: 0, action: '' },
    { title: 'Low risk fire', required: 'Yes', credit: 0, action: '' },
    { title: 'First aid', required: 'Yes', credit: 0, action: '' },
    { title: 'Marketinng', required: 'No', credit: 0, action: '' },
    { title: 'Reading the budget ltd.', required: 'Yes', credit: 2, action: '' },
    { title: 'Microsoft Excel-from Fundamentals to Advanced Level', required: 'Yes', credit: 2, action: '' },
    { title: 'Microsoft Word-From Fundamentals to Advanced', required: 'Yes', credit: 3, action: '' },
    { title: 'Microsoft Access-From Fundamentals to Advanced', required: 'Yes', credit: 4, action: '' },
    { title: 'Leading people', required: 'No', credit: 5, action: '' },
    { title: 'Basic administration', required: 'Yes', credit: 6, action: '' },
  ];

  constructor(
    private _api: ApiService,
    private _common: CommonService,
    private _toast: ToastrService,
    private _router: Router,
    public translate: TranslateService
  ) {}

  public Editor = ClassicEditor;

  ngOnInit(): void {
    this._api.GET('aprovedandassociatedcourses').then((res) => {
      
      if (res['message'] === 'retrieved successfully') {
        let data = res['result'];

        console.log("myapproved courses list",data)

        let arr = [];
        data.forEach((item) => {
          let n = {
            id: item.course ? item.course.id : null,
            title: item.course ? item.course.title : '-',
          };
          arr.push(n);
        });
       
        // commeted bleow line to provide dummy data into table till Api res. not changed 
        this.dataSource = arr;
      }
    });

    this._api.GET('training/get-teacher').then((res) => {
      if (res['message'] === 'retrieved successfully') {
        let data = res['data'];
        console.log("This are theh teacher list data");
        console.log(data, 'background-color: yellow', res);
        this.teachers = data;
      }
      else{
        console.log("Failed to get course list");
      }
    });
  }

  nextTab(data, tab) {
    if (tab === 1) {
      this.courseData.course_id = data[0];
      this.courseData.course_name = data[1];
      this.selectedTab = 1;
    }

    if (tab === 2) {
      if (
        this._common.checkObject(this.courseData, [
          'public_notes',
          'internal_notes',
          'cost',
          'place',
        ]) &&
        this.classes.length > 0
      ) {
        this.selectedTab = 2;
      } else {
        this._toast.error('Please Fill All Fields', 'Error');
      }
    }
  }

  DeleteClass(index) {
    this.classes.splice(index, 1);
    console.log(this.classes);
  }

  AddNewClass() {
    if (
      this.classData.start_time != '' &&
      this.classData.end_time != '' &&
      this.classData.room != 0 &&
      this.classData.teacher_id != 0
    ) {
      this.classes.push({
        date: this.classData.date,
        start_time: this.classData.start_time,
        end_time: this.classData.end_time,
        room: this.classData.room,
        teacher_id: this.classData.teacher_id,
      });
      this.error_msg = '';
    } else {
      this.error_msg = 'Please Fill All fields before adding class';
    }
  }

  insertCourse() {
    if (this.courseData.summary != '') {
      this.courseData.start_date = this.classes[0]['date'];
      this.courseData.end_date = this.classes[this.classes.length - 1]['date'];
      let totalDuration = this.classes.map((element) => {
        let hourDiff = Math.abs(
          +element.end_time.split(':')[0] - +element.start_time.split(':')[0]
        );
        let minDiff = Math.abs(
          +element.end_time.split(':')[1] - +element.start_time.split(':')[1]
        );
        return hourDiff * 60 + minDiff;
      });
      this.courseData.duration = totalDuration.reduce((total, element) => {
        return total + element;
      });

      this.courseData.class_room_det = JSON.stringify(this.classes);

      console.log(this.courseData);

      this._api
        .POST('training/createNewCourse', this.courseData)
        .then((res) => {
          if (res['body']?.['status'] == 'success') {
            this._toast.success('Course Inserted Successfully', 'Success');
            this._router.navigate(['/course']);
          }
        });
    } else {
      this._toast.error('Please Fill All fields', 'ERROR');
    }
  }
}
