import { Component, OnInit } from '@angular/core';
import { ExcelService } from './../../../../core/excel.service';
import * as XLSX from 'xlsx';

import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { NewJobDialogComponent } from 'src/app/shared/new-job-dialog/new-job-dialog.component';
import {HttpClient,HttpParams,HttpHeaders} from '@angular/common/http'
import { Router } from '@angular/router';

import { saveAs } from 'file-saver';
import { config } from './../../../../core/config/config';

@Component({
  selector: 'app-importlearners',
  templateUrl: './importlearners.component.html',
  styleUrls: ['./importlearners.component.css']
})
export class ImportlearnersComponent implements OnInit {

  constructor(
     private excelService:ExcelService, 
     private _api: ApiService, 
     private _toastr: ToastrService,
     public dialog: MatDialog,
     private http:HttpClient,
     private router: Router
     ) { }


  selectedFile: File; // Variable to store the selected file

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]; // Get the selected file from the event
  }


  onSubmit() {
    if (this.selectedFile) {
      const reader = new FileReader();

      // Set up the FileReader onload event handler
      reader.onload = (e: any) => {
        const data = e.target.result; // Get the file data
        const workbook = XLSX.read(data, { type: 'binary' }); // Read the XLSX file

        // Access the sheet data
        const sheetName = workbook.SheetNames[0]; // Get the first sheet name
        const sheet = workbook.Sheets[sheetName]; // Get the first sheet
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert sheet data to JSON with headers

        const headers  = jsonData.shift() as any[]; // Extract the headers (first row) from the array
        const jsonArray = jsonData.map(row => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index]; // Use headers as keys in the JSON object
          });
          return obj;
        });

        console.log('Imported data:', jsonArray);
        // Process the imported data as needed

        // Upload the data to the server
        this.uploadData(jsonArray);
      };

      // Read the file as binary data
      reader.readAsBinaryString(this.selectedFile);
    } else {
      // Show an error message if no file is selected
      console.error('No file selected');
    }
  }
  // http://127.0.0.1:8000/downloads/templates/company-employee-import-template.xlsx

  uploadData(data: any) {
    // Perform data upload logic here, e.g., send the data to a server using an HTTP request
    this._api.POST('company/create-employees', data).then(
      (res) => {
        console.log(res);
        if (res['body']['status'] == 'success') {
          this._toastr.success(res['body']['message']);
          this.router.navigate(['/learner']);
        }else if(res['body']['message'] == "email already exist !"){
          this._toastr.error("Email exists!");
        }
      },
      (err) => {
        this._toastr.error(err);
      }
    );
  }


  downloadFile() {
    const url = config.SYSTEM_URL.concat('downloads/templates/company-employee-import-template.xlsx'); // Replace with your actual file URL
    const filename = 'company-employee-import-template.xlsx'; // Replace with the desired filename for the downloaded file
  
    // Make an HTTP GET request to the file URL to fetch the file data as a Blob
    this.http.get(url, { responseType: 'blob' })
      .subscribe(blob => {
        // Save the Blob as a file with the specified filename
        saveAs(blob, filename);
      }, error => {
        console.error('Error downloading file:', error);
        // Handle any errors that occurred during file download
      });
  }
  

  ngOnInit(): void {
  }

}
