import { NgModule } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  exports: [
    MatDialogModule,
    MatChipsModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
  ],
})
export class MaterialModule { }
