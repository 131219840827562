<header class="header-section header-style-1">
  <div class="container">
    <!-- as per discussion with Sayeed sir  -->
    <!-- <div><label for="">{{'welcome_page.choose_language'|translate}}</label>
      <select #langSelect (change)="translate.use(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
      </select></div> -->
    <div class="row align-items-center">
      <div class="col-lg-2 col-6">
        <div class="logo">
          <a routerLink="/welcome">QUID</a>
        </div>
      </div>

      <div class="col-lg-8 offset-lg-2 d-lg-block text-right nodrops">
        <div class="main-menu">
          <ul *ngIf="!(loginStatus$ | async) as loginStatus; else logged">
            <li><a href="">{{'header.discover_quid'|translate}}</a></li>
            <li><a href="">{{'header.prices'|translate}}</a></li>
            <li><a href="">{{'header.support'|translate}}</a></li>
            <li class="sign-c" (click)="signin()">{{'header.sign_in'|translate}}</li>
          </ul>
          <ng-template #logged>
            <ul style="width: 740px">
              <li>{{'header.discover_quid'|translate}}</li>
              <li>{{'header.prices'|translate}}</li>
              <li>{{'header.support'|translate}}</li>
              <!-- temprory change as per doc. 
                <li>
                <a href="">{{ userRole }}</a>
              </li>
              <li
                class="sign-c i-circle dropdown"
                id="LogoutMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ userDetails.name[0] }}
              </li>
              <div
                class="dropdown-menu dropdown-menu-right text-right"
                aria-labelledby="LogoutMenu"
              >
                <button
                  class="dropdown-item"
                  [routerLink]="['/summary']"
                  type="button"
                >
                  Home
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="logout()" type="button">
                 {{'header.discover_quid'|translate}}
                </button>
              </div> -->
              <li><img src="assets/images/user.png"/></li>
              <li
                id="LogoutMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ userName }}
              </li>
              <div
                class="dropdown-menu dropdown-menu-right text-right"
                aria-labelledby="LogoutMenu"
              >
                <button
                  class="dropdown-item"
                  [routerLink]="['/summary']"
                  type="button"
                >
                {{'header.home'|translate}}
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" (click)="logout()" type="button">
                 {{'header.logout'|translate}}
                </button>
              </div>
              <li>
                <div>
                  <img src="assets/images/like.png" />
                </div>
                <!-- <img src="../../../assets/images/like.png" /> -->
              </li>
              <li>
              <div matBadge="4" matBadgeOverlap="false">
                <img src="assets/images/notification.png" />
              </div>
            </li>
            <li>
              <div><label for="">{{'welcome_page.choose_language'|translate}}</label>
                <select #langSelect (change)="translate.use(langSelect.value)">
                  <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
                </select></div>
            </li>
            </ul>
          </ng-template>
        </div>
      </div>

      <div class="col-lg-10 col-6 text-right drops">
        <div class="dropdown">
          <button
            class="btn btn-secondary"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../../../assets/images/burger.png" />
          </button>
          <div
            class="dropdown-menu dropdown-menu-right text-right"
            aria-labelledby="dropdownMenu2"
          >
            <button class="dropdown-item" type="button">{{'header.discover_quid'|translate}}</button>
            <button class="dropdown-item" type="button">{{'header.prices'|translate}}</button>
            <button class="dropdown-item" type="button">{{'header.support'|translate}}</button>

            <button
              *ngIf="!(loginStatus$ | async) as loginStatus; else logg"
              class="dropdown-item"
              type="button"
              (click)="signin()"
            >
            {{'header.sign_in'|translate}}
            </button>
            <ng-template #logg>
              <button class="dropdown-item" (click)="logout()" type="button">
               {{'header.logout'|translate}}
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  
 
</header>
