<div *ngIf="showSelector">
  <app-register-banner></app-register-banner>
  <div class="chose-your-goal">
      <div class="container">
          <div class="row">
          <h2>{{'choose_role.choose_your_role'|translate}}</h2>

  <div class="col-md-4">
      <div class="box-1">
          <h3>{{'choose_role.company'|translate}}</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          <h4>100 {{'choose_role.euros_year'|translate}}</h4>
          <p>Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nulla quam
              velit, vulputate eu pharetra
              nec, mattis ac neque. Duis vulputate commodo lectus,
              ac blandit elit tincidunt id.</p>
              <a (click)="registerCompany()">{{'choose_role.register'|translate}}</a>
      </div>
  </div><!---col-md-4---->

  <div class="col-md-4">
      <div class="box-1">
          <h3>{{'choose_role.training_center'|translate}}</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          <h4>100 {{'choose_role.euros_year'|translate}}</h4>
          <p>Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nulla quam
              velit, vulputate eu pharetra
              nec, mattis ac neque. Duis vulputate commodo lectus,
              ac blandit elit tincidunt id.</p>
              <a (click)="registerTC()">{{'choose_role.register'|translate}}</a>

      </div>
  </div><!---col-md-4---->

  <div class="col-md-4">
      <div class="box-1">
          <h3>{{'choose_role.employee'|translate}}</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          <h4>100 {{'choose_role.euros_year'|translate}}</h4>
          <p>Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Nulla quam
              velit, vulputate eu pharetra
              nec, mattis ac neque. Duis vulputate commodo lectus,
              ac blandit elit tincidunt id.</p>
              <a (click)="registerEmployee()">{{'choose_role.register'|translate}}</a>
      </div>
  </div><!---col-md-4---->


          </div>

      </div>
  </div>
  </div>

  <app-register-form *ngIf="showForm" [role]="selectedRole"></app-register-form>
]
