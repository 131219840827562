<div class="w-100 d-flex justify-content-between">
  <h3 class="h3">Learner's card</h3>
  <div>
    <a routerLink="new-learner" class="btn btn-primary mb-3">Discent editing</a>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <p>First Name:<span>{{ learnersdata['first_name'] }} {{ learnersdata['last_name'] }}</span></p>
  </div>
  <div class="col-md-6">
    <p>Total training expenditure 2020: <span>{{t_amount}}</span></p>
    <p>% on the 2020 budget <span>12%</span></p>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <p>Email:<span>{{ learnersdata['email'] }}</span></p>
  </div>
  <div class="col-md-6">
    <p>Total training expenditure from 2018-<span>444.00</span></p>
  </div>
</div>
<div class="row">
  <div class="col-md-6"></div>
  <div class="col-md-6">
    <p style=" margin-left: auto">4-year average of 111.00</p>
  </div>
</div>
<div class="col-md-12 my-2">
  <div class="table-div">
    <h3 class="h3">Enrolled course</h3>
    <table mat-table [dataSource]="ds" class="mat-elevation-z8">
  <ng-container matColumnDef="title">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">{{ element.title }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.date }}</td>
  </ng-container>

  <ng-container matColumnDef="place">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Place</th>
    <td mat-cell *matCellDef="let element">{{ element.place }}</td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell style="text-align: center;" *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <img *ngIf="element.action == 1"  src="../../../assets/images/search-icon-t.png" />
      <img *ngIf="element.action == 1" src="../../../assets/images/action-icon.png" />
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table> 
  

</div>
</div>
