import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  @Input() role:string=""

  ngOnInit(): void {
  }

}
