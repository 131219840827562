<div *ngIf="loadingStat" class="loading-container justify-content-center align-items-center">
    <div class="loadingio-spinner-pulse-d3uobv5fiwa">
        <div class="ldio-i2azoygecu">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
