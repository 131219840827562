import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { SummaryComponent } from './summary/summary.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { BuyConfDialogComponent } from './buy-conf-dialog/buy-conf-dialog.component';
import { EnrollConfDialogComponent } from './enroll-conf-dialog/enroll-conf-dialog.component';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid';
import { RequestAssociationDialogComponent } from './request-association-dialog/request-association-dialog.component'; // a plugin

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
]);

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        SummaryComponent,
        ConfirmationDialogComponent,
        BuyConfDialogComponent,
        EnrollConfDialogComponent,
        RequestAssociationDialogComponent
    ],
    imports: [
        MaterialModule,
        CommonModule,
        CarouselModule,
        RouterModule,
        FormsModule,
        MatButtonToggleModule,
        FullCalendarModule,
        TranslateModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        SummaryComponent
    ]
})
export class SharedModule {
  constructor(public translate: TranslateService) {
     translate.addLangs(['en','il']);
    translate.setDefaultLang('en');
    const browserLang=translate.getBrowserLang();
    translate.use(browserLang.match(/en|il/)?browserLang:'en')
  }
}
