<div>
  <div   class="registerd-form">
    <div class="container">
      <div class="side-nav">
        <h3>{{ "course_management.list_of_courses" | translate }}</h3>
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>{{ "purchases_page.course" | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="courseTitle"
                  placeholder="{{ 'purchases_page.title' | translate }}"
                 
                />
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="form-group">
                <label>{{ "register_form.city" | translate }}</label>
                <div class="select">
                  <input list="cities" name="slct" id="slct"  formControlName="courseCity">
                    <datalist id="cities" *ngIf="citydata">
                      <option *ngFor="let city of citydata"  [value]="city.name">
                        {{city.name}}
                      </option>
                    </datalist>
                   
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="form-group">
                <label>{{ "purchases_page.date" | translate }}</label>
                <input
                type="date"
                formControlName="courseDate"
                class="form-control"
                #inputDate
              />
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="form-group">
                <label>{{ "register_form.category" | translate }}</label>
                <div class="select">
                  
                    <select id="categories" formControlName="courseCategory" *ngIf="categories">
                      <option *ngFor="let category of categories"  [value]="category.id">
                        {{category.name}}
                      </option>
                    </select>
                   
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="form-group">
                <label>{{ "register_form.job" | translate }}</label>
                <div class="select">
                   <select name="jobs" id="slct" *ngIf="jobs" formControlName="courseJob">
                      <option *ngFor="let job of jobs"  [value]="job.id">
                        {{job.title}}
                      </option>
                    </select> 
                   
                </div>
              </div>
            </div>
          </div>

      
          <div class="btn-g">
            <button
             type="submit"
              class="btn btn-primary"
              (click)="clearFields()"
              >
              {{ "purchases_page.clear_fields" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-primary active"
              (click)="searchCourse()"
            >
              {{ "welcome_page.search" | translate }}
              <img src="../../../assets/images/search.png" />
            </button>
            <button type="submit" class="btn btn-primary"
            (click)="exportAsXLSX()"
            >
              {{ "company_learner.export_xls" | translate }}
              <img src="../../../assets/images/attach.png" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="container  mt-3" *ngIf="userRole == 'Company'">
    <div class="row text-center" style="text-align:center;">
      <div class="col-sm-1"></div>
      <div class="col-md-9">
        <h3 style="background-color:#fff3e6;border-radius:10px;font-weight: bold;font-size: 16px;color: #372f95;" class="p-2 text-info">Do you want to buy an entire course function session?<a routerLink="/course/buy-all-sessions" class="ml-2 btn small_btn">yes</a></h3>
      </div>
    </div>
    
  </div>
  <!-- *ngIf="common_.checkPermission([['courses', 'edit']])" -->
  <div class="course-manage-table">
    <div class="container">
 
      <div class="row">
        <div
          class="col-md-12 my-2"
          *ngIf="userRole === 'Training Center'"
        >
          <a
            routerLink="insert-new-course"
            routerLinkActive="active"
            class="btn btn-primary mt-3"
            >{{ "purchases_page.insert_new_course" | translate }}</a
          >
        </div>

        <!-- ##################################### TRAINING CENTER ################################################# -->
        <div class="col-md-12 my-2">
          <div class="table-div">
            <div
              *ngIf="userRole === 'Training Center'"
              class="mat-elevation-z8"
            >
              <table mat-table [dataSource]="dataSource">
                <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                <!-- Name Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef class="text-left">
                    {{ "purchases_page.title" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.title }}
                  </td>
                </ng-container>

                <!-- Sirname Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "purchases_page.date" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.date }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="class_room">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "course_management.class_room" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.class_room }}
                  </td>
                </ng-container>
         
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "purchases_page.amount" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.amount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="was">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "course_management.was" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.was }}</td>
                </ng-container>
                
                <ng-container matColumnDef="members">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "course_management.members" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.members }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "company_learner.action" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <img
                      class="ptr"
                      src="../../../assets/images/search-icon-t.png"
                      *ngIf="common_.checkPermission([['courses', 'view']])"
                    />
                    <img
                      class="ptr"
                      src="../../../assets/images/action-icon.png"
                      *ngIf="common_.checkPermission([['courses', 'view']])"
                      [routerLink]="['course-detail/', element.id]"
                    />
                    <img
                      class="ptr"
                      src="../../../assets/images/edit-icon.png"
                      *ngIf="common_.checkPermission([['courses', 'view']])"
                      [routerLink]="['course-modify/', element.id]"
                    />
                    <img
                    class="ptr"
                    src="../../../assets/images/star-icon.png"
                    *ngIf="common_.checkPermission([['courses', 'view']])"
                    [routerLink]="['course-request/', element.id]"
                  />
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <ng-container>
                  <tr class="mat-row" *matNoDataRow>
                    <td
                      class="mat-cell"
                      colspan="7"
                      style="color: blue; font-weight: bold; font-size: 20px"
                    >
                      No data matching the filter
                      <span
                        style="color: red; font-weight: bold; font-size: 20px"
                      >
                        <!-- "{{ inputSearch.value || "-" }}" -->
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </table>
              <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>

            <!-- ##################################### COMPANY ################################################# -->

            <div
            *ngIf="userRole === 'Company'"
            class="mat-elevation-z8"
          >
            <div class="courses">
              <table mat-table [dataSource]="dataSource">
                <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="text-left">
                  Id
                </th>
                <td mat-cell *matCellDef="let item" class="text-left">
                  {{ item.id }}
                </td>
              </ng-container>
               
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef class="text-left">
                    {{ "purchases_page.title" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="text-left">
                    {{ element.title }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="start_date">
                  <th mat-header-cell *matHeaderCellDef>Start Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.start_date }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="end_date">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.end_date }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="place">
                  <th mat-header-cell *matHeaderCellDef>Place</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.place }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="job">
                  <th mat-header-cell *matHeaderCellDef>Job</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.job }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "purchases_page.amount" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.amount }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef>Duration</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.duration }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element">
                    <img
                      class="ptr"
                      src="../../../assets/images/action-icon.png"
                      *ngIf="common_.checkPermission([['courses', 'view']])"
                      [routerLink]="['course-detail/', element.id]"
                    />
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="searchedDataColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: searchedDataColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <ng-container>
                  <tr class="mat-row" *matNoDataRow>
                    <td
                      class="mat-cell"
                      colspan="7"
                      style="color: blue; font-weight: bold; font-size: 20px"
                    >
                      No data matching the filter
                      <span
                        style="color: red; font-weight: bold; font-size: 20px"
                      >
                        <!-- "{{ inputSearch.value }}" -->
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </table>
              <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>

            <!-- ####################################### EMPLOYEE ################################################# -->

            <div *ngIf="userRole === 'Employee'" class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef class="text-left">
                    {{ "purchases_page.title" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="text-left">
                    {{ element.title }}
                  </td>
                </ng-container>

                <!-- Sirname Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "purchases_page.date" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.date }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="place">
                  <th mat-header-cell *matHeaderCellDef>Place</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.place }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element">
                    <img
                      class="ptr"
                      src="../../../assets/images/action-icon.png"
                      *ngIf="common_.checkPermission([['courses', 'view']])"
                      [routerLink]="['course-detail/', element.id]"
                    />
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="employeeCourseColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: employeeCourseColumns"
                ></tr>
              </table>
              <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


