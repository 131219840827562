import { AfterViewInit, Component,ElementRef,OnInit,ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { Chart, registerables } from 'chart.js';
import { ApiService } from 'src/app/core/service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { CalendarOptions } from '@fullcalendar/angular'; 
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
})
export class SummaryComponent implements OnInit {

  constructor(private api: ApiService, private auth: AuthService,public translate: TranslateService) {}
 
  canvas: any;
  ctx: any;
  canvas_pie: any;
  ctx_pie: any; 

 @ViewChild('mychart') mychart: any;
 @ViewChild('chart') chart: any;

  courses_purchased=[]
  upcoming_course_data = [];
  latest_course_data = [];
  graph_data = [];
  dataLoaded=false
  upcoming_dataSource = [];
  latest_dataSource = [];
  last_added_learners=[];
  concluded_dataSource=[];
  latest_concluded=[];
  userRole: string = '';
  xlabel = [];
  ylabel = [];
  graph_month = [];
  graph_sold = [];
  graph_budget = [];
  raw_data=[]
  chartTitle: string = '';
  total = '';
  chart1:any;
  graph_week=[];
  graph_week_label=[];
  graph_today_label=[];
  graph_today=[];
  graph_year=[];
  graph_year_label=[];


 toggleGraph(type):void{
  if(type==='all'){
    this.chart1.config.data.labels=this.graph_year_label;
    this.chart1.config.data.datasets[0].label="Enrollments Per Year";
    this.chart1.config.data.datasets[0].data=this.graph_year;
  }else if(type==="year"){
    this.chart1.config.data.labels=this.graph_month;
    this.chart1.config.data.datasets[0].label="Enrollments Per Month";
    this.chart1.config.data.datasets[0].data=this.graph_sold;
  }else if(type==="week"){
    this.chart1.config.data.datasets[0].data=this.graph_week;
    this.chart1.config.data.datasets[0].label="Enrollments Per Day";
    this.chart1.config.data.labels=this.graph_week_label;

  }else if(type=="today"){
    this.chart1.config.data.datasets[0].data=this.graph_today;
    this.chart1.config.data.datasets[0].label="Enrollments Per Hour";
    this.chart1.config.data.labels=this.graph_today_label;
  }

    this.chart1.update()
 }
  isJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }
 

  ngOnInit() {

    this.userRole = this.auth.getUserRole();
    this.xlabel = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'];
    this.ylabel = [8, 12, 14, 19, 23, 18];
    this.chartTitle =
    this.userRole === 'Training Center'
        ? 'LATEST COURSES CONCLUDED'
        : 'COMPANY LEARNERS';
    this.total = '1300';

    let upcoming_arr = [];
    let concluded_arr=[];
    let latest_arr = [];
    let month = [];
    let soldcourses = [];
    let soldbudget=[];

    this.api.GET('user/dashboard').then((res) => {
   
      if (res['message'] === 'retrieved successfully') {
        //------latest courses
      this.latest_course_data = res['latestCourses'];
        this.latest_course_data.forEach((item) => {
          let jsondata = JSON.parse(item.training_course.class_room_det);

          let m = {
            title: item.course.title,
            date: jsondata[0].date,
            start_time:jsondata[0].start_time,
            place: item.training_course.place,
            room: jsondata[0].room,
             number:jsondata.length
          };
          latest_arr.push(m);

        });
        
         this.latest_dataSource = latest_arr.splice(0, 3);

        // Last learners Inserted
         this.last_added_learners=res['last_added_learners'];

      
    if (this.userRole === 'Training Center') {

        //latest concluded courses
        this.latest_concluded = res['concluded'];
        this.latest_concluded.forEach((item) => {
          let jsondata = JSON.parse(item.training_course.class_room_det);

          let m = {
            title: item.course.title,
            date: jsondata[0].date,
            place: item.training_course.place,
            room: jsondata[0].room,
            number:jsondata.length
          };
          concluded_arr.push(m);
        });

        this.concluded_dataSource = concluded_arr.splice(0, 3);

    }


if (this.userRole === 'Company') {

  //  Get the courses purchased calender
      let course_purchase=res['data'];
         
         let classDet
       course_purchase.forEach((item)=>{
       
        if (item['course'] !== null) {
          classDet = this.isJson(item['course']['class_room_det']);
        }
        
        var date
        var number=classDet?.length
       
          classDet?.forEach((element) => {
            date=element.date;
                });
            let n = {
              title: item?.course?.course_info?.course?.title || '-',
              date: date,
              number:number,
              place:item?.course?.place
            };
            this.courses_purchased.push(n);
             
          });
          
   
       this.dataLoaded=true



       res['all_time']?.forEach((item) => {
        let m = item.year;
        let s =
          this.userRole === 'Training Center'
            ? item.totalCourseSold
            : item.totalCoursePurchased;
        this.graph_year_label.push(m);
        this.graph_year.push(s);
        
      });
        res['graphDataweek']?.forEach((item) => {
        let m = item.day;
        let s =
          this.userRole === 'Training Center'
            ? item.totalCourseSold
            : item.totalCoursePurchased;
        this.graph_week_label.push(m);
        this.graph_week.push(s);
        
      });
        res['graphDataHours']?.forEach((item) => {
        let m = item.hour;
        let s =
          this.userRole === 'Training Center'
            ? item.totalCourseSold
            : item.totalCoursePurchased;
        this.graph_today_label.push(m);
        this.graph_today.push(s);
        
      
      });


      // Chart
      this.canvas_pie = this.chart.nativeElement;
      this.ctx_pie = this.canvas_pie.getContext('2d');

   new Chart(this.ctx_pie, {

        type: 'pie',
        data: {
          labels: this.graph_month,
          datasets: [
            {
              label:
              'Courses Sold per month',
                
              data: this.graph_budget,
              backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 122, 122)',
              'rgb(54, 126, 215)',
              'rgb(65, 162, 235)',
              'rgb(54, 65, 235)',
              'rgb(54, 162, 235)',
              'rgb(54, 162, 65)',
              'rgb(54, 162, 45)',
              'rgb(54, 42, 235)',
              'rgb(42, 162, 235)',
              'rgb(54, 23, 235)',
              'rgb(255, 25, 86)'
            ],
              borderRadius: 10,
            },
          ],
        },
      
      });

     }

        this.upcoming_course_data = res['classdata'];
        this.upcoming_course_data.forEach((item) => {
          let jsondata = this.isJson(item['class-det']);
          jsondata.forEach((element) => {
            let m = {
              title: item['course-det'].title,
              date: element.date,
              place: item['course-det'].place,
              room: element.room,
            };
            upcoming_arr.push(m);
          });
        });

        this.upcoming_dataSource = upcoming_arr.splice(0, 3);
    
      //  Graph data
        this.graph_data = res['graphData'];
        this.graph_data.forEach((item) => {
          let m = item.month;
          let s =
            this.userRole === 'Training Center'
              ? item.totalCourseSold
              : item.totalCoursePurchased;
          month.push(m);
          soldcourses.push(s);
          soldbudget.push(item.amount)
        });

        this.graph_month = month;
        this.graph_sold = soldcourses;
        this.graph_budget=soldbudget;
  
      
      //  Either the Training Center or Company
      if (this.userRole === 'Training Center'|| this.userRole === 'Company') {
       
        // Chart
         Chart.register(...registerables);
         console.log(this.mychart);
           this.canvas = this.mychart.nativeElement;
           this.ctx = this.canvas.getContext('2d');
          
           var bar_gradient = this.ctx.createLinearGradient(0, 0, 0, 600);
           bar_gradient.addColorStop(0, '#8178E8');
           bar_gradient.addColorStop(1, '#372F95');
 
         this.chart1= new Chart(this.ctx, {
             type: 'bar',
             data: {
               labels: this.graph_month,
               datasets: [
                 {
                   label:
                     this.userRole === 'Training Center'
                       ? 'Courses Sold per month'
                       : 'Enrollments per month',
                   data: this.graph_sold,
                   backgroundColor: bar_gradient,
                   borderRadius: 10,
                 },
               ],
             },
             options: {
             plugins:{ legend: { display: false }} ,
               scales: {
                 y: {
                   beginAtZero: true,
                 },
                 x: {},
               },
             },
           });
      }
    
 
      }
    });


  }

  ngAfterViewInit() {}
}
