<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <!-- change as per doc
  <h3>{{title}} Course -->
      <h3>Purchase entire course session</h3>

      <div class="row">
        <div class="col-md-12">
          <p>
            Let s briefly explain how to purchase an entire
            ...........................................Lorem ipsum dolor sit
            amet, consectetur adipisicing elit. Reprehenderit suscipit veritatis
            magnam minima expedita quam aperiam earum aut, laboriosam facere
            beatae aliquam consequuntur quas possimus esse libero. Animi nobis
            dignissimos quasi corporis esse rerum sunt magni reiciendis hic
            soluta est ea neque ipsa deserunt, blanditiis, veritatis iusto
            maxime ducimus tempore? Dicta necessitatibus iusto vero asperiores.
            Accusantium optio laudantium ad beatae quibusdam quo? Laboriosam
            magni aut libero ex eaque deleniti excepturi atque id dignissimos.
            Corrupti voluptas eos iure eius ipsum labore adipisci quibusdam ex?
            Laudantium, dolorem. Inventore, odio quidem. Modi, minima tempora
            ipsam quidem dolorem deserunt, expedita, vitae magnam adipisci
            beatae laudantium laboriosam odio nihil illo tempore rerum
            asperiores quas nostrum!
          </p>
        </div>
        <div class="col-md-12">

        <form>
          <div class="input-group row">
            <label class="col-sm-3 col-form-lable"
              >Choose the course</label
            >
            <div class="select col-sm-5" style="width: 200px;">
              <select name="slct" *ngIf="courseData">
                <option disabled selected>Choose course</option>
                <option *ngFor="let course of courseData"value="{{course.id}}">{{course.title}}</option>
              </select>
            </div>
          </div>
          <div class="row mt-5 ml-1">
            <div class="col-md-12">
            <h6 style="color:#437ede">Describe your request, including the number of participants, the place, and the date you prefer :</h6>
            </div>
            <div class="col-md-12 mt-3">
             <ckeditor [editor]="Editor" data="<p></p>">
              
              </ckeditor>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-1">
            </div>
            <div class="col-md-3">
                <button class="btn btn-primary btn-cancel" style="background-color: #fff;color: #F1B900;padding: 14px 1px;">Cancel</button>
            </div>
             <div class="col-md-1">
            </div>
            <div class="col-md-3">
                <button class="btn btn-primary" style="padding: 14px 1px;">Proceed</button>
            </div>
            <div class="col-md-3">
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!---col-md-3---->
