import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loadingMap = new Map<string, boolean>();
  constructor() { }

  setLoading(loadStatus: boolean, url: string): void {
    if (!url) {
      throw new Error('Provide URL to loading Service. setLoading()');
    }

    if (loadStatus === true) {
      this.loadingMap.set(url, loadStatus);
      this.loadingSubject.next(true);
    } else if (loadStatus === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }

    if (this.loadingMap.size === 0) {
      this.loadingSubject.next(false);
    }
  }
}
