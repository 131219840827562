import { ApiService } from 'src/app/core/service/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from './../../../core/excel.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-company-learners',
  templateUrl: './company-learners.component.html',
  styleUrls: ['./company-learners.component.css'],
})
export class CompanyLearnersComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private api: ApiService,
    private excelService:ExcelService,
     private router:Router,
     private _toastr: ToastrService,
       private auth: AuthService,
     public translate: TranslateService) {}
  raw_data: Array<any> = [
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Employee','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Administrative','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Framework','total_certificates':'7','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Employee','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Administrative','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Framework','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Framework','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''}
];
  dataSource: MatTableDataSource<any[]>

  searchLearner(courseTitle){
    let filterValue:string=courseTitle.value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  file:File;
  handleFileInput(evt: any){

    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {throw new Error('Cannot use multiple files');}
    // console.log(target);
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
console.log("Load successfull");
      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

    
     console.log(e.target.result);
    // this.file= event.target.files[0];
    // const bstr: string = event.target.result;
    // const data = <any[]>this.excelService.importFromFile(bstr);
  }
}
  importFromXLSX(){
    console.log("import button clicked");
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.dataSource.filteredData, 'company-employee-list');
  } 

  deleteItem(item: any) {

    const index = this.dataSource.data.indexOf(item);
    if (index >= 0) {
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
    

    this.api.DELETE('company/learner/delete',item.id).then((res) => {
      if (res['message'] === 'deleted successfully') {

        this._toastr.success("Deleted successfully");

      }else{

        this._toastr.error("Failed to delete");
        
      }


    })
  }

  }
  
 personal_data: any = {
    Name: this.auth.getUserName(),
    Email: this.auth.getUserEmail(),
    Total_cert: 0,
  };

  ngOnInit(): void {
    // /let arr=[]
    this.api.GET('company/get-employee-list').then((res) => {
      
     this.raw_data = res['data'][0]?.['employees'];
     console.log(res['data']);
      let arr = [];

      this.raw_data.forEach((element)=>{
        let n= {
          id : element.id,
          first_name:element.first_name,
          last_name:element.last_name,
          email:element.email,
          job:'Employee',
          contact_1:element.contact_1,
          contact_2:element.contact_2,
          total_certificates: '0',
          last_certificate: '11/10/2019',
          next_deadline: '19/03/2020',
          total_enrollments:element.total_enrollments,
          action:'',
        }
        arr.push(n)
      })

   
      this.raw_data = arr;
      this.dataSource = new MatTableDataSource(this.raw_data);
      this.dataSource.paginator = this.paginator;

    });
  }

  displayedColumns: string[] = [
    'first_name',
    'last_name',
    'email',
    'job',
    'total_certificates',
    'last_certificate',
    'next_deadline',
    'action'   
  ];
}
