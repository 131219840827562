import { CommonService } from './../../../../core/service/common.service';
import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiService } from 'src/app/core/service/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NewJobDialogComponent } from 'src/app/shared/new-job-dialog/new-job-dialog.component';


@Component({
  selector: 'app-propose-new-course',
  templateUrl: './propose-new-course.component.html',
  styleUrls: ['./propose-new-course.component.css'],
})
export class ProposeNewCourseComponent implements OnInit {
  course_propose: any = {
    title: '',
    description: '',
    job: '', 
    role: '', 
    classroom: '',
    risklevel: '', 
    category: '', 
    course_type :''
  };

  constructor(
    private _common: CommonService,
    private _api: ApiService,
    private _toast: ToastrService,
    private _router: Router,
    public translate: TranslateService,
     public dialog: MatDialog
  ) {}
    raw_data = []
    roleData =[]
    jobs = []
    risklevels = []
    classRoom = []
    categorylist = []
    
  public Editor = ClassicEditor;

  ngOnInit(): void {

    this._api.GET('tasks').then((res) => {
   
      if (res['message'] == 'Tasks retrieved successfully') {
         this.jobs = res['data'];
         console.log('%c Task LIST', 'background-color:pink;', this.jobs );
      }
    });


    this._api.GET('category-list').then((res) => {
   
       if (res['message'] == 'retrieved successfully') {
          this.categorylist = res['data'];
          console.log('%c Category List', 'background-color:pink;', this.categorylist );
       }
     });

    this._api.GET('risklevels').then((res) => {
      
       if (res['message'] == 'Levels retrieved successfully') {
          this.risklevels = res['data'];
          console.log('%c Job List', 'background-color:pink;', this.jobs );
       }
     });


   this._api.GET('role-list').then((res) => {
        
        if (res['message'] === 'retrieved successfully') {
        
          this.raw_data = res['data'];
          console.log('%c Roles LisT', 'background-color: yellow', res['data']);
          
          this.roleData = res['data']
        }
      });
      this._api.GET('classrooms').then((res) => {
        
        if (res['message'] === 'retrieved successfully') {
          let arr = [];
          this.raw_data = res['data'];
          console.log('%c Roles LisT', 'background-color: yellow', res['data']);
          
          this.classRoom = res['data']
        }
      });
  }
lauchJbForm(){
    this.dialog.open(NewJobDialogComponent, {
      width: '40vw',
      minHeight: '30vh',
      data: {
        course_name: "",
        course_id: "",
        tc_id: 4,
      },
    });
  }

ProposeCourse() {
    if (
      this._common.checkObject(this.course_propose, [
        'title',
        'description',
        'job',
        'role',
        'classroom',
        'risklevel'
      ])
    ) {
      this._api
        .POST('training/course-propose', this.course_propose)
        .then((res) => {
          if (res['body']?.['status'] == 'success') {
            this._toast.success('Course Proposal Send', 'Success');
            // this._router.navigate(['/course']);
          }
        });
    } else {
      this._toast.error('All fields are required', 'Error');
    }
  }
}
