import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './../../../core/service/api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './../../../core/service/common.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.css']
})
export class UpdateFormComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private api: ApiService,
    private auth: AuthService,
    private _common: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
    ) { }

  update_data= this.formBuilder.group({
    first_name: '',
    last_name: '',
    company: '',
    contact_1: '',
    contact_2: '',
    email: '',
    role: '',
  });


 updateDetails(){
    this.api.POST('updateprofile',
    {
      first_name: this.update_data.value.first_name,
      last_name: this.update_data.value.last_name,
      company_id: this.update_data.value.company,
      contact_1: this.update_data.value.contact_1,
      contact_2: this.update_data.value.contact_2, 
      email: this.update_data.value.email,
      role: this.update_data.value.role,
    }
  ).then(res=>{
    console.log(res)
    this.toastr.success('click here to close it', 'Profile data updated Successfully')
  })
  .catch(err=>{
    this.toastr.error('click here to close it', 'Something is wrong');
  })
  }
   profiledata =[];
   jobdata =[];
  ngOnInit(): void {
    this.api.GET('user/getProfile').then((res) => {
    
        this.profiledata = res['Employee-details'];
        console.log('%c USER PROFILE', 'background-color:silver', this.profiledata);

    });

    this.api.GET('companies').then((res) => {

      if (res['message'] === 'Companies retrieved successfully') {
        this.jobdata = res['company'];
      }

    });
  }
  // getProfile
} 
