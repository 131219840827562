<app-header></app-header>
<!-- 
<label for="">{{'welcome_page.choose_language'|translate}}</label>
<select #langSelect (change)="translate.use(langSelect.value)">
  <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
</select> -->
<app-welcomebanner></app-welcomebanner>
<app-welcomecourses></app-welcomecourses>
<app-welcomecategories></app-welcomecategories>
<app-welcomefindcourses></app-welcomefindcourses>
<app-footer></app-footer>

