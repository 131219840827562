import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';

export interface myCourse_struct {
  title: string;
  starts: string;
  finished: string;
  place: string;
  status: string;
  action: number;
}

@Component({
  selector: 'app-mycourse',
  templateUrl: './mycourse.component.html',
  styleUrls: ['./mycourse.component.css'],
})
export class MycourseComponent implements OnInit {
  
  mycourse: myCourse_struct[] = [
 

  ];

  constructor(private api_: ApiService, public common_: CommonService) {}
  tabledata= [];
  ngOnInit(): void {
    this.api_.GET('training/enrollment').then((res) => {
     let data = [];
      console.log('%cMY CERTIFICATES', 'background-color:pink;', res);
      if (res['status'] == 'success') {
        this.tabledata= res['data'];
      this.tabledata.forEach((element) => {
          let arr= {
            title : element.title,
            starts : element.start_date,
            finished : element.end_date,
            place : element.place,
            status : "Enrolled",
            action : 1,
           };
           data.push(arr);
        });
      let total ={
          title: '',
          starts: 'Total',
          finished: '',
          place: data.length+' courses',
          status: '',
          action : 0,
      };
      data.push(total);
      this.mycourse = data;
      }
    });
  }
  displayedColumns: string[] = [
    'title',
    'starts',
    'finished',
    'place',
    'status',
    'action',
  ];
  searchLearner(courseTitle){
    //
  }
}
