<div class="container-fluid">
    <h3>Import Learners</h3>
    <form (submit)="onSubmit()">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Choose file</label>
          <input type="file" (change)="onFileSelected($event)"  #file>
        </div>
      </div>
     
      <!-- <button type="submit" class="btn btn-primary mx-1"
      (click)="file.click()"
      >
        {{'company_learner.import_xls'|translate}}        <img src="../../../assets/images/attach.png" /> 
      </button> -->
    </div>
 


  
    <div class="row" style="width: 608px; margin-left: 115px; margin-top: 30px">
      <button
        type="submit"
        routerLinkActive="active"
        class="btn btn-warning"
        style="margin-left:14px;color: white;"
      >
      Upload Data
      </button>
    </div>
  
</form>


<div class="row" style="width: 608px; margin-left: 115px; margin-top: 30px">
    <button
      (click)="downloadFile()"
      class="btn btn-warning"
      style="margin-left:14px;color: white;"
    >
   Download Template
    </button>
  </div>
  </div>
  