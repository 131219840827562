<div class="registerd-form">
  <div class="container">
    <div class="row">
      <h3 class="h3"><span style="padding-left: 8px;">{{ courseData["title"] | translate }}</span></h3>
      
    </div>
    <div class="row mt-3">
      <div class="col-md-6 col-sm-6">
        <p>{{'purchases_page.title'|translate}}<span style="padding-left: 50px;">{{ courseData["title"] | translate }}</span></p>
      </div>
      <div class="col-md-6 col-sm-6">
        <p>{{'purchases_page.teacher'|translate}}<span style="padding-left: 37px;">Gianni Gialli</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>{{'purchases_page.date'|translate}}<span style="padding-left: 45px;">{{ courseData["end_date"] | translate }}</span></p>
      </div>
      <div class="col-md-6">
        <p>{{'purchases_page.center'|translate}}<span style="padding-left: 37px;">Tanit-Geona</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>{{'purchases_page.validity'|translate}}<span style="padding-left: 27px;">10/06/2021</span></p>
      </div>
      <div class="col-md-6">
        <p>{{'purchases_page.amount'|translate}}<span style="padding-left: 37px;">{{ courseData["amount"] | translate }}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>{{'purchases_page.credits'|translate}}<span style="padding-left: 28px;">15</span></p>
      </div>
     
    </div>
    <div class="col-md-12 my-2">
      <div class="table-div">
        <h3 class="h3">{{'purchases_page.participants'|translate}}</h3>
       
        <table mat-table  [dataSource]="myData" class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'purchases_page.name'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="job">
            <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'update_form.job'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.job }}</td>
          </ng-container>

          <ng-container matColumnDef="frequency">
            <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'purchases_page.frequency'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.frequency }}</td>
          </ng-container>

          <ng-container matColumnDef="certificate">
            <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'purchases_page.certificate'|translate}} <img style="width: 20px;" src="../../../assets/images/pdf-icon.png" /></th>
            <td mat-cell *matCellDef="let element">
              <img src="../../../assets/images/action-icon.png" />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table >
      </div>
      <div style="width: 121px; margin-left: auto; padding-top: 13px;">
        <a routerLinkActive="active" class="btn btn-primary">
        {{'purchases_page.download_all'|translate}}</a>
      </div>
    </div>
  </div>
</div>
