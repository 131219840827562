import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-welcomecourses',
  templateUrl: './welcomecourses.component.html',
  styleUrls: ['./welcomecourses.component.css',
              '../../../../styles/welcome-slides.css']
})
export class WelcomecoursesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
    customOptions: OwlOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
      nav: true
    }

}
