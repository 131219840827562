import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { SigninDialogComponent } from 'src/app/home/welcome/signin-dialog/signin-dialog.component';
import { AuthService } from 'src/app/core/service/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  constructor(public dialog: MatDialog, private auth: AuthService, private router: Router, private toastr: ToastrService,
    public translate: TranslateService) { }

  userid = this.auth.username
  userDetails: any = { name: "" };
  userRole: string = ""
  userName: string = ""

  signin() {
    let dialogRef = this.dialog.open(SigninDialogComponent, {
      width: '610px',
      panelClass: 'foo'
    });
    return dialogRef;
  }

  logout() {
    this.auth.logout().subscribe(val => {
      this.router.navigate(['/welcome'])
      this.toastr.success('click here to close it', 'Successfully Logged out');
    })
  }

  loginStatus$: Observable<boolean>

  ngOnInit(): void {
    this.loginStatus$ = this.auth.isLoggedin
    this.auth.getUserInfo() ? this.userDetails = this.auth.getUserInfo() : "";
    this.auth.getUserRole() ? this.userRole = this.auth.getUserRole() : "";
    this.userName =this.auth.getUserName();
  }

  hidden = false;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }
}
