<div class="registerd-form">
  <div class="container">
    <div class="row">
      <h3 class="h3"><span style="padding-left: 8px;">You are buying: {{ courseData["title"] | translate }}</span></h3>
      
    </div>
    <div class="row mt-3">
      <div class="col-md-6 col-sm-6">
        <p>{{'purchases_page.title'|translate}}<span style="padding-left: 50px;">{{ courseData["title"] | translate }}</span></p>
      </div>
      <div class="col-md-6 col-sm-6">
        <p>{{'purchases_page.teacher'|translate}}<span style="padding-left: 37px;">Gianni Gialli</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>{{'purchases_page.date'|translate}}<span style="padding-left: 45px;">{{ coursePublish["start_date"] | translate }}</span></p>
      </div>
      <div class="col-md-6">
        <p>{{'purchases_page.center'|translate}}<span style="padding-left: 37px;">{{ trCenter['company_name'] }}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>{{'purchases_page.validity'|translate}}<span style="padding-left: 27px;">{{ coursePublish["end_date"] | translate }}</span></p>
      </div>
      <div class="col-md-6">
        <p>{{'purchases_page.amount'|translate}}<span style="padding-left: 37px;">{{ coursePublish["cost"] | translate }}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>{{'purchases_page.credits'|translate}}<span style="padding-left: 28px;">15</span></p>
      </div>
     
    </div>
    <div class="col-md-12 pl-5 pt-4 card shadow rounded-top rounded-end rounded-start rounded-bottom bg-light my-2 rounded">

      <div class="row">
          <div class="col-md-6">
            <h5>Course times:</h5>
            <h6>Theory</h6>
            <ul>
              <li>Mon 12 March, 9 a.m. to 1. pm</li>
              <li>Mon 14 March, 9 a.m. to 1. pm</li>
            </ul>
            <h5>Practice</h5>
            <ul>
              <li>Thu March 16 9 a.m to 12</li>
            </ul>
          </div>
           <div class="col-md-6">
          <h6>Equipment available </h6>
            <ul>
              <li>LIM</li>
              <li>Computer</li>
              <li>Practical Materials for the tutorial</li>
            </ul>
          </div>
          <div class="col-md-12">
              <h5>Notes:</h5>
              <p>The theory will take place in Via XX September 18, at the headquarters of ABC Courses.<br>
                The practical part will take place in the xxx
              </p>
          </div>
      </div>
    
    </div>
    <div class="col-md-12">
      <div class="table-div">
        <div class="mat-elevation-z8">
          <!-- <table mat-table [dataSource]="dataSource" > temprory change -->
          <table mat-table [dataSource]="dataSource" class="scroller">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="first_name">
              <th mat-header-cell *matHeaderCellDef>{{'register_form.first_name'|translate}}</th>
              <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
            </ng-container>
  
            <!-- Surname Column -->
            <ng-container matColumnDef="last_name">
              <th mat-header-cell *matHeaderCellDef>{{'company_learner.surname'|translate}}</th>
              <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
            </ng-container>
  
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>{{'master_data.email'|translate}}</th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

     
            <tr mat-header-row *matHeaderRowDef="displayedColumnsLearners"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLearners"></tr>
          </table>
          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-3">
          <button
          type="submit"
           (click)="cancelBuy()"
          class="btn btn-primary m-2"
        >
          {{ "Cancel" | translate }}
        </button>
        <button
          type="submit"
           (click)="buyCourse()"
          class="btn btn-primary m-2"
        >
          {{ "Proceed" | translate }}
        </button>
    </div>

  </div>
</div>
