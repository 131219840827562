import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service'
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog';
import { BuyConfDialogComponent } from 'src/app/shared/buy-conf-dialog/buy-conf-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxChange } from '@angular/material/checkbox';



export interface PeriodicElement {
  job: string;
  name: string;
  frequency: string;
  certificate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name:'Mario Rossi' , job: 'Administration', frequency: 'Yes', certificate: ''},
  {name: 'Luke Yellows', job: 'Technical', frequency: 'Yes', certificate: ''},
  {name: 'Antonio Verdi', job: 'Employee', frequency: 'No', certificate: ''},
  {name: 'Fabio Blue', job: 'Framework', frequency: 'Yes', certificate: ''},
  
];

@Component({
  selector: 'app-buy-course-detail',
  templateUrl: './buy-course-detail.component.html',
  styleUrls: ['./buy-course-detail.component.css']
})
export class BuyCourseDetailComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  id: any;
  learners = [];
  myData =[];
  courseData = [];
  trCenter = [];
  coursePublish = [];

  data = [];
  selectedItems: any[] = [];

 
  raw_data: Array<any> = [
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Employee','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Administrative','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Framework','total_certificates':'7','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Employee','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Administrative','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'John','last_name':'Yellow','email':'aaa@gmail.com','job':'Framework','total_certificates':'4','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''},
    {'first_name':'Luke','last_name':'Green','email':'aaa@gmail.com','job':'Framework','total_certificates':'2','last_certificate':'11/10/2019','next_deadline':'19/03/2020','action':''}
];
  dataSource: MatTableDataSource<any[]>
  displayedColumnsLearners: string[] = [
    'select',
    'first_name',
    'last_name',
    'email',
  ];

  constructor(
     public dialog: MatDialog,
    private router:Router,
    private api: ApiService,  
    private _toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    public common_: CommonService,
    public translate: TranslateService
    ) { }


  cancelBuy(){
    this.router.navigate(['/course']);
  }
  
  buyCourse() {

    this.dialog.open(BuyConfDialogComponent, {
      width: '60vw',
      minHeight: '10vh',
      data: {
        course_name: this.courseData['title'],
        course_id: this.id,
        tc_id: 4,
      },
    });
  }

  ngOnInit(): void {
        this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));

      this.api.GET("courseinfo/"+this.id).then((res)=>{
        console.log("my course info response",res['data']['course'])
      
      if (res['message'] === 'retrieved successfully') {
         
            this.learners = res['learners'];
            this.courseData = res['data']['course'];
            this.trCenter = res['data']['training_center'];
            this.coursePublish = res['data']['training_course'];
  
      }
    });

    this.api.GET('company/get-employee-list').then((res) => {
      
      this.raw_data = res['data'][0]?.['employees'];
      console.log(res['data']);
       let arr = [];
 
       this.raw_data.forEach((element)=>{
         let n= {
           id : element.id,
           first_name:element.first_name,
           last_name:element.last_name,
           email:element.email,
           job:'Employee',
           contact_1:element.contact_1,
           contact_2:element.contact_2,
           total_certificates: '0',
           last_certificate: '11/10/2019',
           next_deadline: '19/03/2020',
           total_enrollments:element.total_enrollments,
           action:'',
         }
         arr.push(n)
       })
 
    
       this.raw_data = arr;
       this.dataSource = new MatTableDataSource(this.raw_data);
       this.dataSource.paginator = this.paginator;
 
     });
  }

  deleteItem(item: any) {

    const index = this.dataSource.data.indexOf(item);
    if (index >= 0) {
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
    

    this.api.DELETE('company/learner/delete',item.id).then((res) => {
      if (res['message'] === 'deleted successfully') {

        this._toastr.success("Deleted successfully");

      }else{

        this._toastr.error("Failed to delete");
        
      }


    })
  }

  }

  selectAll(event: MatCheckboxChange): void {
    this.dataSource.data.forEach((row: any) => {
      row.selected = event.checked;
      this.updateSelectedItems(row);
    });
  }

  updateSelectedItems(row: any): void {
    if (row.selected) {
      this.selectedItems.push(row);
    } else {
      const index = this.selectedItems.findIndex(item => item === row);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
  }
  

  displayedColumns: string[] = ['name', 'job', 'frequency', 'certificate'];

}
