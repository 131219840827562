<div class="registerd-form">
  <div class="container">

<div class="side-nav">

<form  [formGroup]="update_data" (ngSubmit)="updateDetails()" novalidate>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="first">{{'update_form.name'|translate}}</label>
          <input type="text" 
          class="form-control" value="{{profiledata['first_name']}}"
          formControlName="first_name"
            placeholder="Alex" id="first">
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="last"></label>
          <input type="text" class="form-control" value="{{profiledata['last_name']}}"  formControlName="last_name" placeholder="{{'register_form.last_name'|translate}}" id="last">
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-6">
        <div class="form-group">
          <label for="url">{{'register_form.phone_number'|translate}}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" type="button">+11</button>
            </div>
            <input type="tel" id="phone" value="{{profiledata['contact_1']}}"  formControlName="contact_1" class="form-control" placeholder="748080797" aria-label="" aria-describedby="basic-addon1">
          </div>
        </div>
        </div>


    <div class="col-md-6">
      <div class="form-group">
      <label for="url">{{'register_form.alternative_number'|translate}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button">+11</button>
        </div>
        <input type="url" id="url" class="form-control" value="{{profiledata['contact_2']}}"  formControlName="contact_2" placeholder="748080797" aria-label="" aria-describedby="basic-addon1">
      </div>
    </div>
    </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="email">{{'register_form.email_address'|translate}}</label>
          <input type="email" class="form-control" value="{{profiledata['email']}}"  formControlName="email" id="email" placeholder="alex@gmail.com">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="password">{{'update_form.password'|translate}}</label>
          <input type="password"  class="form-control" id="password" placeholder="**********">
        </div>
      </div>
      <!--  col-md-6   -->

      <!--  col-md-6   -->
    </div>
    <!--  row   -->

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="company">{{'choose_role.company'|translate}}</label>
          <div class="select">
            <select name="slct"  formControlName="company" id="slct"  >
                <option selected disabled>{{'update_form.options'|translate}}</option>
                <option *ngFor="let job of jobdata" value="{{job.id}}">{{job.name|translate}}</option>
              </select>
          </div>
        </div>
      </div>
      <!--  col-md-6   -->

      <div class="col-md-6">
          <div class="form-group">
            <label for="company">{{'role'|translate}}</label>
            
                <input type="text" value="{{profiledata['role']}}"  formControlName="role" class="form-control" placeholder="Lap worker" id="company">
          </div>
        </div>

    </div>
    <!--  row   -->
    <button type="submit"  class="btn btn-primary">{{'update_form.save_changes'|translate}}</button>
  </form>
</div>


</div><!---col-md-3---->



    </div>








