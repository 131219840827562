
<div class="next-course">
  <div class="container">
      <h2>{{'welcome_page.find_courses_for_you'|translate}}</h2>
      <div class="">
          <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                  <img src="../../../../assets/images/enter.png" class="find-cat">
                  <p>{{'welcome_page.entrepreneur'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/enter.png" class="find-cat">
                  <p>{{'welcome_page.entrepreneur'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/enter.png" class="find-cat">
                  <p>{{'welcome_page.entrepreneur'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

              <ng-template carouselSlide>
                  <img src="../../../../assets/images/enter.png" class="find-cat">
                  <p>{{'welcome_page.entrepreneur'|translate}}</p>
                  <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5>
              </ng-template>

            </owl-carousel-o>
      </div>
  </div>

</div>



