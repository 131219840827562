import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { NewJobDialogComponent } from 'src/app/shared/new-job-dialog/new-job-dialog.component';
import {HttpClient,HttpParams,HttpHeaders} from '@angular/common/http'
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-edit-learner',
  templateUrl: './new-edit-learner.component.html',
  styleUrls: ['./new-edit-learner.component.css'],
})
export class NewEditLearnerComponent implements OnInit {
  company_user = {
    first_name: '',
    last_name: '',
    contact_1: '',
    contact_2: '',
    email: '',
    task: '',
    job:'',
    city:'',
    zip_code:'',
  };

//Removed because an employee already takes the level of risk given by the company (class_of_risk:'')
  constructor(private _api: ApiService, 
    private _toastr: ToastrService,
     public dialog: MatDialog,
     private http:HttpClient,
     private router: Router,
     ) {}
   jobdata =[];
   taskdata =[];
   citydata =[];

  ngOnInit(): void {

    //  Get all the cities

    this.http.get("https://countriesnow.space/api/v0.1/countries/population/cities").subscribe((response)=>{
          
      let d1= [];
    response['data'].forEach((element) => {
      let n = {
        name : element['city']
      }
         d1.push(n);
    });

   this.citydata = d1

  },
  (error) =>{
    console.log("Error Message");
  },
  () =>{
    console.log("Request Completed");
  }
  );


  // Get all the jobs

  this._api.GET('jobs').then((res) => {
   let arr=[]
   
      res['data'].forEach((element)=>{
        let n= {
          id : element.id,
          title: element.title,
        }
        arr.push(n)
      })

   
      this.jobdata = arr;
 
    });

  // Get all the tasks

    this._api.GET('tasks').then((res) => {
     let arr=[]

      res['data'].forEach((element)=>{
        let n= {
          id : element.id,
          title:element.title,
        }
        arr.push(n)
      })

      this.taskdata = arr;
      console.log("Task Data",this.taskdata);
 
    });


  }

  lauchJbForm(){
    this.dialog.open(NewJobDialogComponent, {
      width: '40vw',
      minHeight: '30vh',
      data: {
        course_name: "",
        course_id: "",
        tc_id: 4,
      },
    });
  }

  //  Function to create learner
  createLearner() {
    console.log(this.company_user);
    this._api.POST('company/create-employee', this.company_user).then(
      (res) => {
        console.log(res);
        if (res['body']['status'] == 'success') {
          this._toastr.success(res['body']['message']);
          this.router.navigate(['/learner']);
        }else if(res['body']['message'] == "email already exist !"){
          this._toastr.error("Email exists!");
        }
      },
      (err) => {
        this._toastr.error(err);
      }
    );
  }
}
