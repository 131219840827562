import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  id:number
  name: string;
  sirname: string;
  taxcode: string;
  action: string;
}

@Component({
  selector: 'app-teachers-management',
  templateUrl: './teachers-management.component.html',
  styleUrls: ['./teachers-management.component.css']
})
export class TeachersManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public common_: CommonService, private _api: ApiService, private dialog: MatDialog) { }
  displayedColumns: string[] = ['name', 'sirname', 'taxcode'];
  dataSource: MatTableDataSource<PeriodicElement[]>
  raw_data=[]

  searchTeacher(courseTitle){
      let filterValue:string=courseTitle.value
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnInit(): void {
    let arr=[]
    this._api.GET('training/get-teacher').then((res) => {
      console.log('%c Teacher List', 'background-color: yellow', res)
      if (res['message'] === 'retrieved successfully') {
        this.raw_data = res['data'];
        this.raw_data.forEach(element=>{
          let n= {
            id:element.id,
            name:element.teacher ? element.teacher.first_name : "-",
            sirname:element.teacher ? element.teacher.last_name : "-",
            taxcode:element.teacher ? element.teacher.tax_code : "-"
          }
          arr.push(n)
        })
        this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
        // let data = res['data'];
        // this.dataSource = data;
        // console.log(this.dataSource)
      }
    })
  }


  // RemoveElementFromObjectArray(key: number) {
  //   this.dataSource.forEach((value,index)=>{
  //       if(value.id==key)
  //       this.dataSource.splice(index,1);
  //   });
  // }

  DeleteTeacher(id: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      minHeight: "50vh",
      data: {
        title: 'Delete Teacher',
        message: 'Are you sure you want to remove this teacher?',
        confirm_btn: 'Delete',
        cancel_btn: 'Cancel'
      }
    }).afterClosed().subscribe((res) => {
      if(res){

      }
    })

  }

}
