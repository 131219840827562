<div class="registerd-form">
  <div class="container">

<div class="side-nav">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-6">
      <h3>{{'master_data.master_data'|translate}}</h3>
    </div>
    <div class="col-6 col-sm-6 col-md-6">
      <a routerLink="/update-form" routerLinkActive="active" class="btn btn-primary">{{'master_data.update_your_form'|translate}}</a>
    </div>
</div>
<form>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="first">{{'choose_role.company'|translate}}</label>
          <input type="text" class="form-control" placeholder="Mario Rossi SRL" id="first">
        </div>
      </div>
      <!--  col-md-6   -->

      <div class="col-md-6">
        <div class="form-group">
          <label for="url">{{'register_form.phone_number'|translate}}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" type="button">11</button>
            </div>
            <input type="tel" id="phone" class="form-control" placeholder="{{'register_form.phone'|translate}}" aria-label="" aria-describedby="basic-addon1">
          </div>
        </div>
      </div>
      <!--  col-md-6   -->
    </div>

    <div class="row">

      <div class="col-md-6">

        <div class="form-group">
          <label for="phone">{{'master_data.tax_code'|translate}}</label>
          <input type="tel" class="form-control" id="tax" placeholder="01234567899">
        </div>
      </div>
      <!--  col-md-6   -->

    <div class="col-md-6">
      <div class="form-group">
        <label for="url">{{'master_data.cell'|translate}}</label>
        <input type="url" class="form-control" id="cell" placeholder="111 222 333">
      </div>

    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="email">{{'master_data.vat_new'|translate}}</label>
        <input type="email" class="form-control" id="vat" placeholder="01234567899">
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="email">{{'master_data.vat_new'|translate}}</label>
        <input type="email" class="form-control" id="add" placeholder="Way of the">
      </div>
    </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="email">{{'master_data.email'|translate}}</label>
          <input type="email" class="form-control" id="email" placeholder="Info@mariorossi.com">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="email">{{'register_form.city'|translate}}</label>
          <input type="email" class="form-control" id="email" placeholder="Genoa">
        </div>
      </div>

      <div class="col-md-6">
          <div class="form-group">
            <label for="email">SDI</label>
            <input type="email" class="form-control" id="email" placeholder="ABC123">
          </div>
        </div>

        <div class="col-md-5">
          <div class="form-group">
            <label for="company">{{'master_data.province'|translate}}</label>
            <!-- <input type="text"  class="form-control" id="email" placeholder="ABC123"> -->
            <div class="select">
              <select name="slct" id="slct"  >
                  <option selected disabled>Select Province</option>
                  <option *ngFor="let province of provinces" value="{{ province.name }}">{{ province.name }}</option>
                </select>
            </div>

          </div>
        </div>

        <div class="col-md-1 active">
          <div class="form-group">
          <label for="image1">.</label>
          <a id="image1" class="btn btn-primary">add</a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="email">Pec</label>
            <input type="email" class="form-control" id="email" placeholder="info@pec.mariorossi.com">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="email">{{'master_data.zip'|translate}}</label>
            <input type="email" class="form-control" id="email" placeholder="16100">
          </div>
        </div>
      <!--  col-md-6   -->

    </div>

    <button type="submit" class="btn btn-primary">{{'update_form.save_changes'|translate}}</button>
  </form>
</div>


</div><!---col-md-3---->



    </div>








