import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() role:string=""

  showRole:boolean=true

  gotoChooseRole(){
    this.showRole=!this.showRole
    this.router.navigate(['form-register'])
  }

  OnRegister(){
    this.router.navigate(['/summary'])
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

}
