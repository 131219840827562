import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-role',
  templateUrl: './choose-role.component.html',
  styleUrls: ['./choose-role.component.css']
})
export class ChooseRoleComponent implements OnInit {

  constructor(private router: Router) { }

  selectedRole:string=""

  registerCompany(){
    this.showForm=!this.showForm
    this.showSelector=!this.showSelector
    this.selectedRole="Company"
  }
  registerTC(){
    this.showForm=!this.showForm
    this.showSelector=!this.showSelector
    this.selectedRole="Training Center"
  }
  registerEmployee(){
    this.showForm=!this.showForm
    this.showSelector=!this.showSelector
    this.selectedRole="Employee"
  }


  showForm:boolean=false
  showSelector:boolean=true


  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

}
