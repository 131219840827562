import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { config } from './../../../core/config/config';
// export interface certificate_struct {
//   // certificate_id: number;
//   // certificate_number: string;
//   // training_center: string;
//   date: string;
//   // file: string;
//   title:string;
//   place:string;
//   action:number;
// }

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css'],
})
export class CertificateComponent implements OnInit {
  constructor(private api_: ApiService, 
    public common_: CommonService,
    private auth: AuthService,
    ) {}

  certificates = [
    // { 'title':'Low risk fire','date':'19/03/2020','place':'Genoa','action':1},
    // { 'title':'First aid','date':'26/04/2020','place':'Genoa','action':1},
    // { 'title':'','date':'Total','place':'2 courses','action':0}
  ];

  personal_data: any = {
    Name: this.auth.getUserName(),
    Email: this.auth.getUserEmail(),
    Total_cert: 0,
  };

  displayedColumns: string[] = [
    // 'certificate_id',
    // commented certificate_numb. because no present in column
    // 'certificate_number',
    // 'training_center',
    'title',
    'date',
    'place',
    'action',
  ];

  cert_data = [];

  ngOnInit(): void {
    this.api_.GET('training/certificate').then((res) => {
      // debugger
      console.log('%cMY CERTIFICATES', 'background-color:pink;', res);
      if (res['status'] == 'success') {
        let data: any = res['data'];
        data.forEach((element) => {
          let tabledata: any = {
            'title' :element.certificate_id,
             'date':element.certificate.cert_num,
             'place':element.training_center_id,
             'action':1,
             'url':element.certificate.file_path
          };
          this.cert_data.push(tabledata);

        });
        console.log();
        this.certificates=this.cert_data;
      }
    });
  }
openCertificate(element) {
   
    let img_ext = config.VIEW_URL;
    let cert = element//element['employee']?.['current_course_cert']?.['certificate'];
    // console.log(cert);
    //alert(`${img_ext}${cert}`);
   window.open(`${img_ext}${cert}`, '__blank');
  }
  getPersonalData() {}
}
