import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';



export interface TeacherData {
  first_name: string;
  last_name: string;
  tax_code: string;

}

@Component({
  selector: 'app-add-new-teacher',
  templateUrl: './add-new-teacher.component.html',
  styleUrls: ['./add-new-teacher.component.css']
})
export class AddNewTeacherComponent implements OnInit {
  teacher_data: TeacherData = {
    "first_name": "",
    "last_name": "",
    "tax_code": ""
  }

  data = ''

  constructor(private _api: ApiService, private _toast: ToastrService) { }

  ngOnInit(): void {
  }

  CreateTeacher() {
    if (this.teacher_data.first_name != '' && this.teacher_data.last_name != '' && this.teacher_data.tax_code != '') {
      this._api.POST('training/add-teacher', this.teacher_data).then((res) => {
        console.log('%c ADD TEAHCER RESPONSE', 'background-color: yellow', res)
        if (res['body']?.['status'] == 'success') {
          this._toast.success('Teacher Added Successfully', 'Success');
          this.teacher_data = { first_name: '', last_name: '', tax_code: '' };
        }
      })

    } else {
      this._toast.error('Please Fill all details', 'Error')
    }


  }

}
