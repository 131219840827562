<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <h3>{{'course_management.list_of_courses'|translate}}</h3>
      <form>
        <!-- <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">Course</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Title"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="company">Course</label>
              <div class="">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="company">Role</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="company">Room</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="company">Was</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="company">Teacher</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="company">Category</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>AAA</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="company">City</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Noida</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-6 date-d">
            <div class="form-group">
              <label for="company">City</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="company">City</label>
              <div class="select">
                <select name="slct" id="slct">
                  <option selected disabled>Title</option>
                  <option value="1">Pure CSS</option>
                  <option value="2">No JS</option>
                  <option value="3">Nice!</option>
                </select>
              </div>
            </div>
          </div>
        </div> -->

        <div class="btn-g">
          <button type="submit" class="btn btn-primary">Clear Fields</button>
          <button type="submit" class="btn btn-primary active">
            {{'welcome_page.search'|translate}} <img src="../../../assets/images/search.png" />
          </button>
          <button type="submit" class="btn btn-primary">
            Export XLS <img src="../../../assets/images/attach.png" />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
