import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/service/api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { BuyConfDialogComponent } from 'src/app/shared/buy-conf-dialog/buy-conf-dialog.component';
import { EnrollConfDialogComponent } from 'src/app/shared/enroll-conf-dialog/enroll-conf-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface PeriodicElement {
  course: any;
  id: number;
  training_center: any;
}

export interface classes {
  date: string;
  end_time: string;
  room: string;
  start_time: string;
  teacher_id: string;
}

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CourseDetailComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    public translate: TranslateService
  ) {}

  id: any;
  dataSource: PeriodicElement[] = [];
  classDetail: classes[] = [];
  magnifiedData: any;
  userRole: string;

  title: string;
  public_notes: string;
  description: string;
  date: string;
  place: string;

  buttonRole: string;
  raw_data = [];

  data = [];
  courseData = [];
  buyCourse() {
    console.log('--', {
      course_name: this.courseData['title'],
      course_id: this.id,
      tc_id: 4,
    });
    this.dialog.open(BuyConfDialogComponent, {
      width: '60vw',
      minHeight: '10vh',
      data: {
        course_name: this.courseData['title'],
        course_id: this.id,
        tc_id: 4,
      },
    });
  }

  buyCase() {
    //alert(this.magnifiedData.tc_id +" and "+this.id)

    this.dialog.open(EnrollConfDialogComponent, {
      width: '60vw',
      minHeight: '10vh',
      data: {
        course_name: this.courseData['title'],
        course_id: this.id,
        tc_id: 4,
      },
    });
    // this.api.POST('company/purchase-course',
    //   {
    //     "package_id":1,
    //     "payment_type":"cash",
    //     "training_center_id":this.magnifiedData.tc_id,
    //     "course_id":this.id
    //   }
    // ).then(res=>{
    //   console.log(res)
    //   this.toastr.success('click here to close it', 'Purchase Successful');
    // })
    // .catch(err=>{
    //   this.toastr.error('click here to close it', 'Something is wrong');
    // })
  }

  getDataById(ids: any) {
    return this.dataSource.find((x) => {
      if (x.course) {
        return x.course.id === ids;
      } else {
        return false;
      }
    });
  }

  getDataByIds(ids: any) {
    //console.log(this.dataSource)
    return this.dataSource.find((x) => {
      return x.id === ids;
    });
  }

  ngOnInit(): void {
    this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));
   

    this.userRole = this.auth.getUserRole();

    if (this.auth.getUserRole() === 'Training Center') {
      this.buttonRole = 'Registered and certified management';
      this.api.GET('course-list').then((res) => {
        console.log('%c COURSE LIST', 'background-color: yellow', res);
        if (res['message'] === 'retrieved successfully') {
          let data = res['data'];
          this.dataSource = data;
          var dataById = this.getDataById(this.id);
          this.magnifiedData = dataById;
          console.log(this.magnifiedData);
          console.log(this.magnifiedData.training_course.class_room_det);
          this.classDetail = JSON.parse(
            this.magnifiedData.training_course.class_room_det
          );
          console.log(this.classDetail);

          this.title = this.magnifiedData.course.title;
          this.public_notes = this.magnifiedData.training_course.public_notes;
          this.description = this.magnifiedData.training_course.description;
          this.place = this.magnifiedData.training_course.place;
        }
      });
    }

    if (this.auth.getUserRole() === 'Company') {

      this.buttonRole = 'Buy this Course';
      let arr = [];

      this.api.GET('general-course-list').then((res) => {
        console.log('%c COURSE LIST', 'background-color: yellow', res);
        if (res['message'] === 'retrieved successfully') {
          this.raw_data = res['result']['data'];
          this.raw_data.forEach((item) => {
            item.training_center.forEach((element) => {
              console.log(element, '---');
              let det = element.training_course
                ? element.training_course.class_room_det
                : null;
              let classDet = det
                ? det.replace(String.fromCharCode(92), '')
                : null;
              let m = {
                title: item.title,
                id: element.training_course ? element.training_course.id : '-',
                class_det: classDet ? JSON.parse(classDet) : '-',
                public_note: element.training_course
                  ? element.training_course.public_notes
                  : '-',
                description: element.training_course
                  ? element.training_course.description
                  : '-',
                place: element.training_course
                  ? element.training_course.place
                  : '-',
                tc_id: element.training_center_id,
              };
              arr.push(m);
            });
          });
          //console.log(arr)
          this.dataSource = arr;
          console.log(this.dataSource, 'datasource');
          var mac = this.getDataByIds(arr);
          this.magnifiedData = mac;
          console.log("fadfadadadafafsgdhtrwetgssf",this.magnifiedData);

          // this.title = this.magnifiedData.title;
          // this.public_notes = this.magnifiedData.public_note;
          // this.description = this.magnifiedData.description;
          // this.classDetail = this.magnifiedData.class_det;
        }
      });

    }

    if (this.auth.getUserRole() === 'Employee') {
      this.buttonRole = 's';
      let arr = [];
      this.api.GET('course-list').then((res) => {
        console.log('%c COURSE LIST', 'background-color: yellow', res);
        if (res['message'] === 'retrieved successfully') {
          // Val- training_center
          // element - course
          this.raw_data = res['data'];
          this.raw_data.forEach((item) => {
                let m = {
                  id: item.course.id,
                  tc_id: item.course.id,
                  title: item.course.title,
                  place: item.training_course ? item.training_course.place : '-',
                  date: item.training_course
                    ? item.training_course.start_date
                    : '-',
                  class_det: item.training_course
                    ? JSON.parse(item.training_course.class_room_det)
                    : '-',
                  description: item.training_course
                    ? item.training_course.description
                    : '-',
                  public_note: item.training_course
                    ? item.training_course.public_notes
                    : '-',
                };
                arr.push(m);
            
          });

          this.dataSource = arr;
          console.log(this.dataSource);
          var mac = this.getDataByIds(this.id);
          this.magnifiedData = mac;
          console.log(this.magnifiedData);

          this.title = this.magnifiedData.title;
          this.public_notes = this.magnifiedData.public_note;
          this.description = this.magnifiedData.description;
          this.classDetail = this.magnifiedData.class_det;
        }
      });


      this.api.GET("course/"+this.id).then((res)=>{
        let arr = [];
        if (res['message'] === 'Course retrieved successfully') {
              // this.raw_data=res['course'];
              this.courseData = res['course'];
        }
      });

    }
  }
}
