import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.css'],
})
export class CalenderComponent implements OnInit {
  constructor(private api: ApiService, public common_: CommonService) {}

  calendarOptions = {
    initialView: 'dayGridMonth',
    // dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [
     
    ],
  };

  isJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  dataLoaded = false;
  dataSource = [];
  classDet = [];
  magnifiedData: any;

  ngOnInit(): void {
    this.api.GET('getClassDetails').then((res) => {
      if (res['message'] === 'retrieved successfully') {
        let data = res['classdata'];
        console.log(data, 'Class Room Det');
        data.forEach((item) => {
          let classDet = this.isJson(item['class_room_det']);
          classDet.forEach((element) => {
            let n = {
              title: item?.course_info?.course?.title || '-',
              date: element.date,
            };
            this.calendarOptions.events.push(n);
          });
        });

        this.dataLoaded = true;
        console.log(this.calendarOptions.events, this.dataLoaded, 'CALENDERS');
        //this.calendarOptions.events=this.classDet
      }
    });
  }
}
