<div class="container-fluid">
  <div class="w-100 d-flex justify-content-between">
    <h3>{{'company_learner.company_learners'|translate}}</h3>
    <div>
      <a
        routerLink="new-learner"
        routerLinkActive="active"
        class="btn btn-primary"
        >{{'company_learner.new_learner'|translate}}</a
      >
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-sm-6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{'company_learner.search_learner'|translate}}er"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          #inputvalue
        />
        <div class="input-group-append" (click)="searchLearner(inputvalue)">
          <span class="input-group-text" id="basic-addon2">
            <img class="search_icon" src="../../../assets/images/search.png" />
          </span>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-6 text-right xls-btn">
      <input type="file" class="d-none" (change)="handleFileInput($event)"  #file>
      <a
      routerLink="import-learners"
      routerLinkActive="active"
      class="btn btn-primary"
      >Import Learners</a
    >
      <!-- <button type="submit" class="btn btn-primary mx-1"
      (click)="file.click()"
      >
        {{'company_learner.import_xls'|translate}}       <img src="../../../assets/images/attach.png" /> 
      </button> -->
      <button type="submit" class="btn btn-primary mx-1"
      (click)="exportAsXLSX()"
      >
    
        {{'company_learner.export_xls'|translate}}
        <!-- <img src="../../../assets/images/attach.png" /> -->
      </button>
    </div>

    <div class="table-div">
      <div class="mat-elevation-z8">
        <!-- <table mat-table [dataSource]="dataSource" > temprory change -->
        <table mat-table [dataSource]="dataSource" class="scroller">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef>{{'register_form.first_name'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
          </ng-container>

          <!-- Surname Column -->
          <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.surname'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{'master_data.email'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>
          <ng-container matColumnDef="job">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.company_learners'|translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.job }}</td>
          </ng-container>
          <ng-container matColumnDef="total_certificates">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.total_certificates'|translate}}s</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_certificates }}
            </td>
          </ng-container>
          <!-- change as per pdf and marked -->
          <ng-container matColumnDef="last_certificate">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.last_certificates'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.last_certificate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="next_deadline">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.next_deadline'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.next_deadline }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{'company_learner.action'|translate}}</th>
            <td mat-cell *matCellDef="let element">
              <img 
                class="ptr" 
                [routerLink]="['/learner/learner_card/', element.id]"
                src="../../../assets/images/search-icon-t.png" />
              <img 
                class="ptr"
                routerLink="/learner/edit"
                src="../../../assets/images/edit-icon.png"
              />     
              <img 
              class="ptr"
              (click)="deleteItem(element)"
              src="../../../assets/images/delete-icon.png"
            />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
