import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './../../../core/service/api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './../../../core/service/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddAtecoCodeComponent } from 'src/app/add-ateco-code/add-ateco-code.component';

export interface PeriodicElement {
  code: string;
  activity:string;
  level: string;
}

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})

export class CompanyProfileComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public translate: TranslateService,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    public common_: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) { }


    atecos =  []
    raw_data = [];
    citydata= [];
    provinces= [];
    profiledata =[];
    jobdata =[];
    RiskLevel = '';
    countries = [];

    update_data= this.formBuilder.group({
      name: '',
      first_name: '',
      last_name: '',
      company: '',
      contact_1: '',
      contact_2: '',
      tax_code: '',
      vat_number: '',
      email: '',
      ateco_code: '',
      risk_level: '',
    });

    ateco_data= this.formBuilder.group({
      ateco_code: [],
    });

    searchedDataColumns:string[]=[
      'id',
      'code',
      'level',
      'activity'];

      
    dumyData:PeriodicElement[]=[
      {'code':'Fire','level':'Room1','activity':'test'},
      {'code':'Fire','level':'Room1','activity':'test'},
      {'code':'Fire','level':'Room1','activity':'test'},
     ];

   employeeCourseColumns: string[] = ['title','date', 'place','action'];
   dataSource: MatTableDataSource<PeriodicElement>=new MatTableDataSource(this.dumyData);


  ngOnInit(): void {

    this.api.GET('user/getProfile').then((res) => {
        
        this.profiledata = res['Company-details'][0];

        console.log('%c USER PROFILE', 'background-color:silver', this.profiledata);

        this.RiskLevel = res['Company-details'][0].company_risk_level['title'];
        
    });

    this.api.GET('countries').then((res) => {
        
      this.profiledata = res['Company-details'][0];

      if(res["body"]["status"]=="failed"){
        this.toastr.error('AtecoCode not found!', 'Something is wrong');
      }
      console.log('%c USER PROFILE', 'background-color:silver', this.profiledata);

      this.RiskLevel = res['Company-details'][0].company_risk_level['title'];
      
  });


    let arr = [];
    this.api.GET('getcodes').then((res) => {
      
      if (res['message'] === 'Codes retrieved successfully') {
        this.raw_data = res['data'];
        var i = 0;
        this.raw_data.forEach((element) => {
              i++;
            let m = {
              id: i,
              code: element.code,
              activity: element.activity_name,
              level: element.level_name,
            };
            arr.push(m);
         
        });
        this.dataSource = new MatTableDataSource(arr);
        this.dataSource.paginator = this.paginator;
      }
    });


    // $("#mobile_code").intlTelInput({
    //   initialCountry: "in",
    //   separateDialCode: true,
    //   // utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js"
    // });

  }

   updateDetails(){
    this.api.POST('updateCompanyprofile',
    {
      ateco_code: this.update_data.value.ateco_code,
      tax_code: this.update_data.value.tax_code,
      vat_number: this.update_data.value.vat_number
    }
  ).then(res=>{
  
    if(res["body"]["status"]=="failed"){
      this.toastr.error('AtecoCode not found!', 'Something is wrong');
    }else if(res["body"]["status"]=="success"){
      this.toastr.success('Details updated Successfully', 'Success');
    }
  
  })
  .catch(err=>{
    this.toastr.error('click here to close it', 'Something is wrong');
  })
  }

  addAtecocodes() {
    console.log('--', {
      course_name: "dad",
      course_id: 2,
      tc_id: 4,
    });
    
    this.dialog.open(AddAtecoCodeComponent, {
      width: '40vw',
      minHeight: '10vh',
      data: {
        course_name: "",
        course_id: "ad",
        tc_id: 4,
      },
    });

  }

  add(){
    this.atecos.push(this.update_data)
  }

  
}
