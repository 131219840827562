<div class="registerd-form">
  <div class="container">


<form  [formGroup]="update_data" (ngSubmit)="updateDetails()" novalidate>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="first">Company Name</label>
          <input type="text" 
          class="form-control" value="{{profiledata['name']}}"
          formControlName="first_name"
            placeholder="Alex" id="first">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <!-- <label for="first">{{'update_form.name'|translate}}</label> -->
          <label for="first">First Name</label>
          <input type="text" 
          class="form-control" value="{{profiledata['first_name']}}"
          formControlName="first_name"
            placeholder="Alex" id="first">
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="last">Last Name</label>
          <input type="text" class="form-control" value="{{profiledata['last_name']}}"  formControlName="last_name" placeholder="{{'register_form.last_name'|translate}}" id="last">
        </div>
      </div>
    </div>

  <div class="row">

    <div class="col-md-6">
        <div class="form-group">
          <label for="url">{{'register_form.phone_number'|translate}}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" type="button">+11</button>
            </div>
            <input type="tel" id="phone" value="{{profiledata['contact_1']}}"  formControlName="contact_1" class="form-control"  aria-label="" aria-describedby="basic-addon1">
          </div>
        </div>
    </div>


    <div class="col-md-6">
      <div class="form-group">
      <label for="url">{{'register_form.alternative_number'|translate}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button">+11</button>
        </div>
        <input type="tel" id="url" class="form-control" value="{{profiledata['contact_2']}}"  formControlName="contact_2"  aria-label="" aria-describedby="basic-addon1">
      </div>
    </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
      <label for="tax_code">Tax Code</label>
        <input type="text" id="tax_code" class="form-control" value="{{profiledata['tax_code']}}"  formControlName="tax_code"  aria-label="" aria-describedby="basic-addon1">
    </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
      <label for="vat_number">VAT Number</label>
        <input type="number" id="vat_number" class="form-control" value="{{profiledata['vat_number']}}"  formControlName="vat_number"  aria-label="" aria-describedby="basic-addon1">
    </div>
    </div>

      <div class="col-md-5">
        <div class="form-group">
          <label for="email">{{'register_form.email_address'|translate}}</label>
          <input type="email" class="form-control" value="{{profiledata['email']}}"  formControlName="email" id="email" placeholder="alex@gmail.com" readonly>
        </div>
      </div>

    <div class="col-md-6">
        <div class="form-group showInputField">
          <label for="ateco_code">Enter Ateco Code (Risk level will be automatically determined by the sytem based on the ateco code that you enter)</label>
          <input type="text" class="form-control" value="{{ profiledata['ateco_code'] }}"  formControlName="ateco_code" id="ateco_code" placeholder="e.g 01.01.01">
        </div>
    </div>

      <div class="col-md-1 p-0 d-flex justify-content-center">
        <img
          class="p-1 yellow-bg ptr"
          width="100%"
          height="100%"
          (click)="add()"
          src="../../../../assets/images/plus-icon.png"
        />      
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="email">Your Risk Level (Determined by the backend after entering the code in the system)</label>
          <input type="email" class="form-control" value="{{ RiskLevel }}"  formControlName="risk_level" id="email" placeholder="Level of risk" readonly>
        </div>
      </div>
      <!--  col-md-6   -->
    </div>
    <!--  row   -->

    <div class="row">
      <div class="col-md-6">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
           <label class="form-check-label" for="flexCheckChecked">Change Password</label>
        </div>
    </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="password">Old Password</label>
          <input type="password"  class="form-control" id="password" placeholder="**********">
        </div>
      </div>
      <!--  col-md-6   -->
      <div class="col-md-6">
        <div class="form-group">
          <label for="password">New Password</label>
          <input type="password"  class="form-control" id="password" placeholder="**********">
        </div>
      </div>
      <!--  col-md-6   -->

    </div>

    <!--  row   -->
    <button type="submit"  class="btn btn-primary">{{'update_form.save_changes'|translate}}</button>
 

    
  </form>
</div>

<div class="col-md-12 mt-3">
  <div class="row">
      <div class="col-md-12">
          <button
            (click)="addAtecocodes()"
            class="btn btn-primary mt-2"
            >
            {{ "Add Code" | translate }}
          </button>
      </div>
    <div class="col-md-12">
      <div class="table-div">
    <div class="courses">
      <table mat-table [dataSource]="dataSource">
        <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          Id
        </th>
        <td mat-cell *matCellDef="let item" class="text-left">
          {{ item.id }}
        </td>
      </ng-container>
      
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef class="text-left">
            {{ "Code" | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="text-left">
            {{ element.code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef>Level</th>
          <td mat-cell *matCellDef="let element">
            {{ element.level }}
          </td>
        </ng-container>
        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Activity" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.activity }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="searchedDataColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: searchedDataColumns"
        ></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              colspan="7"
              style="color: blue; font-weight: bold; font-size: 20px"
            >
              No data matching the filter
              <span
                style="color: red; font-weight: bold; font-size: 20px"
              >
                <!-- "{{ inputSearch.value }}" -->
              </span>
            </td>
          </tr>
        </ng-container>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
    </div>
  </div>
  </div>

</div><!---col-md-3---->

</div>








