import { ApiService } from 'src/app/core/service/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SiteDataService {
  allCourses: Array<any> = [];

  constructor(private _api: ApiService) {}

  setCourseData(page: number = 1) {
    this._api.GET(`general-course-list?page=${page}`).then((res) => {
      if (res['message'] == 'retrieved successfully') {
        let x: Array<any> = res['result']?.['data'];
        this.allCourses = [...this.allCourses, ...x];
      }
      res['result']?.['next_page_url'] && this.setCourseData();
      console.log(this.allCourses, 'course DATA ');
    });
  }
}
