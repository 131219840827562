import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';

@Component({
  selector: 'app-buy-conf-dialog',
  templateUrl: './buy-conf-dialog.component.html',
  styleUrls: ['./buy-conf-dialog.component.css']
})
export class BuyConfDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BuyConfDialogComponent>,
    private api: ApiService,
    private toastr: ToastrService
    ) { }

  c_id=this.data.course_id
  tc_id=this.data.tc_id

  buy(){
    this.api.POST('company/purchase-course',
      {
        "package_id":1,
        "payment_type":"cash",
        "training_center_id":this.tc_id,
        "course_id":this.c_id
      }
    ).then(res=>{
      console.log(res)
      this.toastr.success('click here to close it', 'Purchase Successful')
      this.dialogRef.close();
    })
    .catch(err=>{
      this.toastr.error('click here to close it', 'Something is wrong');
    })
  }

  cancel(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
