<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <!-- change as per doc
<h3>{{title}} Course -->
      <h3>
        {{ courseData["title"] | translate }}
        <button
          *ngIf="userRole === 'Training Center'"
          [routerLink]="['../../rc-management/', id]"
          type="submit"
          class="btn btn-primary"
        >
          {{
            "course_management.registered_and_certified_management" | translate
          }}
        </button>

        <button
          *ngIf="userRole === 'Company'"
          type="submit"
          [routerLink]="['../../final-purchase/', id]"
          class="btn btn-primary"
        >
          {{ "course_management.buy_this_course" | translate }}
        </button>
<!-- course/final-purchase/ -->
 <!-- (click)="buyCourse()" -->
        <button
          *ngIf="userRole === 'Employee'"
          type="submit"
          (click)="buyCase()"
          class="btn btn-primary"
        >
          {{ "course_management.enroll_now" | translate }}
        </button>
      </h3>

      <div class="row">
        <div class="row">
          <div class="col-md-4">
            <img
              src="../../../assets/images/dummy-image.png"
              class="img-dummy"
            />
          </div>
          <div class="col-md-8">
            <p> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora
              eum yihusequi molestiae neque accusantium, quaerat veniam voluptatibus
              vitae, soluta hic fugiat ipsum at? Quis repellendus ratione
              aspernatur distinctio laboriosam facilis.
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora
              eum yihusequi molestiae neque accusantium, quaerat veniam voluptatibus
              vitae, soluta hic fugiat ipsum at? Quis repellendus ratione
              aspernatur distinctio laboriosam facilis
            </p>
          </div>

          <div class="col-md-12">
            <p [innerHTML]="description">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora
              eum yihusequi molestiae neque accusantium, quaerat veniam voluptatibus
              vitae, soluta hic fugiat ipsum at? Quis repellendus ratione
              aspernatur distinctio laboriosam facilis.
            </p>
          </div>

          <div class="col-md-12">
            <!-- <p>{{'rc_management.training_center_notes'|translate}}:<br/>test</p> -->
            <!-- temprory change as per doc. -->
          </div>
        </div>

        <p>
          Lorem ipsum dolor sit amet, consectetur Registered and certified
          management adipiscing elit. Nulla quam velit, vulputate eu pharetra
          nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit
          tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor
          mauris harassment elit, et lacinia ipsum quam nec dui. Quisque nec
          mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit
          hate, elementum in tempus ut. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis
          ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id.
          Sed rhoncus, tortor sed eleifend tristique, tortor mauris harassment
          elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit
          iaculis pretium sit amet quis magna. Aenean velit hate, elementum in
          tempus ut, vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
          vulputate eros sed felis sodales nec vulputate justo hendrerit.
          Vivamus varius pretium ligula, a aliquam hate euismod sit amet.
          Quisque laoreet sem sit amet orci ullamcorper at ultricies metus
          viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan,
          blandit sed diam
        </p>

        <div></div>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam
          velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate
          commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed
          eleifend tristique, tortor mauris harassment elit, et lacinia ipsum
          quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit
          amet quis magna. Aenean velit hate, elementum in tempus ut, vehicula
          eu diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed
          felis sodales nec vulputate justo hendrerit. Vivamus varius pretium
          ligula, a aliquam hate euismod sit amet. Quisque laoreet sem sit amet
          orci ullamcorper
        </p>

        <div class="pdf">
          <img src="../../../assets/images/pdf-icon.png" />
          <p>Exhibit 1</p>
        </div>
        <div class="pdf">
          <img src="../../../assets/images/pdf-icon.png" />
          <p>Exhibit 2</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!---col-md-3---->
