// import { Component, OnInit } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-ateco-code',
  templateUrl: './add-ateco-code.component.html',
  styleUrls: ['./add-ateco-code.component.css']
})
export class AddAtecoCodeComponent implements OnInit {

  constructor(
    
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAtecoCodeComponent>,
    private api: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,

  ) { }

  c_id=this.data.course_id
  tc_id=this.data.tc_id

  ateco_data= this.formBuilder.group({
    ateco_code: '',
  });

  submitCode(){

    // console.log(this.ateco_data.value);
    this.api.POST('submitcode',
      {
        "ateco_code":this.ateco_data.value.ateco_code,
      }
    ).then(res=>{
      console.log(res);
      this.toastr.success('click here to close it', 'Ateco code processed Successfully');
      this.dialogRef.close();
    })
    .catch(err=>{
      this.toastr.error('click here to close it', 'Something is wrong');
    })
  }

  cancel(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
