import { MaterialModule } from './../../material';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidecontentComponent } from './sidecontent.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SummaryComponent } from 'src/app/shared/summary/summary.component';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { CourseManagementComponent } from './course-management/course-management.component';
import { CmListComponent } from './course-management/cm-list/cm-list.component';
import { CmTableComponent } from './course-management/cm-table/cm-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { InsertNewCourseComponent } from './course-management/insert-new-course/insert-new-course.component';
import { ProposeNewCourseComponent } from './course-management/propose-new-course/propose-new-course.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TeachersManagementComponent } from './teachers-management/teachers-management.component';
import { CourseDetailComponent } from './course-management/course-detail/course-detail.component';
import { RcManagementComponent } from './course-management/rc-management/rc-management.component';
import { AddNewTeacherComponent } from './teachers-management/add-new-teacher/add-new-teacher.component';
import { CalenderComponent } from './calender/calender.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    // Other imported modules
    MatCheckboxModule,
  ],
  // Other module configurations
})
export class YourModule { }


import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
//import interactionPlugin from '@fullcalendar/interaction'; // a plugin

import { ToastrModule } from 'ngx-toastr';
import { CompanyLearnersComponent } from './company-learners/company-learners.component';
import { NewEditLearnerComponent } from './company-learners/new-edit-learner/new-edit-learner.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RoleGuardGuard } from 'src/app/core/guards/role-guard.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CertificateComponent } from './certificate/certificate.component';
import { PuchasesComponent } from './puchases/puchases.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TransactionsComponent } from './transactions/transactions.component';
import { LearnerCardComponent } from './company-learners/learner-card/learner-card.component';
import { UpdateFormComponent } from '../update/update-form/update-form.component';
import { MycourseComponent } from 'src/app/mycourse/mycourse.component';
import { PurchaseviewComponent } from 'src/app/purchaseview/purchaseview.component';
import { EditActionComponent } from 'src/app/company-learners/edit-action/edit-action.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TestComponent } from './test/test.component';
import { BuyEntireCourseComponent } from './course-management/buy-entire-course/buy-entire-course.component';
import { BuyCourseDetailComponent } from './course-management/buy-course-detail/buy-course-detail.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ImportlearnersComponent } from './company-learners/importlearners/importlearners.component';
import { ModifyPublishedcourseComponent } from './course-management/modify-publishedcourse/modify-publishedcourse.component';
import { RequestAssociationComponent } from './course-management/request-association/request-association.component';
export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
]);

const appRoutes: Routes = [
  {
    path: '',
    component: SidecontentComponent,
    children: [
      {
        path: 'summary',
        component: SummaryComponent,
      },
      { 
        path: 'profile/master-data',
        component: MasterdataComponent 
      },
      { 
        path: 'profile/company-profile',
        component: CompanyProfileComponent 
      },
      {
        path: 'course/final-purchase/:id',
        component: BuyCourseDetailComponent,
      },
      {
        path: 'course',
        component: CourseManagementComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'course/insert-new-course',
        component: InsertNewCourseComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'edit']],
        },
      },
      {
        path: 'course/buy-all-sessions',
        component: BuyEntireCourseComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'course/propose-new-course',
        component: ProposeNewCourseComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'course/course-detail/:id',
        component: CourseDetailComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'course/course-modify/:id',
        component: ModifyPublishedcourseComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'course/course-request/:id',
        component: RequestAssociationComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },

      { path: 'course/rc-management/:id', component: RcManagementComponent },
      {
        path: 'teachers',
        component: TeachersManagementComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['teachers', 'view']],
        },
      },
      {
        path: 'teachers/add-teacher',
        component: AddNewTeacherComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'edit']],
        },
      },
      {
        path: 'learner',
        component: CompanyLearnersComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['learners', 'view']],
        },
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['courses', 'view']],
        },
      },
      {
        path: 'learner/edit-learner/:id',
        component: NewEditLearnerComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['learners', 'edit']],
        },
      },
      {
        path: 'learner/learner_card/:id',
        component: LearnerCardComponent,
      },

      {
        path: 'learner/new-learner',
        component: NewEditLearnerComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['learners', 'edit']],
        },
      },

      {
        path: 'learner/import-learners',
        component: ImportlearnersComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['learners', 'edit']],
        },
      },
      {
        path: 'purchases',
        component: PuchasesComponent,
      },
      {
        path: 'purchases/view/:id',
        component: PurchaseviewComponent,
      },
      {
        path: 'certificate/my-certificates',
        component: CertificateComponent,
        canActivate: [RoleGuardGuard],
        data: {
          permission: [['certificates', 'view']],
        },
      },
      { path: 'calender', component: CalenderComponent },

      {
        path: 'user/update',
        component: UpdateFormComponent,
      },
    
      {
        path: 'mycourses',
        component: MycourseComponent,
      },
      {
        path: 'learner/edit',
        component: EditActionComponent,
      },
 
    ],
  },
];

@NgModule({
  declarations: [
    MycourseComponent,
    LearnerCardComponent,
    PurchaseviewComponent,
    SidecontentComponent,
    MasterdataComponent,
    CourseManagementComponent,
    CmListComponent,
    CmTableComponent,
    InsertNewCourseComponent,
    ProposeNewCourseComponent,
    TeachersManagementComponent,
    CourseDetailComponent,
    RcManagementComponent,
    AddNewTeacherComponent,
    CalenderComponent,
    CompanyLearnersComponent,
    CertificateComponent,
    NewEditLearnerComponent,
    PuchasesComponent,
    TransactionsComponent,
    TestComponent,
    BuyEntireCourseComponent,
    BuyCourseDetailComponent,
    CompanyProfileComponent,
    ImportlearnersComponent,
    ModifyPublishedcourseComponent,
    RequestAssociationComponent,
  ],
  imports: [
    MatTableModule,
    RouterModule.forChild(appRoutes),
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CKEditorModule,
    MatCheckboxModule,
    FullCalendarModule,
    MaterialModule,
    MatPaginatorModule,
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [RouterModule],
})
export class SidecontentModule {
  constructor(public translate: TranslateService) {
    translate.addLangs(['en','il']);
    translate.setDefaultLang('en');
    const browserLang=translate.getBrowserLang();
    translate.use(browserLang.match(/en|il/)?browserLang:'en')
  }
}
