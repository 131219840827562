import { config } from './../../../../core/config/config';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';
import { TranslateService } from '@ngx-translate/core';

export interface PeriodicElement {
  course_id: any;
  id: number;
}

@Component({
  selector: 'app-rc-management',
  templateUrl: './rc-management.component.html',
  styleUrls: ['./rc-management.component.css'],
})
export class RcManagementComponent implements OnInit {
  @ViewChild('fileupload') FileChooser: ElementRef;
  course_id:number;
  id: number;
  emp_id:number;
  displayedColumns: string[] = [
    // 'employee_id',
    'id',
    'company',
    'sur_name',
    'name',
    'tax_code',
    'frequency',
    'outcome',
    'action',
    // 'enrollment_date',
    //  'certificate',
    
  ];
  datas = [
    // {'id':'1','company':'Mario SRL','sur_name':'Blue','name':'Mario','tax_code':'ABCDEF123456','frequency':'Yes','outcome':'OK','action':''},
    // {'id':'2','company':'Blue s.n.c','sur_name':'Black','name':'Peter','tax_code':'ABCDEF123456','frequency':'Yes','outcome':'OK','action':''},
    // {'id':'3','company':'SPA Yellows','sur_name':'Yellow','name':'John','tax_code':'ABCDEF123456','frequency':'Yes','outcome':'OK','action':''},
    // {'id':'4','company':'SPA Yellows','sur_name':'Red','name':'Luke','tax_code':'ABCDEF123456','frequency':'No','outcome':'OK','action':''},
    // {'id':'5','company':'Blue s.n.c','sur_name':'Green','name':'Antionio','tax_code':'ABCDEF123456','frequency':'Yes','outcome':'OK','action':''},
  ];
  fileData: File = null;
  uploadCertData = {
    employee_id: null,
    course_id: null,
  };

  courseInfo = {
    title: '',
    Location: '',
  };

  constructor(
    private _Activatedroute: ActivatedRoute,
    private api: ApiService,
    private _toast: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.id = parseInt(this._Activatedroute.snapshot.paramMap.get('id'));

    this.api.GET('enrollment').then((res) => {
      //console.log('%c ENROLLMENT', 'background-color: green', res);
  var datas=[];
      if (res['message'] === 'retrieved successfully') {
        // debugger
        var data: Array<any> = res['data'];
        // this.datas = allData.filter((element) => element.course_id == this.id);
      //  console.log(this.datas, 'ALL ENROLLMENT FOR COURSE');
        // if (this.datas.length > 0) {
          // this.courseInfo.title = this.datas[0]?.['course']?.['title'];
          // this.courseInfo.Location = this.datas[0]?.['course']?.['place'];
          data.forEach((dt)=>{
            // if(dt.?)
         var  n= {'id':dt.emp_id,
                  'company':dt.employee.company.name,
                  'sur_name':dt.employee.last_name,
                  'name':dt.employee.first_name,
                  'tax_code':dt.employee.company?.vat_number!==null?dt.employee.company?.vat_number:'null',
                  'frequency':'Yes',
                  'outcome':'OK',
                  'action':dt.employee.certificates[0]
                }
          datas.push(n);
          this.datas=datas;
          });
          console.log(this.datas)
    // // }})

          // this.datas.forEach((element) => {
          //   element['employee']['current_course_cert'] = element['employee'][
          //     'certificates'
          //   ].find((cert) => cert.course_id == this.id);
          //   element['employee']['certified'] = element['employee'][
          //     'current_course_cert'
          //   ]
          //     ? true
          //     : false;
          // });
          // console.log(this.datas)
        }
      // }
    });
  }

  fileChooser(element) {
    console.log(element);
    const e: HTMLInputElement = this.FileChooser.nativeElement;
    this.uploadCertData.course_id = element['course_id'];
    this.uploadCertData.employee_id = element['emp_id'];
    e.click();
  }

  uploadCertificate(event) {
    let fileData: File = event.target.files[0];
    let form = new FormData();
    form.append('file', fileData);
    form.append('employee_id',String(this.emp_id));
    form.append('course_id', String(this.id));
    // form.append('employee_id', );
    this.api.POST('training/certificate', form).then((res) => {
      console.log(res);
      if (res['body']['status'] == 'success') {
        this._toast.success('Certificate Uploaded Successfully', '');
        this.ngOnInit();
      }
    });
  }

  openCertificate(element) {
   
    let img_ext = config.VIEW_URL;
    let cert = element.certificate//element['employee']?.['current_course_cert']?.['certificate'];
     //console.log(cert);
    //alert(`${img_ext}${cert.file_path}`);
    window.open(`${img_ext}${cert.file_path}`, '__blank');
  }
}
