import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/service/api.service';

@Component({
  selector: 'app-enroll-conf-dialog',
  templateUrl: './enroll-conf-dialog.component.html',
  styleUrls: ['./enroll-conf-dialog.component.css']
})
export class EnrollConfDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EnrollConfDialogComponent>,
    private api: ApiService,
    private toastr: ToastrService
    ) { }

  c_id=this.data.course_id
  tc_id=this.data.tc_id

  enroll(){
    this.api.POST('enrollment',
      {
        "training_center_id":this.tc_id,
        "course_id":this.c_id
      }
    ).then(res=>{
      console.log(res);
      this.toastr.success('click here to close it', 'Course Enrollment Successfull');
      this.dialogRef.close();
    })
    .catch(err=>{
      this.toastr.error('click here to close it', 'Something is wrong');
    })
  }

  cancel(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
