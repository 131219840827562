<div class="container-fluid">
  <h3>{{'company_learner.discent_editing'|translate}}</h3>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>{{'register_form.first_name'|translate}}</label>
        <input
          type="text"
          #first_name="ngModel"
          style="
            border: 1px solid silver;
            border-radius: 7px;
            margin-left: 59px;
          "
          [(ngModel)]="company_user.first_name"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>{{'register_form.last_name'|translate}}</label>
        <input
          type="text"
          style="
            border: 1px solid silver;
            border-radius: 7px;
            margin-left: 49px;
          "
          #last_name="ngModel"
          [(ngModel)]="company_user.last_name"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>{{'register_form.phone'|translate}}</label>
        <input
          type="tel"
          style="
            border: 1px solid silver;
            border-radius: 7px;
            margin-left: 92px;
          "
          #phone="ngModel"
          [(ngModel)]="company_user.contact_1"
        />
        <!-- <input type="tel" [(ngModel)]="company_user.contact_1" /> -->
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>{{'company_learner.zip_code'|translate}}</label>
        <input
          type="text"
          style="
            border: 1px solid silver;
            border-radius: 7px;
            margin-left: 66px;
          "
          #zip_code="ngModel"
          [(ngModel)]="company_user.zip_code"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Mobile</label>
        <input
          type="tel"
          style="
            border: 1px solid silver;
            border-radius: 7px;
            margin-left: 90px;
          "
          #mobile="ngModel"
          [(ngModel)]="company_user.contact_2"
        />
        <!-- <input type="tel" [(ngModel)]="company_user.contact_2" /> -->
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <div class="input-group">
          <label class="col-md-4">{{'register_form.city'|translate}}</label>
          <div class="select col-md-6" style="padding-right: 0px;">
            <input list="cities" name="slct" id="slct"  formControlName="courseCity">
            <datalist id="cities" *ngIf="citydata">
              <option *ngFor="let city of citydata"  [value]="city.name">
                {{city.name}}
              </option>
            </datalist>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-2 col-form-label">{{'master_data.email'|translate}}</label>
    <div class="col-sm-10">
      <input
        type="text"
        style="border: 1px solid silver; border-radius: 7px"
        #email="ngModel"
        [(ngModel)]="company_user.email"
      />
    </div>
  </div>

  <div class="input-group row">
    <label class="col-sm-2 col-form-lable">Choose Task</label>
    <div class="select col-sm-8" style="padding-right: 0px;">
      <select name="slct" #task="ngModel" [(ngModel)]="company_user.task">
        <option *ngFor="let task of taskdata" value="{{task.id}}">{{ task.title }}</option>
      </select>
    </div>

  </div>
  <div class="input-group row mt-3">
    <label class="col-sm-2 col-form-lable">{{'update_form.job'|translate}}</label>
    <div class="select col-sm-8" style="padding-right: 0px;">
      <select name="slct" #job="ngModel" [(ngModel)]="company_user.job">
         <option *ngFor="let job of jobdata" value="{{job.id}}">{{ job.title }}</option>
      </select>
    </div>
  </div>

  <div class="row" style="width: 608px; margin-left: 115px; margin-top: 30px">
    <button class="btn btn-outline-warning">{{'company_learner.cancel'|translate}}</button>
    <button
      (click)="createLearner()"
      routerLinkActive="active"
      class="btn btn-warning"
      style="margin-left:14px;color: white;"
    >
    {{'update_form.save_changes'|translate}}
    </button>
  </div>

</div>
