<div class="registerd-form">
  <div class="container">
    <div class="side-nav">
      <h3>Certificate</h3>
      <p>
        Name:<span>{{ personal_data.Name }} </span> 
      </p>
      <p>
        Email: <span>{{ personal_data.Email }} </span> 
      </p>
     
    </div>

    <div class="col-md-12 my-2">
      <div class="table-div">
        <h3>Couse Completed</h3>
        <table mat-table [dataSource]="certificates" class="mat-elevation-z8">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element" class="text-left">
              {{ element.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="text-center">Date</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.date ? element.date : "-" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="place">
            <th mat-header-cell *matHeaderCellDef class="text-center">Place</th>
            <td mat-cell *matCellDef="let element" class="text-center">
              {{ element.place ? element.place : "-" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">Action <img style="margin-left:10px;height:23px;width: 20px;" class="ptr" src="../../../assets/images/pdf-icon.png" /> </th>
            <td  mat-cell *matCellDef="let element">
              <img *ngIf="element.action == 1" class="ptr" src="../../../assets/images/search-icon-t.png" />
              <img
              *ngIf="element.action == 1"
                class="ptr"
                (click)="openCertificate(element.url)"
                src="../../../assets/images/action-icon.png"
                             />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div style="width: 121px;
      margin-left: auto;">
        <a  routerLinkActive="active" class="btn btn-primary mt-3" >Download All</a>
      </div>
    </div>
  </div>
</div>
