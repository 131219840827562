import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { CommonService } from 'src/app/core/service/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
})
export class TransactionsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private api: ApiService, public common_: CommonService) {}

  displayedColumns: string[] = ['title', 'transaction_id', 'date', 'amount'];
  dataSource: MatTableDataSource<any[]>;

  searchCourse(courseTitle) {
    let filterValue: string = courseTitle.value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    this.api.GET('getTranx').then((res) => {
      // console.log('%c COURSE LIST', 'background-color: yellow', res);
      if (res['message'] === 'retrieved successfully') {
        let data = res['data'].map((val) => {
          return {
            title: val?.course?.course_info?.course?.title || '-',
            trid: val.tranx_id,
            date: val.created_at.slice(0, 10),
            amount: val?.course?.cost || '-'
          };
        });
        //console.log(data)
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
}
