import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signin-dialog',
  templateUrl: './signin-dialog.component.html',
  styleUrls: ['./signin-dialog.component.css']
})
export class SigninDialogComponent implements OnInit {

  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router,
    public dialogRef: MatDialogRef<SigninDialogComponent>, private toastr: ToastrService) { }

  formSet: FormGroup

  RecoverMode: boolean = false
  LoginMode: boolean = true
  RecoverNoticeMode: boolean = false

  OnSubmit() {
    console.log(this.formSet.value)
    const email = this.formSet.value.email
    const password = this.formSet.value.password

    this.auth.signIn(email, password).subscribe(res => {
      this.toastr.success('click here to close it', 'Login Successful');
      this.dialogRef.close();
      this.router.navigate(['/summary'])
    },
      err => {
        console.log(err)
        this.toastr.success('click here to close it', 'Login Failed');
      })
  }

  OnRegister() {
    this.router.navigate(['/register'])
    this.dialogRef.close();
  }

  OnForget() {
    this.LoginMode = false
    this.RecoverMode = true
  }

  OnRecovered() {
    this.RecoverMode = false
    this.LoginMode = false
    this.RecoverNoticeMode = true
  }

  ngOnInit(): void {
    this.formSet = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    })
  }
}
