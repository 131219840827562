<app-header></app-header>
<div class="update-sec">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-sidenav></app-sidenav>
            </div>
            <div class="col-md-9">
                <app-update-form></app-update-form>
            </div>
        </div>
    </div>

</div>
<app-footer></app-footer>
