<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 text-center">
        
        <form> 
          <div class="form-group">
            <label for="exampleInputTitle">Title</label>
            <input type="text"
             class="form-control" 
             placeholder="Enter title"
             id="description"
            >
          </div>
          <div class="form-group">
            <label for="exampleInputDescription">Description</label>
            <input type="text"
              class="form-control"
              placeholder="Enter Description" 
              id="description"
        >
          </div>
 
          <button (click)="saveJOb()" class="btn btn-warning"> Save </button>
        </form>
        
      </div>
    </div>
  </div>
  